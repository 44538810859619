import { atom } from "recoil";

export const resultContent = atom({
    key:'resultContent',
    default:{
    page1:{

    Case:'<h2> Possibly a case of dry bite</h2>',

    Specific:`<ul>
        <li>No Hospitalization </li>
        <li>No antivenom</li>
    </ul>`,

    Clinical:`<ul>
    <li>Monitoring of the patient for 4 hours after envenomation.</li>
    <li>Repeat the questionnaire if the symptoms of the patient changed.</li>
    </ul>`,

    Symptomatic:`<ul>
    <li>Tetanus vaccination. </li>
    <li>In case; pain treatment with paracetamol, codeine, tramadol, morphine, or <span className= "hoverText" data-hover="
    The analgesic effect of ketamine is obtained at a dose of 0.05-0.1 mg/kg administered intravenously.
    This dose can be repeated if, after 5 minutes, the initial dose is insufficient or when the pain increases again (generally 30-60 minutes after the initial dose).
    Paracetamol at the usual doses can be administer along with ketamine."><b>ketamine</b></span>, depending on its intensity and the skills of the nursing staff. Local anesthesia may be considered too.</li>
    </ul>`,
    },

    page2:{

        Case:'<h2> Not clear! possibly a case of snakebite or dry bite</h2>',

        Specific:`<ul>
            <li>Hospitalization in an emergency unit. </li>
            <li>No antivenom at this stage.</li>
        </ul>`,

        Clinical:`<ul>
        <li>Monitoring of the patient for 4 hours after envenomation.</li>
        <li>Repeat the questionnaire if the symptoms of the patient changed.</li>
        </ul>`,

        Symptomatic:`<ul>
        <li>Tetanus vaccination.</li>
        <li>Pain treatment with paracetamol, codeine, tramadol, morphine, or <span className= "hoverText" data-hover="
        The analgesic effect of ketamine is obtained at a dose of 0.05-0.1 mg/kg administered intravenously.
        This dose can be repeated if, after 5 minutes, the initial dose is insufficient or when the pain increases again (generally 30-60 minutes after the initial dose).
        Paracetamol at the usual doses can be administer along with ketamine."><b>ketamine</b></span>, depending on its intensity and the skills of the nursing staff. Local anesthesia may be considered too.</li>
        </ul>`,
        },

    page3:{

            Case:'<h2> Possibly a <span>severe</span> case of snakebite by an <span>Elapid</span></h2>',
    
            Specific:`<ul>
                <li>Hospitalization in ICU </li>
                <li>
                    Administration of 4 vials of polyvalent antivenom or 2 vials of monovalent elapid antivenom, as early as possible, for optimal efficacy.
                <ul>
                    <li>The antivenom is administered intravenously or by infusion (diluted to 10%, 
                        in 30 min for 100 mL), or directly intravenously slow (3 min for 10 mL). 
                        A possible intolerance to antivenom (pruritus, urticaria, rash, cough, dysphagia) 
                        is rare with a high-quality antivenom. The intolerance will be treated with an antihistamine, 
                        corticosteroids, or adrenaline in case of sudden hypotension or anaphylactic shock. 
                        </li>
                <ul>
                </li>
                <li>
                    The same dosage of antivenom for adults/children/pregnant women.
                </li>
            </ul>`,
    
            Clinical:`<ul>
            <li>Monitoring of neurotoxicity 2  
            hours after every administration of the antivenom. If neurotoxicity is still graded 1 or more, 4 more vials of polyvalent 
            antivenom or 2 vials of monovalent elapid antivenom are administered.
                <ul>
                <li>Monitoring of the neurotoxicity must be repeated every 2 hours. Monitoring and administration of extra 
                antivenom continue till when the neurotoxicity symptoms disappear. </li>
                <ul>
            </li>
            </ul>`,
    
            Symptomatic:`<ul>
            <li>Tetanus vaccination.</li>
            <li>Neurotoxic disorders treatment with atropine for a muscarinic
                syndrome of Dendroaspis or mambas (grade 2 neurological disorders)
                or atropine + <span className= "hoverText" data-hover="
                In case of severe dyspnea due to neurotoxic envenomation of grade 3 or more, treatment with anticholinesterase can be combined with antivenom and artificial ventilation.
                1. First, administer 0.6 mg of atropine sulphate slowly (2 minutes) intravenously.
                2. Immediately afterwards, inject the anticholinesterase by slow intravenous route (2 minutes) (neostigmine = 0.05 mg/kg; edrophonium = 10 mg in adults and 5 mg in children weighing less than 40 kg).
                Atropine and anticholinesterases can be administered intramuscularly if the venous route is not possible."><b>anticholinesterase</b></span> 
                for the syndrome cobra (grades 3 and 4 neurological disorders). 
            <ul>
                <li>This treatment must be associated with antivenom. An experienced practitioner, an emergency doctor, or even a resuscitator must be accessible, in case. Respiratory disorders require assisted ventilation which may continue for several weeks.</li> 
            </ul>
            </li>
            <li>
            In case, pain treatment with paracetamol, codeine, tramadol, morphine, or <span className= "hoverText" data-hover="
            The analgesic effect of ketamine is obtained at a dose of 0.05-0.1 mg/kg administered intravenously.
            This dose can be repeated if, after 5 minutes, the initial dose is insufficient or when the pain increases again (generally 30-60 minutes after the initial dose).
            Paracetamol at the usual doses can be administer along with ketamine."><b>ketamine</b></span>, 
            depending on its intensity and the skills of the nursing staff. Local anesthesia may be considered too.         
            </li>
            <li>In case, edema treatment by positioning the bitten limb above the level of the heart or in severe cases by administering corticosteroids. </li>
            </ul>`,
            },

            

        page4:{

            Case:'<h2> Possibly a mild case of snakebite by a <span>Viper</span></h2>',
    
            Specific:`<ul>
                <li>Hospitalization in an emergency unit</li>
                <li>
                Administration of 2 vials of polyvalent antivenom, as early as possible, for optimal efficacy.

                <ul>
                    <li>
                    The antivenom is administered intravenously or by infusion (diluted to 10%, in 30 min for 100 mL), 
                    or directly intravenously slow (3 min for 10 mL). A possible intolerance to antivenom (pruritus, urticaria, rash, cough, dysphagia) is rare with a high-quality antivenom. 
                    The intolerance will be treated with an antihistamine, corticosteroids, or adrenaline in case of sudden hypotension or anaphylactic shock.
                    </li>
                <ul>
                </li>
                <li>
                    The same dosage of antivenom for adults/children/pregnant women
                </li>
            </ul>`,
    
            Clinical:`<ul>
            <li>Monitoring of bleeding 2 hours after every administration of the antivenom.
                If bleeding is still graded 1 or more, 
                2 more vials of polyvalent antivenom are administered.
                <ul>
                <li>Monitoring of the bleeding must be repeated every 2 hours. Monitoring and administration of extra antivenom continues till when the bleeding stops </li>
                </ul>
            </li>
            </ul>`,
    
            Symptomatic:`<ul>
            <li>Tetanus vaccination.</li>
            <li>Pain treatment with paracetamol, codeine, 
            tramadol, morphine, or <span className= "hoverText" data-hover="
            The analgesic effect of ketamine is obtained at a dose of 0.05-0.1 mg/kg administered intravenously.
            This dose can be repeated if, after 5 minutes, the initial dose is insufficient or when the pain increases again (generally 30-60 minutes after the initial dose).
            Paracetamol at the usual doses can be administer along with ketamine."><b>ketamine</b></span>, depending on its intensity and the skills of the nursing staff. 
            Local anesthesia may be considered too.
            </li>
            <li>
            Edema treatment by positioning the bitten limb above the level of the heart or in severe cases by administering corticosteroids.  </li>      
            </li>
            <li>In case, edema treatment by positioning the bitten limb above the level of the heart or in severe cases by administering corticosteroids </li>
            <li>In case, Haemorrhages treatment with the administration of blood substitutes (fresh blood, fresh frozen plasma) at least 30 minutes after antivenom.</li>
            </ul>`,
            },

            page5:{

                Case:'<h2> Possibly a <span>Severe</span> case of snakebite by a <span>Viper</span></h2>',
        
                Specific:`<ul>
                    <li>Hospitalization in ICU</li>
                    <li>
                    Administration of 2 vials of polyvalent antivenom, as early as possible, for optimal efficacy.

                    <ul>
                        <li>
                        The antivenom is administered intravenously or by infusion 
                        (diluted to 10%, in 30 min for 100 mL), or directly intravenously 
                        slow (3 min for 10 mL). A possible intolerance to antivenom (pruritus, urticaria, rash, cough, dysphagia) is rare with a high-quality antivenom. The intolerance will be treated with an antihistamine, corticosteroids, 
                        or adrenaline in case of sudden hypotension or anaphylactic shock.
                        </li>
                    <ul>
                    </li>
                    <li>
                        The same dosage of antivenom for adults/children/pregnant women
                    </li>
                </ul>`,
        
                Clinical:`<ul>
                <li>Monitoring of bleeding 2 hours after every administration of the antivenom.
                    If bleeding is still graded 1 or more,
                    2 more vials of polyvalent antivenom are administered.
                    <ul>
                    <li>Monitoring of the bleeding must be repeated every 2 hours. Monitoring and administration of extra antivenom continues till when the bleeding stops </li>
                    </ul>
                </li>
                </ul>`,
        
                Symptomatic:`<ul>
                <li>Tetanus vaccination.</li>
                <li>Pain treatment with paracetamol, codeine, 
                tramadol, morphine, or <span className= "hoverText" data-hover="
                The analgesic effect of ketamine is obtained at a dose of 0.05-0.1 mg/kg administered intravenously.
                This dose can be repeated if, after 5 minutes, the initial dose is insufficient or when the pain increases again (generally 30-60 minutes after the initial dose).
                Paracetamol at the usual doses can be administer along with ketamine."><b>ketamine</b></span>, depending on its intensity and the skills of the nursing staff. 
                Local anesthesia may be considered too.
                </li>
                <li>
                Edema treatment by positioning the bitten limb above the level of the heart or in severe cases by administering corticosteroids.  </li>      
                </li>
                <li>Haemorrhages treatment with the administration of blood substitutes (fresh blood, fresh frozen plasma) at least 30 minutes after antivenom. </li>
                <li>The bite wound (and any traditional scarifications) cleaning twice times a day, e.g. with a solution of Dakin (5% bleach + permanganate 100 mg/L) until healing.</li>
                <li>No antibiotic therapy, in the absence of infection conﬁrmed.</li>
                </ul>`,
                },


                page6:{

                    Case:'<h2> Possibly a mild case of snakebite</h2>',
            
                    Specific:`<ul>
                        <li>Hospitalization in emergency unit</li>
                        <li>
                        Administration of 1 vials of polyvalent antivenom, as early as possible, for optimal efficacy.

                        <ul>
                            <li>
                            The antivenom is administered intravenously or by infusion 
                            (diluted to 10%, in 30 min for 100 mL), or directly intravenously 
                            slow (3 min for 10 mL). A possible intolerance to antivenom (pruritus, urticaria, rash, cough, dysphagia) is rare with a high quality antivenom. The intolerance will be treated with antihistamine, corticosteroids 
                            or adrenaline in case of sudden hypotension or anaphylactic shock.
                            </li>
                        <ul>
                        </li>
                        <li>
                            The same dosage of antivenom for adults/children/pregnant women
                        </li>
                    </ul>`,
            
                    Clinical:`<ul>
                    <li>
                    No extra antivenom
                    </li>
                    </ul>`,
            
                    Symptomatic:`<ul>
                    <li>Tetanus vaccination.</li>
                    <li>Pain treatment with paracetamol, codeine, 
                    tramadol, morphine, or <span className= "hoverText" data-hover="
                    The analgesic effect of ketamine is obtained at a dose of 0.05-0.1 mg/kg administered intravenously.
                    This dose can be repeated if, after 5 minutes, the initial dose is insufficient or when the pain increases again (generally 30-60 minutes after the initial dose).
                    Paracetamol at the usual doses can be administer along with ketamine"><b>ketamine</b></span>, depending on its
                        intensity and the skills of the nursing staff. 
                        Local anesthesia may be considered too.
                    </li>
                    <li>
                    Edema treatment by positioning the bitten limb above the level of the heart or in severe cases by administering corticosteroids.  </li>      
                    </li>
                    <li>In case, Haemorrhages treatment with the administration of blood substitutes
                        (fresh blood, fresh frozen plasma) at least 30 minutes after antivenom.</li>
                    </ul>`,
                    }, 
                    
            page7:{

                Case:'<h2> Possibly a <span>severe</span> case of snakebite </h2>',
        
                Specific:`<ul>
                    <li>Hospitalization in emergency unit</li>
                    <li>
                    Administration of 2 vials of polyvalent antivenom, as early as possible, for optimal efficacy.

                    <ul>
                        <li>
                        The antivenom is administered intravenously or by infusion 
                        (diluted to 10%, in 30 min for 100 mL), or directly intravenously 
                        slow (3 min for 10 mL). A possible intolerance to antivenom (pruritus, urticaria, rash, cough, dysphagia) is rare with a high quality antivenom. The intolerance will be treated with antihistamine, corticosteroids 
                        or adrenaline in case of sudden hypotension or anaphylactic shock.
                        </li>
                    <ul>
                    </li>
                    <li>
                        The same dosage of antivenom for adults/children/pregnant women
                    </li>
                </ul>`,
        
                Clinical:`<ul>
                <li>
                No extra antivenom
                </li>
                </ul>`,
        
                Symptomatic:`<ul>
                <li>Tetanus vaccination.</li>
                <li>Pain treatment with paracetamol, codeine, 
                tramadol, morphine, or <span className= "hoverText" data-hover="
                The analgesic effect of ketamine is obtained at a dose of 0.05-0.1 mg/kg administered intravenously.
                This dose can be repeated if, after 5 minutes, the initial dose is insufficient or when the pain increases again (generally 30-60 minutes after the initial dose).
                Paracetamol at the usual doses can be administer along with ketamine"><b>ketamine</b></span>, depending on its
                    intensity and the skills of the nursing staff. 
                    Local anesthesia may be considered too.
                </li>
                <li>
                Edema treatment by positioning the bitten limb above the level of the heart or in severe cases by administering corticosteroids.  </li>      
                </li>
                <li>In case, Haemorrhages treatment with the administration of blood substitutes
                    (fresh blood, fresh frozen plasma) at least 30 minutes after antivenom.</li>
                </ul>`,
                }, 

                page8:{

                    Case:'<h2> Possibly a mild case of snakebite </h2>',
            
                    Specific:`<ul>
                        <li>Hospitalization in emergency unit</li>
                        <li>
                        Administration of 2 vials of polyvalent antivenom, as early as possible, for optimal efficacy.
    
                        <ul>
                            <li>
                            The antivenom is administered intravenously or by infusion 
                            (diluted to 10%, in 30 min for 100 mL), or directly intravenously 
                            slow (3 min for 10 mL). A possible intolerance to antivenom (pruritus, urticaria, rash, cough, dysphagia) is rare with a high quality antivenom. The intolerance will be treated with antihistamine, corticosteroids 
                            or adrenaline in case of sudden hypotension or anaphylactic shock.
                            </li>
                        <ul>
                        </li>
                        <li>
                            The same dosage of antivenom for adults/children/pregnant women
                        </li>
                    </ul>`,
            
                    Clinical:`<ul>
                    <li>
                    No extra antivenom
                    </li>
                    </ul>`,
            
                    Symptomatic:`<ul>
                    <li>Tetanus vaccination.</li>
                    <li>Pain treatment with paracetamol, codeine, 
                    tramadol, morphine, or <span className= "hoverText" data-hover="
                    The analgesic effect of ketamine is obtained at a dose of 0.05-0.1 mg/kg administered intravenously.
                    This dose can be repeated if, after 5 minutes, the initial dose is insufficient or when the pain increases again (generally 30-60 minutes after the initial dose).
                    Paracetamol at the usual doses can be administer along with ketamine."><b>ketamine</b></span>, depending on its
                        intensity and the skills of the nursing staff. 
                        Local anesthesia may be considered too.
                    </li>
                    <li>
                    Edema treatment by positioning the bitten limb above the level of the heart or in severe cases by administering corticosteroids. </li>      
                    </li>
                    <li>In case, Haemorrhages treatment with the administration of blood substitutes
                        (fresh blood, fresh frozen plasma) at least 30 minutes after antivenom.</li>
                    </ul>`,
                    }, 


    },

})