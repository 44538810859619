import { useState , useEffect } from "react";
import Layout from "./../../components/common/layout";
import Steps from "./../../components/pages/imageIdentification/steps";
import UploadImageStep from "./../../components/pages/imageIdentification/uploadImageStep";
import IdentificationStep from "./../../components/pages/imageIdentification/identificationStep";
import ResultStep from "./../../components/pages/imageIdentification/resultStep";
import FeedbackStep from "./../../components/pages/imageIdentification/feedbackStep";

function ImageIdentification(props) {
  const [currentStep, setCurrentStep] = useState(1);
  const [uploadId, setUploadId] = useState(0);
  const [currentSnake, setCurrentSnake] = useState(0);
  const [predictedSnake, setpredictedSnake] = useState(0);
  const [selectedSnake, setSelectedSnake] = useState({});
  const [uploadedImage, setUploadedImage]= useState(null)

  const changeStepHandler = (stepState) => {
    setCurrentStep(stepState);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  useEffect(() => { console.log(uploadId); console.log("image récuperer stylé v2"+uploadedImage) }, [uploadId])

  return (
   <>
      <Layout withFixedItem={currentStep === 3 && true}>
        <section className="image__identification">
          <div className="wrapper">
            <div className="content">
              <Steps activeStep={currentStep} />
              {currentStep === 1 && (
                <UploadImageStep changeStep={changeStepHandler} 
                changeId={ val => {setUploadId(val);} } 
                id={uploadId} 
                getImage={ val => {setUploadedImage(val);} } />
              )}
              {currentStep === 2 && (
                
                <IdentificationStep changeStep={changeStepHandler} id={uploadId} 
                getCurrentSnake={ val => {setCurrentSnake(val);}} 
              getPredictedSnake={ val => {setpredictedSnake(val);}} 
              />
              )}
              {currentStep === 3 && (
                <ResultStep changeStep={changeStepHandler} snake_id={currentSnake}  prediction_id={predictedSnake}/>
              )}
              {currentStep === 4 && (
                <FeedbackStep changeStep={changeStepHandler} snake_id={currentSnake} id={uploadId} image={uploadedImage} />
              )}
            </div>
          </div>
        </section>
      </Layout>
  </>
  );
}

export default ImageIdentification;
