import React, { useEffect , useMountEffect } from "react";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { ReactComponent as NonVenemous } from "./../../../../assets/images/icon/non-venemous.svg";
import { ReactComponent as Venemous } from "./../../../../assets/images/icon/venemous.svg";
import { ReactComponent as SemiVenemous } from "./../../../../assets/images/icon/semi-venemous.svg";
import axios from "axios";
import IranMap from"./../../../../assets/images/picture/Iran-map.jpg";
import { guideStep , userLoc } from "../../../../state/GuideLineState";
import { currentSnake } from "../../../../state/StateMangement";



function LocationStep (props){

    const [currentStep, setCurrentStep] = useRecoilState(guideStep)
    const [userLocation, setUserLocation] = useRecoilState(userLoc)
    const [activeSnake, setActiveSnake] = useRecoilState(currentSnake)
    const [snakeInfo,setSnakeInfo]=useState([])
    const [species, setSpecies] = useState([])
    const [snake,setSnake] = useState([])
    const [notFound, setNotFound] = useState(false)

    async function clickOnMap(){
        await axios.get(process.env.REACT_APP_API_URL+'snake-risk/global-estimation/'+userLocation.lng+'/'+userLocation.lat).then( (response) =>{
          setSnakeInfo(response.data);
          console.log("SnakeInfo "+snakeInfo.species)
          if(response.data.species == "no species") {setNotFound(true);console.log("wake up to reality");}
          });
        }

    function generateFigures(iteration){
        var indents = [];
            for (var i = 0; i < iteration; i++) {
            indents.push(<div className='element' key={i}></div>);
            }
            return indents;
    }


        useEffect(() => {

            async function getSnakeClass(index) {
                let response = await axios.get(process.env.REACT_APP_API_URL+'snake-image/class/'+(index)+'/');
                response = await response;
                setSnake(snake => [...snake, response.data]);
              }

            if(snakeInfo.length == 0)
            {   console.log("not done");
                clickOnMap();
            }

            if(snakeInfo!=0){
                let SpList = (snakeInfo.species).split(",");
                SpList.pop();
                console.log(SpList);
                setSpecies(SpList);
                
                for(const snake in snakeInfo.index_list){
                    getSnakeClass(snakeInfo.index_list[snake]);
                }

                console.log("enterring the loop");
                console.log("snake toxicity before: "+snake[0]);
                        //snake.unshift(snake[snaky]);
                let obj = JSON.stringify(snake);
                console.log("snake toxicity after: "+obj);
                snake.sort((a, b) => (a.properties.venome_level > b.properties.venome_level) ? 1 : -1);
                console.log("snake toxicity after: "+snake[0]);
                        //snake.unshift(snake[snaky]); 
                

            }
            
            } ,[snakeInfo,notFound]
            );

     
    
    console.log("SnakeInfo "+snakeInfo);

    return(<>
    
    <section className="risk__location">
    
    <h2> { notFound ? (<></>) : (<> {species.length} species of snakes can possibly exist in the selected location</>)}  </h2>
    {/*snake.map((item, index) => {return(<h3>{item.properties.venome_level}</h3>)})*/}
    {/*<div className="element__container">{generateFigures(36)}</div>*/}
    
    { notFound ? (
    <div className="not__found">
        <div className="no__species" style={{background:`url(${IranMap})`, backgroundPosition:"center" , backgroundSize:"cover"}}>
            <div className="header"></div>
            <h2>The selected location is not supported currently <br/>Only the mentioned area is covered</h2>
        </div>

        <button
        type="submit"
        className="submit"
        onClick={() => {setCurrentStep(3);}}
        >
        Come Back
        </button>

    </div>

    

    ) : (
        <>
        <div className="guide">
        <div className="items">
          <div className="item">
            <span>Non venemous</span>
          </div>
          <div className="item">
            <span>venemous</span>
          </div>
          <div className="item">
            <span>semi venemous</span>
          </div>
        </div>
    </div>

    <div className="suggestions">

        <div className="items">

        {snake.map((snaky , index) => {

            var galery = snaky.properties.images;
            var venom = snaky.properties.venome_level;

            if(snaky.properties.venome_level=="V")
            {
            //console.log("its venomous");
            var venomBorder="item border-dark-orange"
            var venom = "NonVenemous";
            }

            else if(snaky.properties.venome_level=="S"){
            var venomBorder="item border-orange"
            }

            else
            {
            var venomBorder="item border-green"
            }

            return(



            <div className={venomBorder}
                        onClick={ () => { /*SetActiveSnake(snaky.properties.index); /*changes(snaky.properties.index)*/ setActiveSnake(snaky.id); setCurrentStep(5);}} > 
                        
            <div
                        className="item border-green"
            onClick={ () => { /*SetActiveSnake(snaky.properties.index); changes(snaky.properties.index) ;*/ setActiveSnake(snaky.id);  setCurrentStep(5);}}
                    >
            <div className="top">

                        <div className="left">
                            <div className="type__and__name">
                            <div className="type__icon">
                            {(() => {
                                switch(snaky.properties.venome_level) {
                                case "V":
                                    return <Venemous />
                                    break;
                                case "S":
                                    return <SemiVenemous />
                                    break;
                                default:
                                    return <NonVenemous />
                                }
                                })()}
                                
                            
                            </div>
                            <div className="type__name">
                                <h4>{snaky.properties.name}</h4>
                            </div>
                            </div>
                            <div className="tip">
                            <span>
                                {(snaky.properties.Common_name)}
                            </span>
                            </div>
                        </div>

                        </div>
                    </div>

                    <div className="content">
                        <div className="snake__images">
                        <div className="items">
                        {
                            
                            Object.keys(galery).map(function(key) {
                            return (
                                
                            <div className="item">
                                <img src={galery[key].image.toLowerCase()/*process.env.REACT_APP_API_IMG+galery[key].image*/} alt="" />
                            </div>
                            
                                );
                            })

                        
                            
                        }
                    </div>

                    </div>
                    
                    </div>
                    
                    
                </div>
                
                
                

            )

            })

            
        

        }
        

        </div>
        <div className="button__container_non_relv">
            <button     
            type="submit"    
            onClick={() => {setCurrentStep(6);}}
            >
            I can't find the relevant snake in the list
            </button>
        </div>
        
    </div></>)}
    </section>
    </>)
}

export default LocationStep;