import React, { useState } from "react";
import {
  LightgalleryProvider,
  LightgalleryItem,
  withLightgallery,
} from "react-lightgallery";
import "lightgallery.js/dist/css/lightgallery.css";
import Snake from "./../../../../assets/images/picture/snake_image.png";
import { ReactComponent as ThreeBullet } from "./../../../../assets/images/icon/three-bullet.svg";

function Gallery(props) {
  const Images = [
    [props.source[1].image, props.source[1].image],
    [props.source[2].image, props.source[2].image],
    [props.source[3].image, props.source[3].image],
    [props.source[4].image, props.source[4].image],
  ];

  const PhotoItem = ({ image, thumb, group }) => (
    <div className="gallery__item">
      <LightgalleryItem group={group} src={image.toLowerCase()} thumb={thumb.toLowerCase()} style={{cursor: "pointer"}}>
        <img src={image.toLowerCase()} style={{ width: "100%" }} />
      </LightgalleryItem>
    </div>
  );

  const OpenButtonWithHoc = withLightgallery(({ openGallery, ...props }) => {
    return (
      <div
        {...props}
        onClick={() => {
          openGallery("images");
        }}
        className={`${props.className ? props.className : ""} more__items`}
      >
        <ThreeBullet />
      </div>
    );
  });

  return (
    <div className="gallery__component">
      <LightgalleryProvider
        onBeforeOpen={() => console.info("onBeforeOpen")}
        onAfterOpen={() => console.info("onAfterOpen")}
        onSlideItemLoad={() => console.info("onSlideItemLoad")}
        onBeforeSlide={() => console.info("onBeforeSlide")}
        onAfterSlide={() => console.info("onAfterSlide")}
        onBeforePrevSlide={() => console.info("onBeforePrevSlide")}
        onBeforeNextSlide={() => console.info("onBeforeNextSlide")}
        onDragstart={() => console.info("onDragstart")}
        onDragmove={() => console.info("onDragmove")}
        onDragend={() => console.info("onDragend")}
        onSlideClick={() => console.info("onSlideClick")}
        onBeforeClose={() => console.info("onBeforeClose")}
        onCloseAfter={() => console.info("onCloseAfter")}
      >
        <div className="gallery__items">
          <OpenButtonWithHoc />
          {Images.map((p, idx) => (
            <PhotoItem key={idx} image={p[0]} thumb={p[1]} group="images" />
          ))}
        </div>
      </LightgalleryProvider>
    </div>
  );
}

export default Gallery;
