import Navigation from "../navigation";

function ContentBox(props){

    const {children} = props;
    
    return(
        <>
        <div className="contentBox">
            <div className="symptomBox">
                {children}
            </div>
        </div>
        </>
    );
}
export default ContentBox;