import axios from "axios";
import { useRecoilState , useSetRecoilState , useRecoilValue } from "recoil";
import { AuthState , useAuthState , registration , Username , csrfToken } from "../state/AuthState";
import { getRecoil, setRecoil } from 'recoil-nexus'
import Cookies from "js-cookie";
import { toast } from "react-toastify"; 


export { register , login , logout , loginSecond , whoami , getSession };

//const registred = useSetRecoilState(registration); 

let _csrfToken = null; 
const cookies = Cookies.get()




   function isResponseOk(response) {
        if (response.status >= 200 && response.status <= 299) {
          return response.json();
        } else {
          throw Error(response.statusText);
        }
      }

async function register(username , email , profession , password , re_password){
       
    console.log("registration trigerred");
    const config = {
        withCredentials : true,
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': cookies.csrftoken,
        }
    };

    const body = JSON.stringify({username , email , profession , password , re_password});

    try{
        const userState = getRecoil(AuthState);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}auth/register/`, body , config );

        if(res.data.error){
            //registred("REGISTER_FAIL");
            console.log("couldnt register causze of > ",res.data.error)
        }

        else{
            //registred("REGISTER_SUCCESS");
            console.log("registered with success", res.data)
            setRecoil(AuthState,{loggedIn:false, name:"",  authMessage:"registred with sucess"});
            
        }
        
    }
    catch(err){
        //registred("REGISTER_FAIL");
        console.log("something went wrong");
    }
}

async function login(username , password){

    const userState = getRecoil(AuthState);
    console.log("authentification state "+userState)
    console.log("login trigerred");
    const config = {
        withCredentials : true,
        headers:{
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'X-CSRFTOKEN': cookies.csrftoken,
        }
    };
    console.log(username, password);
    const body = JSON.stringify({username , password});

    try{
        const res = await axios.post(`${process.env.REACT_APP_API_URL}account/login/`, body , config );
        
        console.log("res success", res.data.sucess);
        if(res.data.success){
            console.log("loged in with sucess", res.data);
            console.log(userState);

            setRecoil(AuthState,{loggedIn:true, name:res.data.username,  authMessage:"registred with sucess"});
        }   
        else{
            console.log("loged in fail", res.data);
        }
    }

    catch(err){
        //registred("REGISTER_FAIL");
        console.log("something went wrong");
    }
}

// async function logout(){
//     const userState = getRecoil(AuthState);

//     const config = {
//         withCredentials : true,
//         headers:{
//             'Accept': 'application/json',
//             'Content-Type': 'application/json',
//             'X-CSRFTOKEN': cookies.csrftoken,
//         }
//     };

//     try{
//         const res = await axios.post(`${process.env.REACT_APP_API_URL}account/logout/`, config );
        
//         console.log("res success", res.data);
//         if(res.data.sucess){
//             console.log("loged out with sucess", res.data);
//             console.log(userState);
//             setRecoil(AuthState,{loggedIn:false, name:''});
//         }   
//         else{
//             console.log("loged out failed", res.data);
//         }
//     }

//     catch(err){
//         //registred("REGISTER_FAIL");
//         console.log("something went wrong",err);
//     }
// }

const getCSRF = () => {
    fetch(process.env.REACT_APP_API_URL+"auth/csrf/", {
       credentials: "include",
    })
    .then((res) => {
      let csrfToken = res.headers.get("X-CSRFToken");
      _csrfToken = csrfToken;
      console.log("the choosen one",csrfToken);
    })
     .catch((err) => {
      console.log(err);
    });
   }

const logout = () => {
    fetch(process.env.REACT_APP_API_URL+"auth/logout", {
      credentials: "include",
    })
    .then(isResponseOk)
    .then((data) => {
      console.log(data);
      setRecoil(AuthState,{loggedIn:false, name:''});
      getCSRF();
      window.location.reload(false);
    })
    .catch((err) => {
      console.log(err);
    });
  };

const loginSecond = ( username , password ) => {

    const userState = getRecoil(AuthState);
    const token = getRecoil(csrfToken);
    console.log("the token >>> ",token)
    fetch(process.env.REACT_APP_API_URL+"auth/login/", {
      method: "POST",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=UTF-8',
        "X-CSRFToken": token,
      },
      credentials: "include",
      body: JSON.stringify({username: username, password: password}),
    })
    .then(isResponseOk)
    .then((data) => {
      console.log(data);
      setRecoil(AuthState,{loggedIn:true, name:data.username, authMessage:"login with sucess"});
      return "success"
    })
    .catch((err) => {
      console.log("error hell",err);
       setRecoil(AuthState,{loggedIn:false, name:"", authMessage:"sucess"});
      return "error"
      //this.setState({error: "Wrong username or password."});
    });
    // .then(response => response.json())
    // .then((data) => {
    //   console.log(data);
    //   //this.setState({isAuthenticated: true, username: "", password: "", error: ""});
    //   setRecoil(AuthState,{loggedIn:true, name:"khosrow"});
    // })
    // .catch((err) => {
    //   //console.log(err);
    //   //this.setState({error: "Wrong username or password."});
    // });
  }

  const whoami = () => {
    fetch(process.env.REACT_APP_API_URL+"auth/whoami/", {
      headers: {
        "Content-Type": "application/json",
      },
      credentials: "include",
    })
    .then((res) => res.json())
    .then((data) => {
      console.log("You are logged in as: " + data.username);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const getSession = () => {
    fetch(process.env.REACT_APP_API_URL+"auth/session/", {
      credentials: "include",
    })
    .then((res) => res.json())
    .then((data) => {
      console.log(data);
      if (data.isAuthenticated) {
        setRecoil(AuthState,{loggedIn:true, name:"khosrow"});
      } else {
        setRecoil(AuthState,{loggedIn:false, name:"khosrow"});
        //this.getCSRF();
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

/*async function logoutSecondary(){

    const userState = getRecoil(AuthState);

    fetch(`${process.env.REACT_APP_API_URL}account/logout_function/`, {
        credentials: "include",
      })
      .then((data) => {
        console.log(data);
        console.log(userState);
        setRecoil(AuthState,{loggedIn:false, name:''});

      })
      .catch((err) => {
        console.log(err);
      });
}*/
