import React from "react";
import { useState , useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  KmlLayer,
  MarkerClusterer,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import MobileFilter from "../mobileFilter";
import HospitalIcon from "../../../../assets/images/icon/hospital.svg";
import SnakeBiteIcon from "../../../../assets/images/icon/resized-snakebite.png";
import Snake1Icon from "../../../../assets/images/icon/snake1.svg";
import Snake2Icon from "../../../../assets/images/icon/snake2.svg";
import Snake3Icon from "../../../../assets/images/icon/snake3.svg";
import HospitalImg from "../../../../assets/images/picture/hospital-img.png";
import SnakeImg from "../../../../assets/images/picture/snake-modal.png";
import HospitalIconNew from "../../../../assets/images/icon/hospital_new.png";
import Filter from "../filter";
import { Link } from "react-router-dom";
import axios from 'axios';
import { morphological_feature , pattern_feature , core_feature } from "../../../../state/filterState";
import { useRecoilValue , useRecoilState } from "recoil";

// replace zig zag and band
// aspcet ratio for snake head

const containerStyle = {
  width: "100vw",
  height: "100vh",
};
const center = {
  lat: 30.445,
  lng: 29.523,
};

const position = {
  lat: 31.772,
  lng: 53.214,
};
const defaultMapOptions = {
  fullscreenControl: false,
};
const options = {
  imagePath:
  process.env.PUBLIC_URL+"/markerclusterer/images/m", // so you must have m1.png, m2.png, m3.png, m4.png, m5.png and m6.png in that folder
};

const onLoad = (marker) => {
  console.log("marker: ", marker);
};
function generateIcon(type) {
  if (type === "V") {
    return Snake2Icon;
  }
  if (type === "S") {
    return Snake3Icon;
  }
  if (type === "N") {
    return Snake1Icon;
  }
}

function GoogleMapRE(props) {

  const [fullList , setFullList] = useState([]);
  const [filteredVenom, setFilteredVenom] = useState([]);
  const [filteredSnakes , setFilteredSnakes] = useRecoilState(core_feature);
  const [allInOne , setAllInOne] = useState([]);
  const [finalList , setFinalList] = useState([]);
  const [filteredEye, setFilteredEye] = useState([]);
  const [filteredPattern, setFilteredPattern] = useState([]);


  const [snakePreview , setSnakePreview] = useState(null);
  const [noSnake, setNoSnake] = useState(false);
  const [snakePreviewName, setSnakePreviewName] = useState("Selected Snake");
  const [{actual_pattern , actual_eye , actual_head}, setFilteredSpecies] = useRecoilState(morphological_feature);
  const [ actualPattern ,setActualPattern ] = useRecoilState(pattern_feature);
  const [markerPointMap, setMarkerMapPoint] = useState([ ]);
  const [snakeInfo,setSnakeInfo]=useState([]);
  const [markerMap, setMarkerMap] = useState({});
  const [selectedSnake, setSelectedSnake] = useState(null);
  const [infoOpen, setInfoOpen] = useState(false);
  const [LngLnt, setLngLnt] = useState({ lat: 0, lng: 0 });
  const [snakePosition,setSnakePosition]=useState({ lat: 0, lng: 0 })
  const [ristStyle , setRiskStyle] = useState("")
  const [species, setSpecies] = useState([])
  const [riskDescription , setRiskDescription] = useState("")
  const [season , setSeason] = useState(props.season)
  const [time , setTime] = useState(props.time)
  const [risk , setRisk] = useState(0)
  const [kmlUrl , setKmlUrl] = useState("https://www.dropbox.com/s/zm9ndikz4ev3dyu/Risk3_5cls_1.kml?dl=1");
  const [riskText, setRiskText] = useState("")
  const [snake, setSnake] = useState({
    Snake: {
      index: 1,
      name: "Echis",
      image: [{image:'media/images/gallery/viper/viper8.jpg'}],
      venome_level: "N",
      Common_name: "",
      season: 3,
      activity: 1,
    },
    area: [],
    locationX: "36.41800000",
    locationY: "54.97600000",
  });
  const [hospital, sethospital] = useState({
    id: 1,
    name: "Imam Reza Hospital",
    city: "Bojnord",
    province: "north khorasan ",
    adress: "Dowlat Blvd, Bojnurd, Iran",
    locationX: "37.48318130",
    locationY: "57.33355180",
    website: "https://nkums.ac.ir/Category/30",
    area: 1,
  });

  const handelModal = (e, type, data) => {
    setLngLnt({ lat: e.latLng.lat(), lng: e.latLng.lng() });
    ///Hospital
    if (type === 1) {
      document.querySelector(".overlay").classList.add("overlay__active");
      sethospital(data);
      document
        .querySelector(".hospital__modal")
        .classList.add("aside__modal__active");
    }
    if (type === 2) {
      setSnake(data);
      console.log("data", data)
      setSelectedSnake(data.Snake.index);
      setSnakePosition({ lat: data.locationX , lng : data.locationY})
      console.log("la postion X ",snakePosition.lat)
      if (infoOpen) {
        setInfoOpen(false);
      }
      console.log(snake.Snake.image)
      setInfoOpen(true);
      // document
      //   .querySelector(".snake__modal")
      //   .classList.add("aside__modal__active");
    }
  };

  function OpenFilters(){
    if (window.innerWidth >= 992) {
    var element = document.getElementById("filter");
    console.log("comonly used");
    element.classList.toggle("activation");
    }
    else{
      var element = document.getElementById("MFilter");
      console.log("comonly used");
      element.classList.toggle("activation");
    }

  }

  const CloseOverlay = () => {
    if (window.innerWidth >= 992) {
      var ele = document.getElementById("filter");
      document.querySelector(".overlay").classList.remove("overlay__active");
      document.querySelectorAll(".aside__modal").forEach((element) => {
        element.classList.remove("aside__modal__active");
      });
      ele.classList.remove("activation");
    }
    setMarkerMapPoint([]);
  };

  const CloseOverlayIcon = () => {
    document.querySelector(".overlay").classList.remove("overlay__active");
    document.querySelectorAll(".aside__modal").forEach((element) => {
      element.classList.remove("aside__modal__active");
    });
    setMarkerMapPoint([]);
  };

  //event to have the latitude and longitude of a snake
  async function clickOnMap(e){
    setLngLnt({ lat: e.latLng.lat(), lng: e.latLng.lng() });

    await axios.get(process.env.REACT_APP_API_URL+'snake-risk/global-estimation/'+e.latLng.lng()+'/'+e.latLng.lat()).then( (response) =>{
      setSnakeInfo(response.data);
      console.log("SnakeInfo "+snakeInfo)
      console.log(process.env.REACT_APP_API_URL+'snake-risk/global-estimation/'+e.latLng.lng()+'/'+e.latLng.lat())
      });

    setMarkerMapPoint([
      {
        title: " ",
        name: " ",
        position: { lat: e.latLng.lat(), lng: e.latLng.lng()  }
      }    
    ]
     

    );

    document.querySelector(".overlay").classList.add("overlay__active");
    document
      .querySelector(".point__modal")
      .classList.add("aside__modal__active");
  };

  const markerLoadHandler = (marker, snake) => {
     //console.log(marker);
    if (snake !== undefined || snake !== "undefined")
      return setMarkerMap((prevState) => {
        return { ...prevState, [snake.index]: marker };
      });
  };

  const openSnakeModal = () =>{
    console.log("modal oppening")
    document.querySelector(".overlay").classList.add("overlay__active");
     document
        .querySelector(".snake__modal")
        .classList.add("aside__modal__active");
  }

  function GalleryEvent(e , venom , image , name){
    var filter_block = document.getElementsByClassName("filters__container");
    var actual_element = e.target
    var preview_image = document.getElementsByClassName("snake__preview");
    preview_image[0].classList.add("activated");
    setSnakePreview(image);
    setSnakePreviewName(name);
    actual_element.classList.add(venom+"_button"); 
    filter_block[0].classList.add("widthView");
    //console.log("width view event trigerred");
    console.log(e.target.dataset.info);
  }

  function RevokeGalleryEvent(e, venom){
    var filter_block = document.getElementsByClassName("filters__container");
    var actual_element = e.target
    var preview_image = document.getElementsByClassName("snake__preview");
    preview_image[0].classList.remove("activated");
    actual_element.classList.remove(venom+"_button");
    filter_block[0].classList.remove("widthView");
    //console.log("width view event revoked");
  }

 
  useEffect(() => {
    

      if(snakeInfo!=0){
      let SpList = (snakeInfo.species).split(",");
      SpList.pop();
      //console.log(SpList);
      setFullList(SpList);
      setSpecies(SpList);
      setFilteredPattern(snakeInfo.pattern_list)
      setFilteredVenom(snakeInfo.venom_list);
      setAllInOne([SpList, snakeInfo.venom_list, snakeInfo.pattern_list, snakeInfo.eye_list, snakeInfo.head_list])
      //setFilteredSnakes([SpList, snakeInfo.venom_list, snakeInfo.pattern_list, snakeInfo.eye_list, snakeInfo.head_list]);
      //setFinalList(allInOne);
      //console.log(allInOne);

      var filtered = document.getElementsByClassName("filtered");

        if(filtered.length > 0 ){
        for(var i=0 ; i < filtered.length; i++){
           filtered[i].classList.remove("filtered");
           //console.log(filtered[i].classList);
        }
      }
      
    }
      
      
      if(props.season === 0 && props.time === 0){ 
        //setKmlUrl("https://www.dropbox.com/s/fwiq2b9xl7a5vco/R_General.kml?dl=1");
        setRisk(snakeInfo.general_risk)
      }
      if(props.season === 1 && props.time === 1){ 
        setRisk(snakeInfo.night_warm_risk)
        //setKmlUrl("https://www.dropbox.com/s/hny5qnkcfk0ubg4/R_W_N.kml?dl=1");

      }
      if(props.season === 1 && props.time === 2){
        setRisk(snakeInfo.day_warm_risk)
        //setKmlUrl("https://www.dropbox.com/s/lw70wbo0s46x5of/R_W_D.kml?dl=1");
      }
      if(props.season === 2 && props.time === 1){
        //setKmlUrl("https://www.dropbox.com/s/uuyboa5o4iwsedq/R_C_N.kml?dl=1");
        setRisk(snakeInfo.night_cold_risk)
      }
      if(props.season === 2 && props.time === 2){
        //setKmlUrl("https://www.dropbox.com/s/o5btobhkhzl8e69/R_C_D.kml?dl=1");
        setRisk(snakeInfo.day_cold_risk)
      }
      


      switch(snakeInfo.general_risk) {
        case 0:
          setRiskDescription("This area is out of the handled region")
          setRiskStyle("risk__level low__risk");
          setRiskText("Unknown Risk")
          //console.log("style changed to 0 "+snakeInfo.general_risk);
          break;
        case 1:
          setRiskStyle("risk__level low__risk");
          setRiskDescription("Risk of occurrence of just semi-venomous snakes");
          setRiskText("Very Low Risk")
          //console.log("Style Changed "+snakeInfo.general_risk)
          break;
        case 2:
          setRiskStyle("risk__level low__risk1");
          setRiskText("Low Risk")
          setRiskDescription("Risk of occurrence of 1 venomous snake");
          //console.log("style changed "+snakeInfo.general_risk);
          break;
        case 3:
            setRiskStyle("risk__level medium__risk");
            setRiskText("Medium Risk")
            setRiskDescription("Risk of occurrence of 2 venomous snakes");
            //console.log("style changed "+snakeInfo.general_risk);
            break;
        case 4:
          setRiskStyle("risk__level medium__risk1");
          setRiskText("High Risk")
          setRiskDescription("Risk of occurrence of 3 venomous snakes")
          //console.log("style changed "+snakeInfo.general_risk);
          break;
        case 5:
          setRiskStyle("risk__level high_risk");
          setRiskText("Very High Risk")
          setRiskDescription("Risk of occurrence of 4 or more venomous snakes")
          //console.log("style changed "+snakeInfo.general_risk);
          break;
          
        default:
          break;
        }

        if(snakeInfo.species == "no species"){
          setRiskDescription("This area is out of the handled region")
          setRiskStyle("risk__level low__risk");
          setRiskText("Unknown Risk")
          //console.log("style changed to 0 "+snakeInfo.general_risk);
        }

      //console.log("time "+props.time)
      //console.log("kml file "+kmlUrl)
      
     
        
      
  },[snakeInfo,time,kmlUrl])

  function FilterHandler(p_arg , e_arg , h_arg){

    var target = ""
    var pattern_class
    var eye_class
    var head_class

    console.log("is there is some snake ",noSnake);

    console.log(p_arg , e_arg , h_arg);

    if(p_arg === undefined || p_arg===""){
      pattern_class = ""
    }

    else{
      pattern_class = "pattern-"+p_arg+" "
    }

    if(e_arg === undefined || e_arg === null || e_arg === 0){
      eye_class=""
    }
    else{
      eye_class="eye-"+e_arg+" "
    }

    if(h_arg === undefined || h_arg === 0){
      head_class = ""
    }
    else{
      head_class = "head-"+h_arg
    }

    target = pattern_class + eye_class + head_class

    console.log("target value : ",target)

    var unfiltered = document.getElementsByClassName(target);
    var filtered = document.getElementsByClassName("snake-element");

    
    for(var i=0 ; i < filtered.length ; i++){
      filtered[i].classList.add("filtered");
    }

    for(var j=0 ; j < unfiltered.length ; j++){
      unfiltered[j].classList.remove("filtered");
    }
    console.log(unfiltered);

    
    const elem = document.getElementById("ul-snakelist");
    console.log("child element in list = ",elem.childElementCount)
    var delayInMilliseconds = 350;

        setTimeout(function() {
          var filters = document.getElementsByClassName("filtered");
          console.log("actual filtered element = ", filters.length);
          if(elem.childElementCount === filters.length && filters.length != 0){
            setNoSnake(true);
          }
          if(elem.childElementCount != filters.length){
            setNoSnake(false);
          }
        }, delayInMilliseconds);

  }
 
  useEffect(() => {
    //console.log("filter init ",actual_pattern," head value",actual_head," eye value ",actual_eye)

    FilterHandler(actual_pattern , actual_eye , actual_head);
    //console.table("species filtered",filtered_species)
    //setFilteredList(filteredList)
  },[actual_pattern , actual_eye , actual_head])

  //console.log("we got the species"+species);
  //console.log(snakeInfo.species);
  //console.log(selectedSnake);
  //console.log(markerMap[8])
  //console.log(snake.Snake.image)

  const changes = (v) => {props.getCurrentSnake(v)}

  return (
    <>
      <div className="overlay " onClick={() => CloseOverlay()}></div>

      <LoadScript googleMapsApiKey="AIzaSyBSfRV-o63zy_ogfsrCfCibwTp4j3HB5pU">
        <GoogleMap
          mapContainerStyle={containerStyle}
          zoom={4.5}
          center={center}
          onClick={(e) => clickOnMap(e)}
          options={defaultMapOptions}
        >
          {<KmlLayer
            url={props.kml}
            options={{ preserveViewport: true , suppressInfoWindows: true}}
            onClick={(e) => clickOnMap(e)}
          />}
          {/* Child components, such as markers, info windows, etc. */}

          <MarkerClusterer options={options}>
            {(clusterer) =>
              props.hospitals.map((location) => (
                <Marker
                  icon={HospitalIconNew}
                  key={location.id}
                  position={{
                    lat: Number(location.locationX),
                    lng: Number(location.locationY),
                  }}
                  clusterer={clusterer}
                  onClick={(e) => handelModal(e, 1, location)}
                  height="32px"
                  width="32px"
                />
              ))
            }
          </MarkerClusterer>
          <MarkerClusterer options={options}>
            {(clusterer) =>
              props.snakebite.map((location) => (
                <Marker
                  icon={{url:SnakeBiteIcon}}
                  key={location.id}
                  position={{
                    lat: Number(location.locationX),
                    lng: Number(location.locationY),
                  }}
                  clusterer={clusterer}
                  
                />
              ))
            }
          </MarkerClusterer>
          
          {markerPointMap.map((marker, index) => (
            <Marker
              key={index}
              title={marker.title}
              name={marker.name}
              position={marker.position}
            />
          ))}

          {infoOpen && selectedSnake && (
            <InfoWindow 
            position={{
              lat: Number(snakePosition.lat),
              lng: Number(snakePosition.lng),
            }}
              onCloseClick={() => setInfoOpen(false)}
            >
              <div className="snake__info__modal">
                <h3>{snake.Snake.name}</h3>
                <div onClick={()=> openSnakeModal()}>
                  More Detail
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="5.248"
                    height="9.18"
                    viewBox="0 0 5.248 9.18"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-back"
                      data-name="Icon ionic-ios-arrow-back"
                      d="M14.917,10.783,11.443,7.312a.656.656,0,1,1,.929-.926l3.935,3.933a.655.655,0,0,1,.019.9l-3.952,3.96a.656.656,0,0,1-.929-.926Z"
                      transform="translate(-11.251 -6.194)"
                      fill="#5f65a6"
                    />
                  </svg>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>

      <div className="aside__modal hospital__modal">
        <div className="main__content">
          <h4>
            <span>{hospital.name}</span>

            <button onClick={() => CloseOverlayIcon()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.953"
                height="20.953"
                viewBox="0 0 20.953 20.953"
              >
                <path
                  id="Icon_ionic-md-close"
                  data-name="Icon ionic-md-close"
                  d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z"
                  transform="translate(-7.523 -7.523)"
                  fill="#b3b3b3"
                />
              </svg>
            </button>
          </h4>

          <div className="content">
            <img src={HospitalImg} alt="" />
            <ul>
              <li>
                <span className="title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.411"
                    height="17.729"
                    viewBox="0 0 12.411 17.729"
                  >
                    <path
                      id="Icon_material-location-on"
                      data-name="Icon material-location-on"
                      d="M13.705,3A6.2,6.2,0,0,0,7.5,9.205c0,4.654,6.205,11.524,6.205,11.524s6.205-6.87,6.205-11.524A6.2,6.2,0,0,0,13.705,3Zm0,8.422a2.216,2.216,0,1,1,2.216-2.216A2.217,2.217,0,0,1,13.705,11.422Z"
                      transform="translate(-7.5 -3)"
                      fill="#b3b3b3"
                    />
                  </svg>
                  Address:
                </span>
                {hospital.adress}
              </li>

              <li>
                <span className="title">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="17.757"
                    height="17.757"
                    viewBox="0 0 17.757 17.757"
                  >
                    <path
                      id="Icon_material-local-phone"
                      data-name="Icon material-local-phone"
                      d="M8.071,12.185a14.944,14.944,0,0,0,6.5,6.5l2.17-2.17a.981.981,0,0,1,1.006-.237,11.253,11.253,0,0,0,3.522.562.989.989,0,0,1,.986.986V21.27a.989.989,0,0,1-.986.986A16.769,16.769,0,0,1,4.5,5.486.989.989,0,0,1,5.486,4.5H8.939a.989.989,0,0,1,.986.986,11.207,11.207,0,0,0,.562,3.522.99.99,0,0,1-.247,1.006l-2.17,2.17Z"
                      transform="translate(-4.5 -4.5)"
                      fill="#b3b3b3"
                    />
                  </svg>
                  Tel:
                </span>
                02635857426
              </li>
              <li>Website : <a href={hospital.website} target="_blank"><span>{hospital.website}</span></a></li>
            </ul>
            <a
              href={`https://www.google.com/maps/dir/?api=1&origin=Current+Location&destination=${hospital.locationX},${hospital.locationY}`}
              /*'https://www.google.com/maps/dir/?api=1&origin=43.7967876,-79.5331616&destination=43.5184049,-79.8473993&waypoints=43.1941283,-79.59179|43.7991083,-79.5339667|43.8387033,-79.3453417|43.836424,-79.3024487&travelmode=driving&dir_action=navigate' */
              className="route__to__hospital"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15.48"
                height="22.114"
                viewBox="0 0 15.48 22.114"
              >
                <path
                  id="Icon_material-location-on"
                  data-name="Icon material-location-on"
                  d="M15.24,3A7.734,7.734,0,0,0,7.5,10.74c0,5.8,7.74,14.374,7.74,14.374s7.74-8.569,7.74-14.374A7.734,7.734,0,0,0,15.24,3Zm0,10.5A2.764,2.764,0,1,1,18,10.74,2.765,2.765,0,0,1,15.24,13.5Z"
                  transform="translate(-7.5 -3)"
                  fill="#5f65a6"
                />
              </svg>
              Get Direction
            </a>
          </div>
        </div>
      </div>

      <div className="aside__modal point__modal ">

      

        <div className="main__content">
        <Filter />

        <div className="snake__preview">
          <div className="preview_header">
            <h2>{snakePreviewName}</h2>
          </div>
          <img src={snakePreview} />
        </div>

          <h4>
            <span>Estimated Risk</span>

            <button onClick={() => CloseOverlayIcon()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.953"
                height="20.953"
                viewBox="0 0 20.953 20.953"
              >
                <path
                  id="Icon_ionic-md-close"
                  data-name="Icon ionic-md-close"
                  d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z"
                  transform="translate(-7.523 -7.523)"
                  fill="#b3b3b3"
                />
              </svg>
            </button>
          </h4>

          <div className="content">
            {/* for change , change class type
            .medium__risk
            .low__risk */
            
            }
            <div className={ristStyle}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="13.091"
                height="19.093"
                viewBox="0 0 13.091 19.093"
              >
                <g
                  id="right-arrow_1_"
                  data-name="right-arrow (1)"
                  transform="translate(0.589 18.593) rotate(-90)"
                >
                  <g
                    id="Group_231"
                    data-name="Group 231"
                    transform="translate(0)"
                  >
                    <path
                      id="Path_167"
                      data-name="Path 167"
                      d="M17.809,5.316h0L12.75.282a.968.968,0,0,0-1.366,1.372L12.75,4.707H.4c-.535,0-.535,2.262,0,2.262H12.75l-1.366,3.379a.968.968,0,0,0,1.366,1.372l5.058-5.033h0A.969.969,0,0,0,17.809,5.316Z"
                      transform="translate(0)"
                      fill="#fff"
                      stroke="#fff"
                      stroke-width="1"
                    />
                  </g>
                </g>
              </svg>
               {riskText}
            </div>
            <p>
              {riskDescription}
            </p>
            { window.innerWidth <= 992 ? (<></>):(
              <ul className="lnglat">
              <li>
                <span>latitude:</span>
                {LngLnt.lat.toFixed(4)}
              </li>
              <li>
                <span>longitude:</span>
                {LngLnt.lng.toFixed(4)}
              </li>
            </ul>
            )}

            <div className="filters">
              <div className="element">

              </div>
            </div>
            <MobileFilter />
            <div className="snake__list">
              <div className="snake_list_header">
                <h6>Snake List</h6>
                <div className="gosht">
                  <button className="filter__button" onClick={OpenFilters}> Filter </button>
                  <div className="shine"></div>
                </div>
              </div>
              {noSnake ? (<><h4 className="no__species">There is no species that match actual filters</h4></>):(<></>)}
              <ul id="ul-snakelist">
                { 

                  species.map((sp , index) => 

                  <li 
                  className={filteredVenom[index] + ` pattern-${snakeInfo.pattern_list[index]} eye-${snakeInfo.eye_list[index]} head-${snakeInfo.head_list[index]} snake-element`}
                  onMouseOver={(e) => {GalleryEvent(e , filteredVenom[index] , snakeInfo.image_list[index] , sp)}}
                  onMouseOut={(e) => {RevokeGalleryEvent(e , filteredVenom[index])}}
                  >
                   <div className="bubbleInfo">
                      <img src={snakeInfo.image_list[index]} />
                    </div> 
                  <Link to={undefined}  onClick={(e) => {e.stopPropagation();console.log("wee sel3a");changes(snakeInfo.index_list[index]);props.changeStep(2)}}>
                    <span>
                      <svg
                        id="snake_1_"
                        data-name="snake (1)"
                        xmlns="http://www.w3.org/2000/svg"
                        width="26.787"
                        height="26.787"
                        viewBox="0 0 26.787 26.787"
                      >
                        <path
                          id="Path_146"
                          data-name="Path 146"
                          d="M126.558,100.128c-6.947-5.908-1.743-12.328-1.743-12.328h-7.528s-5.2,6.42,1.743,12.328Z"
                          transform="translate(-109.353 -83.188)"
                          fill="#f5f5f5"
                        />
                        <path
                          id="Path_147"
                          data-name="Path 147"
                          d="M378.9,89.3a1.858,1.858,0,0,0,.308-.115l.74-.355a2.671,2.671,0,0,1,1.712-.2s.636-.824,1.161-.561-.121.774,0,.776a.394.394,0,0,1-.012.788,2.634,2.634,0,0,1-.517-.06l-.8-.173a1.879,1.879,0,0,0-1.2.142l-.74.355a2.646,2.646,0,0,1-.649.213Z"
                          transform="translate(-358.998 -83.914)"
                          fill="#f5f5f5"
                        />
                        <path
                          id="Path_148"
                          data-name="Path 148"
                          d="M120.413,2.312A7.545,7.545,0,0,0,115.07,0a8.117,8.117,0,0,0-7.035,4.611c-1.477,2.772-5.2,6.42,1.743,12.328h5.02a9.422,9.422,0,0,1-3.52-5.691,1.924,1.924,0,0,1,2.31-2.182,7.059,7.059,0,0,0,1.481.157,7.545,7.545,0,0,0,5.343-2.312,3.239,3.239,0,0,0,0-4.6Z"
                          transform="translate(-100.154 0)"
                          fill="#f5f5f5"
                        />
                        <g
                          id="Group_221"
                          data-name="Group 221"
                          transform="translate(8.998)"
                        >
                          <path
                            id="Path_149"
                            data-name="Path 149"
                            d="M173.442,174a1.917,1.917,0,0,1,1.1-2.051,7.051,7.051,0,0,1-.889-.132A1.924,1.924,0,0,0,171.34,174a9.422,9.422,0,0,0,3.52,5.691h2.1A9.422,9.422,0,0,1,173.442,174Z"
                            transform="translate(-171.315 -162.751)"
                            fill="#f5f5f5"
                          />
                          <path
                            id="Path_150"
                            data-name="Path 150"
                            d="M270.379,2.312A7.544,7.544,0,0,0,265.036,0a7.077,7.077,0,0,0-1.051.079,7.711,7.711,0,0,1,4.292,2.233,3.239,3.239,0,0,1,0,4.6,7.71,7.71,0,0,1-4.292,2.233,7.06,7.06,0,0,0,1.05.079,7.545,7.545,0,0,0,5.343-2.312,3.239,3.239,0,0,0,0-4.6Z"
                            transform="translate(-259.118 0)"
                            fill="#f5f5f5"
                          />
                        </g>
                        <g
                          id="Group_224"
                          data-name="Group 224"
                          transform="translate(0 14.947)"
                        >
                          <g
                            id="Group_222"
                            data-name="Group 222"
                            transform="translate(2.178)"
                          >
                            <path
                              id="Path_151"
                              data-name="Path 151"
                              d="M60.251,291.883H45.116a3.648,3.648,0,0,1,0-7.3H60.251a3.648,3.648,0,0,1,0,7.3Z"
                              transform="translate(-41.468 -284.587)"
                              fill="#f5f5f5"
                            />
                            <path
                              id="Path_152"
                              data-name="Path 152"
                              d="M361.163,284.586h-2.1a3.648,3.648,0,0,1,0,7.3h2.1a3.648,3.648,0,1,0,0-7.3Z"
                              transform="translate(-342.38 -284.586)"
                              fill="#f5f5f5"
                            />
                            <path
                              id="Path_153"
                              data-name="Path 153"
                              d="M45.116,284.586H60.251a3.648,3.648,0,0,1,2.7,6.1,3.518,3.518,0,0,0-2.7-1.3H45.116a3.518,3.518,0,0,0-2.7,1.3,3.648,3.648,0,0,1,2.7-6.1Z"
                              transform="translate(-41.468 -284.586)"
                              fill="#f5f5f5"
                            />
                            <path
                              id="Path_154"
                              data-name="Path 154"
                              d="M318.987,284.677h0a3.654,3.654,0,0,0-.808-.091H313.69a2.743,2.743,0,0,0,3.633,1.9,1.771,1.771,0,0,1,2.394,1.579q0,.082,0,.165a3.637,3.637,0,0,1-.278,1.4,3.658,3.658,0,0,1,1.434,1.052,3.648,3.648,0,0,0-1.89-6.008Z"
                              transform="translate(-299.392 -284.586)"
                              fill="#f5f5f5"
                            />
                            <path
                              id="Path_155"
                              data-name="Path 155"
                              d="M117.157,313.263a2.743,2.743,0,0,1,2.685,3.294h-5.37a2.743,2.743,0,0,1,2.685-3.294Z"
                              transform="translate(-110.585 -311.757)"
                              fill="#f5f5f5"
                            />
                            <circle
                              id="Ellipse_153"
                              data-name="Ellipse 153"
                              cx="1.786"
                              cy="1.786"
                              r="1.786"
                              transform="translate(10.348 0.783)"
                              fill="#f5f5f5"
                            />
                          </g>
                          <g
                            id="Group_223"
                            data-name="Group 223"
                            transform="translate(0 4.544)"
                          >
                            <path
                              id="Path_156"
                              data-name="Path 156"
                              d="M23.139,378.394H3.648a3.648,3.648,0,0,1,0-7.3H23.139a3.648,3.648,0,0,1,0,7.3Z"
                              transform="translate(0 -371.098)"
                              fill="#f5f5f5"
                            />
                            <path
                              id="Path_157"
                              data-name="Path 157"
                              d="M402.631,371.1h-2.1a3.648,3.648,0,0,1,0,7.3h2.1a3.648,3.648,0,0,0,0-7.3Z"
                              transform="translate(-379.492 -371.098)"
                              fill="#f5f5f5"
                            />
                            <path
                              id="Path_158"
                              data-name="Path 158"
                              d="M23.139,371.1H3.648a3.648,3.648,0,0,0-2.7,6.1,3.519,3.519,0,0,1,2.7-1.3H23.139a3.519,3.519,0,0,1,2.7,1.3,3.648,3.648,0,0,0-2.7-6.1Z"
                              transform="translate(0 -371.098)"
                              fill="#f5f5f5"
                            />
                            <path
                              id="Path_159"
                              data-name="Path 159"
                              d="M404.177,374.746a3.637,3.637,0,0,1-.278,1.4,3.657,3.657,0,0,1,1.434,1.052,3.648,3.648,0,0,0-2.7-6.1h-2.1A3.648,3.648,0,0,1,404.177,374.746Z"
                              transform="translate(-379.492 -371.098)"
                              fill="#f5f5f5"
                            />
                            <path
                              id="Path_160"
                              data-name="Path 160"
                              d="M54.811,371.1a3.656,3.656,0,0,0-.812.092,2.741,2.741,0,0,0,5.3-.092Z"
                              transform="translate(-51.163 -371.098)"
                              fill="#f5f5f5"
                            />
                            <path
                              id="Path_161"
                              data-name="Path 161"
                              d="M335.424,399.775a2.743,2.743,0,0,0-2.685,3.294h5.37a2.743,2.743,0,0,0-2.685-3.294Z"
                              transform="translate(-315.21 -398.269)"
                              fill="#f5f5f5"
                            />
                            <circle
                              id="Ellipse_154"
                              data-name="Ellipse 154"
                              cx="1.786"
                              cy="1.786"
                              r="1.786"
                              transform="translate(9.545 0.783)"
                              fill="#f5f5f5"
                            />
                          </g>
                        </g>
                        <g
                          id="Group_225"
                          data-name="Group 225"
                          transform="translate(13.907 2.387)"
                        >
                          <path
                            id="Path_162"
                            data-name="Path 162"
                            d="M266.265,45.454a1.491,1.491,0,0,0-1.489,1.489.418.418,0,1,0,.836,0,.654.654,0,0,1,1.307,0,.418.418,0,1,0,.836,0A1.491,1.491,0,0,0,266.265,45.454Z"
                            transform="translate(-264.776 -45.454)"
                            fill="#f5f5f5"
                          />
                        </g>
                        <path
                          id="Path_163"
                          data-name="Path 163"
                          d="M129.961,82.567a1.934,1.934,0,0,0-2.291-1.9c-.066.113-.131.227-.192.343a9.282,9.282,0,0,0-1.251,2.268,1.935,1.935,0,0,0,3.735-.709Z"
                          transform="translate(-119.596 -76.397)"
                          fill="#f5f5f5"
                        />
                        <circle
                          id="Ellipse_155"
                          data-name="Ellipse 155"
                          cx="1.091"
                          cy="1.091"
                          r="1.091"
                          transform="translate(6.495 9.535)"
                          fill="#f5f5f5"
                        />
                      </svg>
                    </span>
                    {sp}
                  </Link>
                </li> )
                }
               {
                 
               } 
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="aside__modal snake__modal">
        <div className="main__content">
          <h4>
            <span>{snake.Snake.name}</span>

            <button onClick={() => CloseOverlayIcon()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.953"
                height="20.953"
                viewBox="0 0 20.953 20.953"
              >
                <path
                  id="Icon_ionic-md-close"
                  data-name="Icon ionic-md-close"
                  d="M28.477,9.619l-2.1-2.1L18,15.9,9.619,7.523l-2.1,2.1L15.9,18,7.523,26.381l2.1,2.1L18,20.1l8.381,8.381,2.1-2.1L20.1,18Z"
                  transform="translate(-7.523 -7.523)"
                  fill="#b3b3b3"
                />
              </svg>
            </button>
          </h4>

          <div className="content">
            <img src={snake.Snake.image[0].image.toLowerCase()} alt="" />
            {console.log(snake.Snake.index)}
            <p>
              {"Its a "+snake.Snake.short_description+"."}<br />{snake.Snake.shape_description}
            </p>
            
            <a href="#" onClick={() => {changes(snake.Snake.index);props.changeStep(2);}}>More Detail</a>
          </div>
        </div>
      </div>
    </>
  );
}

export default React.memo(GoogleMapRE);
