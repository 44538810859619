import React from "react";
import { useState } from "react";
import Layout from "../../components/common/layout";
import {useRecoilState} from 'recoil'; 
import { toast } from "react-toastify";
import Steps from "../../components/pages/treatment/steps";
import { treatmentStep } from "../../state/TreatmentState";
import SnakebiteCase from"./../../components/pages/treatment/snakebiteCase";
import SymptomStep from "../../components/pages/treatment/symptomsStep";
import PainStep from "../../components/pages/treatment/painStep";
import BleedingStep from "../../components/pages/treatment/bleedingStep";
import TestStep from "../../components/pages/treatment/testStep";
import NeuroStep from "../../components/pages/treatment/neuroStep";
import EdemaStep from "../../components/pages/treatment/edemaStep";
import NecroStep from "../../components/pages/treatment/necroStep";
import { locationData } from "../../state/TreatmentState"; 
import ResultStep from "../../components/pages/treatment/resultStep";


function Treatment(props){

    const [currentStep, setCurrentStep] = useRecoilState(treatmentStep); 
    console.log("current state "+currentStep)
    console.log("saved location "+locationData.value);

    const changeStepHandler = (stepState) => {
        setCurrentStep(stepState);
        document.body.scrollTop = 10; // For Safari
        document.documentElement.scrollTop = 10; // For Chrome, Firefox, IE and Opera
      };

    const nextStepHandler = (Choice , SubChoice , isSubChoice , stepState) => {

        console.log("handling the next step");
        console.log("this is my choice "+Choice);

        if(isSubChoice===false){
            if(Choice === ""){
                toast.warn('🐍 you have to choose all option to continue', {
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }
    
            else{
            changeStepHandler(stepState);
            }
        }

        else if(Choice==="No"){
        changeStepHandler(stepState);
        }

        else{
            if(SubChoice===""){
                toast.warn('🐍 you have to choose all option to continue', {
                    autoClose: 6000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    });
            }
            else{
                changeStepHandler(stepState); 
            }
            
        }

       
    }

    return(
    <>
        <Layout fullWith={true} >
           <section className="treatment">
               <div className="wrapper">
                   <div className="content">
                       <Steps activeStep={currentStep} />
                       {currentStep === 0 && (<SnakebiteCase changeStep={changeStepHandler} nextStep={nextStepHandler} />)}
                       {currentStep === 1 && (<SymptomStep changeStep={changeStepHandler} nextStep={nextStepHandler} />)}
                       {currentStep === 2 && (<PainStep changeStep={changeStepHandler} nextStep={nextStepHandler} />)}
                       {currentStep === 3 && (<EdemaStep changeStep={changeStepHandler} nextStep={nextStepHandler} />)}
                       {currentStep === 4 && (<BleedingStep changeStep={changeStepHandler} nextStep={nextStepHandler} />)}
                       {currentStep === 5 && (<TestStep changeStep={changeStepHandler} nextStep={nextStepHandler} />)}
                       {currentStep === 6 && (<NeuroStep changeStep={changeStepHandler} nextStep={nextStepHandler} />)}
                       {currentStep === 7 && (<NecroStep changeStep={changeStepHandler} nextStep={nextStepHandler} />)}
                       {currentStep === 8 && (<ResultStep changeStep={changeStepHandler} nextStep={nextStepHandler} />)}
                   </div>
               </div>
           </section>
        </Layout>
    </>);
}
export default Treatment;
