import React from "react";
import { useState , useEffect } from "react";
import { ReactComponent as CloseModalIcon } from "../../../../assets/images/icon/delete.svg";
import { login , loginSecond , getSession } from "../../../../actions/auth";
import CSRFToken from "../CSRFToken";
import { csrfToken, AuthState } from "../../../../state/AuthState";
import { useRecoilState } from "recoil"
import { toast } from "react-toastify";



function SignUpEvent(){
  var element = document.getElementById("signIn__modal");
  var element2 = document.getElementById("signUp__modal");
  console.log("comonly used");
  element.classList.toggle("active");
  element2.classList.toggle("active");
}

function closeModal(){
  var element = document.getElementById("signIn__modal");
  var element2 = document.getElementById("signUp__modal");
  element.classList.remove("active")
  element2.classList.remove("active")
}

function SignIn(props){

  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [csrf , setCsrf] = useRecoilState(csrfToken);
  const [{state, name , authMessage}, setAuthMessage] = useRecoilState(AuthState)

  useEffect(()=> {
    getSession();
  }, [])

   useEffect(() => {
    const getCSRF = () => {
      fetch(`${process.env.REACT_APP_API_URL}auth/csrf/`, {
         credentials: "include",
      })
      .then((res) => {
        let csrfToken = res.headers.get("X-CSRFToken");
        setCsrf(csrfToken);
        console.log("the choosen one",csrfToken);
      })
       .catch((err) => {
        console.log(err);
      });
     }
    getCSRF();
  }, [])

  useEffect(() =>{
    toast.warn(authMessage);
    console.log("auth message",authMessage);
  }, [authMessage])

  function SignInEvent(){
    var element = document.getElementById("signIn__modal");
    console.log("comonly used");
    element.classList.toggle("active");
  }
  
  useEffect(() => {
    props.childFunc.current = SignInEvent;
  }, [])

  const handleUserNameOnChange = (event) =>
  {
    setUserName(event.target.value);
    console.log("username > "+event.target.value)
  }

  const handlePasswordOnChange = (event) =>
  {
    setPassword(event.target.value);
    console.log("username > "+event.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    console.log("username",userName)
    const v = loginSecond(userName , password);
    console.log("submitting ",v);
  }


    if(onSubmit==="error"){toast.warn("credantials are incorrect");}
    else if(onSubmit==="success"){
      toast.success("connected with success");
    }
  

    return(<>

      <section className="aside__login__modal" id="signIn__modal">
             <CloseModalIcon className="close__icon" onClick={closeModal}/>
             <div className="modal__content">
                  <h2>Sign In</h2>
              <form onSubmit={e => onSubmit(e)}>
                <CSRFToken />
                <div className="login__form__element">
                    <label htmlFor="" className="is__required">
                    Email
                  </label>
                  <input type="text" placeholder="ex: Dr.Nadi" onChange={(event) => handleUserNameOnChange(event)}  required/>
              </div>

              <div className="login__form__element">
                    <label htmlFor="" className="is__required">
                    Password
                  </label>
                  <input type="password" onChange={(event) => handlePasswordOnChange(event)} required/>
              </div>
              <div className="button__container"><button type="submit"className="submit"> Go </button></div>
              <div className="sign_in">You dont have an account ? <span onClick={SignUpEvent}>Sign Up</span></div>
              </form>
              </div>
             
    </section>
        
    </>);
}
export default SignIn;