import React from "react";

function SnakeBiteCase(props){
    return(<>
    <div className="snakeBiteCase">
        <section className="question__box">
            <div className="question__quote">
                <h2>
                <h2>You are using this service for:</h2>
                </h2>
            </div>

            <div className="question__response">
                 <button onClick={() => {props.changeStep(1)}}> A test  </button>
                 <button onClick={() => {props.changeStep(1)}}> A real case </button>
            </div>

            <div className="question__quote">
                <ul className="question__disc">
                    <li>  This service is designed for the snakes of western Asia and Africa </li> 
                    <li>  This questionnaire could be used regardless of the time between the bite and clinical examination. 
                        For a more accurate result, performing an immediate coagulation test, either a simple WBCT<sub>20</sub> , 
                        or if possible, measurement of coagulation factors (abnormality in platelet, 
                        fibrinogen, or any other factor) is strongly recommended. </li> 

                    <li> By default, the number of antivenoms proposed by this service refers to high-quality, 
                        purified antivenoms, meeting WHO standards.</li> 


            
            
            </ul>


            </div>

        </section>
    </div>
    </>);
}

    export default SnakeBiteCase
