import React from "react";
import axios from 'axios';
import { useState , useEffect } from "react";
import Cookies from 'js-cookie'
import { csrfToken } from "../../../../state/AuthState";
import { getRecoil, setRecoil } from "recoil-nexus";

const CSRFToken = () => {

    const [token , setToken] = useState("")
    const cookies = Cookies.get()

    const getCookie = (name) => {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            let cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i].trim();
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    useEffect(() => {
        const fetchData = async() => {
            try{
                await axios.get(`${process.env.REACT_APP_API_URL}auth/csrf`).then(function (response) {
                    // handle success
                    console.log(response);
                  })
                
            }
            catch(err){

            }
        }

        fetchData();
        setToken(getCookie('csrftoken'));
        console.log("token >>> ",getRecoil(csrfToken))
        
    }, [])

    
    return(
    <input type='hidden' name='csrfmiddlewaretoken' value={getRecoil(csrfToken)} />    
    )
}

export default CSRFToken;