import { atom } from "recoil";


export const treatmentStep = atom({
    key: "treatmentStep",
    default: 0,
})

//change value in default

export const locationData = atom({
    key: 'locationData',
    default:''
})

export const painData = atom({
  key: 'painData',
  default: {
    choice: '',
    subChoice: '',
    val:0,
  },
});

export const edemaData = atom({
  key: 'edemaData',
  default: {
    choice: '',
    subChoice: '',
    val: 0,
  },
});

export const bleedingData = atom({
  key: 'bleedingData',
  default: {
    choice: '',
    subChoice: '',
    val:0,
  },
});

export const testData = atom({
  key: 'testData',
  default: {
    choice: '',
    subChoice: '',
    val:0,
  },
});

export const neuroData = atom({
  key: 'neuroData',
  default: {
    choice: '',
    subChoice: '',
    val:0,
  },
});

export const necroData = atom({
  key: 'necroData',
  default: {
    data:'',
    val:0,
  },
});

//not useful
export const pain = atom({
  key: 'pain',
  default:'',
});



export const treatmentData = atom({
    key: 'treatmentData',
    default: [locationData,painData,edemaData,bleedingData,testData,neuroData,necroData,pain],
  });
