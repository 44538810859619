import { useState , useEffect } from "react";
import GoogleMapKmlLayer from "./googleMapKmlLayer/idnex";
import Gallery from "./../../../pages/imageIdentification/gallery";
import { ReactComponent as NonVenemous } from "./../../../../assets/images/icon/non-venemous.svg";
import { ReactComponent as Venemous } from "./../../../../assets/images/icon/venemous.svg";
import { ReactComponent as SemiVenemous } from "./../../../../assets/images/icon/semi-venemous.svg";
import { ReactComponent as Location } from "./../../../../assets/images/icon/location.svg";
import Checked from "./../../../../assets/images/icon/check.png";
import Crossed from "./../../../../assets/images/icon/cross.png"
import Snake from "./../../../../assets/images/picture/snake_image.png";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { guideState, guideStep } from "../../../../state/GuideLineState";
import { currentSnake } from "../../../../state/StateMangement";
import { useRecoilValue , useRecoilState } from "recoil";
import ModalDialog from "react-bootstrap/esm/ModalDialog";



function ResultStep(props) {
  const [kmlUrl, setKmlUrl] = useState("https://www.dropbox.com/s/p3g97lma0aqqk9y/NatrixR.kml?dl=1");
  const [snakeClass, setSnakeClass] = useState([]);
  const [legend , setLegend] = useState('item1');
  const [currentStep, setCurrentStep] = useRecoilState(guideStep);
  const activeSnake = useRecoilValue(currentSnake);
  const history = useHistory();
  const guideValue=useRecoilValue(guideState);

  useEffect(()=> {
    console.log("value",guideValue);
  }, [])

  
  const dataUploadHandler = async () => {
    
    let formField = new FormData()

    formField.append('predicted',props.prediction_id)
    formField.append('confirmed',props.snake_id)

    console.log(formField)

    await axios({
      method: 'post',
      url:process.env.REACT_APP_API_URL+'snake-image/prediction-feedback/',
      data:(formField),
      
    }).then((response) => {
      console.log(response.data);   
      props.changeStep(4);                 
      
    })}
  
  useEffect(() => {
    async function getSnakeClass() {
      let response = await axios.get(process.env.REACT_APP_API_URL+'snake-image/class/'+activeSnake+'/');
      response = await response;
      console.log(response.data);
      setSnakeClass(response.data);
      setKmlUrl(response.data.properties.kml_file)
    }
    if(snakeClass.length == 0){
      getSnakeClass();
      console.log("choosen one "+ props.snake_id)
      console.log("predictable one", props.prediction_id)
    }
    else{

    }
    
  }, [snakeClass])

  if(snakeClass.length != 0){

    return (
      <div className="result__step">
        <div className="left">
          <div className="snake__info">
            <div className="title">
            {(() => {
                        switch(snakeClass.properties.venome_level) {
                        case "V":
                          return <Venemous/>
                          break;
                        case "S":
                          return <SemiVenemous />
                          break;
                        default:
                          return <NonVenemous />
                        }
                      })()}
              
              <h3>{snakeClass.properties.name}</h3>
            </div>
            <div className="fine__information">
              <div className="notice">
                <span>{snakeClass.properties.Common_name}</span>
              </div>
            </div>
            <div className="description">
                  
              <h6>Venom</h6>
              <p>
              {snakeClass.properties.venom_description}
              </p>

               <br/>       
              <h6>Morphology</h6>
              <p>
              {snakeClass.properties.shape_description}
              </p>
                        
              <h6>Size</h6>
              <p>
              {snakeClass.properties.size_description}
              </p>

              <h6>Behavior</h6>
              <p>
              {snakeClass.properties.behavior_description}
              </p>

              <br/> 
    
              
              {/*

              <h6>Biology</h6>
              <p>
              {snakeClass.properties.biology_description}
              </p>

              <br/>   
                 <h6>Just a Fact</h6>
              <p>
              {snakeClass.properties.fact_description}
              </p>

              <br/>        
              <h6>Conservation</h6>
              <p>
              {snakeClass.properties.conservation_description}
              </p>
              */
              }
             
            </div>
          </div>
        </div>
        <div className="right">
          <div className="gallery">
            <div className="master__image">
              <img src={snakeClass.properties.images[0].image.toLowerCase()} alt="" />
            </div>
            <Gallery source={snakeClass.properties.images} cursor="pointer" />
          </div>
          <div className="location">
            <GoogleMapKmlLayer kmlUrl={kmlUrl} />
          </div>
          
          <div className="toxic">
          <div class="guide">
            <div class="items">

            {(() => {
                        switch(snakeClass.properties.venome_level) {
                        case "V":
                          return (<div class="item1">
                            <span>{snakeClass.properties.name} Repartition</span>
                          </div>)
                          break;
                        case "S":
                          return (<div class="item2">
                            <span>{snakeClass.properties.name} Repartition</span>
                          </div>)
                          break;
                        default:
                          return (<div class="item3">
                            <span>{snakeClass.properties.name} Repartition</span>
                          </div>)
                        }
                      })()}
              
                <div class="WholeLocation">
                  <span>Lar Snakes Repartition</span></div>
                </div>
              </div>
          </div>
          </div>
        <div className="tell__us__feedback">
          
          { guideValue === true &&(
          <div className="guide__action__group">
            <h2>Is this the correct Snake ?</h2>
            <div className="button_group" style={{ display: "flex", justifyContent: "center" }}>
              <img src={Checked} width="56px" height="56px" onClick={() => {
                console.log("comon");
                setCurrentStep(2);
                }}/>
              <img src={Crossed} width="56px" height="56px" onClick={() => {setCurrentStep(4)}}/>
            </div>
          </div>)}
          
          { guideValue == false &&(<div className="action__group">
          <div className="button_group">
          <button onClick={() => props.changeStep(2)}>
            Return To Identification
          </button>
          <button onClick={async() => { await dataUploadHandler();}}>
            Confirm Identification
          </button>
          </div>
          <button onClick={() => props.changeStep(4)} className="white_button">
            Tell us your feedback
          </button>
          </div>)}
          
          </div>
        </div>
      
    );

  }
  
 else
 {
   return(<h2>Still loading Data</h2>)
 }

}

export default ResultStep;
