import ContentBox from "./../contentBox";
import Forward from "../../../../assets/images/icon/next.png";
import Backward from "../../../../assets/images/icon/previous.png"
import info from "../../../../assets/images/icon/info.png"
import { useEffect } from "react";
import { useRecoilState } from "recoil";
import { bleedingData } from "../../../../state/TreatmentState";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { ReactComponent as CloseModalIcon } from "./../../../../assets/images/icon/delete.svg";
import arr_next from "../../../../assets/images/icon/arrow_next.png"
import arr_previous from "../../../../assets/images/icon/arrow_previous.png"


//import the images of symptoms here
import bleeding_g1_1 from "../../../../assets/images/picture/bleeding/bleeding_g1_1.jpg"
import bleeding_g1_2 from "../../../../assets/images/picture/bleeding/bleeding_g1_2.jpg"
import bleeding_g2_1 from "../../../../assets/images/picture/bleeding/bleeding_g2_1.jpg"
import bleeding_g2_2 from "../../../../assets/images/picture/bleeding/bleeding_g2_2.jpg"
import bleeding_g3_1 from "../../../../assets/images/picture/bleeding/bleeding_g3_1.jpg"
import bleeding_g3_2 from "../../../../assets/images/picture/bleeding/bleeding_g3_2.jpg"
import bleeding_g4_1 from "../../../../assets/images/picture/bleeding/bleeding_g4_1.jpg"
import bleeding_g4_2 from "../../../../assets/images/picture/bleeding/bleeding_g4_2.jpg"

function activeSymptoms(val){

    if(val === "Yes"){
    const element = document.getElementById("sub_choice");
    element.classList.add("active");
    console.log(val);
    }

    if(val === "No"){
        const element = document.getElementById("sub_choice");
        element.classList.remove("active");
        console.log(val);
        }
}



function BleedingStep(props){

      useEffect(() => {
        if(choice === "Yes" ){
            activeSymptoms("Yes");
        }
    },[])

    const [choices, setChoices] = useState(["Yes","No"]);
    const [secondaryChoices, setSecondaryChoices] = useState(["Grade 1, non-stop, narrow stream local bleeding",
    "Grade 2, local bleeding + bleeding gums or nose bleeding","Grade 3, symptoms of grade 2 + blister",
     "Grade 4, symptoms of grade 2 or 3 + internal bleeding (detectable by sonography)     " ]);
    const [{choice, subChoice , val}, setBleed] = useRecoilState(bleedingData);
    const [modalState, setModalState] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRefs, setSelectedRefs] = useState([]);
    const [selectedRef, setSelectedRef] = useState(null);

    console.log('schoice length' + secondaryChoices.length)


    var i = 0;
    var slider_img = document.getElementsByClassName("slider-img");
    var images = [[bleeding_g1_1, bleeding_g1_2], [bleeding_g2_1, bleeding_g2_2], [bleeding_g3_1, bleeding_g3_2], [bleeding_g4_1, bleeding_g4_2]];
    var refs = [["World Health Organization. (2010). Saw-scaled viper Echis ocellatus bite, persistent profuse bleeding from multiple incisions at the site of bite inflicted 18 hours earlier", 
    "World Health Organization. (2010). The patient is bleeding profusely from incisions made at the site of the bite 7 hours previously, further evidence of consumption coagulopathy"],
     ["World Health Organization. (2010). Saw-scaled viper Echis ocellatus bite, Zaria, Nigeria: showing bleeding from gingival sulci", 
     "World Health Organization. (2010). Saw-scaled viper Echis ocellatus bite, Kaltungo, Nigeria: showing bleeding from gingival sulci and into floor of mouth"],
     ["World Health Organization. (2010). Rhinoceros viper Bitis rhinoceros bite on back of the hand of a 15-year-old in Côte d’Ivoire 24 hours previously: blood still oozes from a fang puncture and there is massive swelling and blistering up to the elbow",
      "World Health Organization. (2010). Puff adder Bitis arietans bite Zaria, Nigeria, same patient as Figure 108, blistering of bitten hand"], 
      ["", ""]];




    function prev(){
        if(i <= 0) i = selectedGallery.length;	
        i--;
        return setImg();			 
    }
    
    function next(){
        if(i >= selectedGallery.length-1) i = -1;
        i++;
        return setImg();			 
    }
    
    
    function setImg(){

        console.log(slider_img)
        //slider_img.setAttribute('src', selectedGallery[i]);
        setSelectedImage(selectedGallery[i])
        setSelectedRef(selectedRefs[i])

    
    }

    
     console.log("the bleeding value is "+val);

     const handleAnswer = (value , isSub) => {
 
         if(isSub){
             if(value === "No"){
                 setBleed({choice:value , subChoice , val:0});
             }
             if(value === "Yes"){
                 setBleed({choice:value , subChoice , val:1});
             }
             console.log("choice updated ",value);
         }else{
             setBleed({choice , subChoice:value});
             console.log("Subchoice updated");
         }
       };

//onChange={(event) => handleRadioChange(event , index)}
    return( 
    <>
     <ContentBox>
         <h2>Does the patient have clinical bleeding?</h2>
         <div className="symptom_checkbox">
             <form>
             <div className="form__container">
             { choices.map((Pchoice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={Pchoice}
                        name="area"
                        defaultChecked={ choice === Pchoice ? true : false }
                        id={`${index + 1}-${index * 10}-ans`}
                        onChange={(event)=>{activeSymptoms(Pchoice); handleAnswer(event.target.value, true)}}
                    />

                    <label htmlFor={`${index + 1}-${index * 10}-ans`}>
                        {Pchoice}
                    </label>
                    </div>);
                 })}      

                 <div className="confirmedSymptom" id="sub_choice">
            { secondaryChoices.map((Schoice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={Schoice}
                        name="sub-area"
                        id={`${index}-ans`}
                        defaultChecked={ subChoice === Schoice ? true : false }
                        onChange={(event) => {handleAnswer(event.target.value, false)}}
                    />
                    <img className="infoimg"  src={info} onClick={() => {setModalState(true); setSelectedGallery(images[index]) ; setSelectedImage(images[index][0]) ; setSelectedRefs(refs[index]) ; setSelectedRef(refs[index][0]) ; console.log(index)}} />

                    <label htmlFor={`${index}-ans`}>
                        {Schoice}
                    </label>
                    </div>);
                 })}            
            </div>

            </div>
            </form>
         </div>


         <Modal dialogClassName="symptomGallery"
             aria-labelledby="contained-modal-title-vcenter"
             size="lg"
             show={modalState}
             onHide={() => setModalState(false)}
             backdrop={ 'static' }
             keyboard={ false }
 
             >
             <div className="home__modal">
               <div className="modal__header">
               <h4> Samples of the chosen grade:</h4>
                 
                 <div
                   className="close__modal"
                   onClick={() => {setModalState(false)}}
                 >
                   <CloseModalIcon />
 
                   </div>
               </div>


               <div class="slider">
                    <div class="img-box">
                    <img className="slider-img" src={selectedImage} />
                    </div>

                    <img className="imgButton" src={arr_previous} onClick={() => {prev()}} />
                    <img className="imgButton" src={arr_next}     onClick={() => {next()}} /> 
                
                </div>


               <h7></h7>
               <h7>{selectedRef}</h7>
               <div className="modal__footer">
             
           </div>
         
             </div>
            </Modal>

             <img className="previous"  src={Backward} onClick={() => {props.changeStep(3)}}/>
             <img className="next"  src={Forward}  onClick={() => {props.nextStep(choice,subChoice,true,5)}}/>

    </ContentBox>
    </>
    )
}
export default BleedingStep;