import React from "react";
import { ReactComponent as CloseModalIcon } from "../../../../assets/images/icon/delete.svg";


/*function SignUpEvent(){
    var element = document.getElementById("login__modal");
    console.log("comonly used");
    element.classList.toggle("active");
  }*/


  function SignInEvent(){
    var element = document.getElementById("login__modal");
    console.log("comonly used");
    element.classList.toggle("active");
  }

function AccountPanel(){

    return(
        <>
        <button className="signIn__button" onClick={SignInEvent}> </button>
         <section className="aside__login__modal" id="login__modal">
             <CloseModalIcon className="close__icon" onClick={SignInEvent}/>
              <div className="modal__content">
                  <h2></h2>
                <div className="login__form__element">
                    <label htmlFor="" className="is__required">
                    Email
                  </label>
                  <input type="text" placeholder="ex: snaky@gmail.com"  required/>
              </div>

              <div className="login__form__element">
                    <label htmlFor="" className="is__required">
                    Password
                  </label>
                  <input type="password" required/>
              </div>
              <div className="button__container"><button type="submit"className="submit"> Go </button></div>
              <button className="sign_in" onClick={() => {console.log("Signup")}}>You dont have an account ? <span>Sign Up</span></button>
              </div>
             
          </section>
        </>
    );
}
export default AccountPanel;