import { useEffect } from "react";

function ViewHeightFull({ children }) {
  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("view-height");
    return function cleanup() {
      document.getElementsByTagName("html")[0].classList.remove("view-height");
    };
  });
  return <>{children}</>;
}

export default ViewHeightFull;
