import { ReactComponent as MapFlag } from "./../../../../../assets/images/icon/map-flag.svg";
import "./index.scss";
const Marker = (props) => {
  const { snakeFace } = props;
  return (
    <>
      <div className="custom__marker">
        <div className="flag">
          <MapFlag />
          <img src={snakeFace} alt="" className="snake__face" />
        </div>
      </div>
    </>
  );
};

export default Marker;
