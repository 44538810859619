import Helmet from "react-helmet";
function Seo(props) {
  const { title } = props;
  return (
    <>
      <Helmet>
        <title>AI Snake</title>
        
        <meta charSet="utf-8" />
        <link rel="icon" href="/favicon.ico" />
        <meta name="keywords" content="Snake identification , Snakebites , Snake , First aid , Poison , AI"></meta>
        <meta name="description" content="AI web application can help to reduce snakebites. About 5.4 million snakebites occur per year in the world, resulting in more than 81,000 death rate! AI.Nature aims to use state of the art AI technologies to reduce human-snake conflict, providing Image-based snake identification, location-based snakebite risk assessment and interactive first aid suggestions."></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#BAAF28" />
        <link rel="apple-touch-icon" href="/favicon.ico" />
        <meta name="apple-mobile-web-app-capable" content="yes" />
        <meta name="language" content="english" />
        <meta name="generator" content="AI Nature" />
        <meta name="copyright" content="© AI Nature" />
        <meta name="description" content="AI Nature" />
      </Helmet>
    </>
  );
}

export default Seo;
