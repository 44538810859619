import { compose, withProps } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  KmlLayer,
} from "react-google-maps";

const GoogleMapKmlLayer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyBSfRV-o63zy_ogfsrCfCibwTp4j3HB5pU&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs, 
  withGoogleMap
)((props) => (
  <GoogleMap defaultZoom={3.3} defaultCenter={{ lat: 28.445 , lng: 24.523, }}>
    <KmlLayer
      url={props.kmlUrl}
      defaultTitle="Vipera Test"
      options={{ preserveViewport: true , suppressInfoWindows: true }}
      label="test"
      icon="https://www.instagram.com/static/images/web/mobile_nav_type_logo.png/735145cfe0a4.png"
    />
  </GoogleMap>
));

export default GoogleMapKmlLayer;
