import ContentBox from "./../contentBox";
import Forward from "../../../../assets/images/icon/next.png";
import Backward from "../../../../assets/images/icon/previous.png"
import info from "../../../../assets/images/icon/info.png"
import { useState } from "react";
import WBCT from "../../../../assets/images/picture/wbct.png";
import { Modal } from "react-bootstrap";
import { ReactComponent as CloseModalIcon } from "./../../../../assets/images/icon/delete.svg";
import { useEffect} from "react";
import { useRecoilState } from "recoil";
import { testData } from "../../../../state/TreatmentState";

function activeSymptoms(val){

    if(val === "Yes"){
    const element = document.getElementById("sub_choice");
    element.classList.add("active");
    console.log(val);
    }

    if(val === "No"){
        const element = document.getElementById("sub_choice");
        element.classList.remove("active");
        console.log(val);
        }
}



function TestStep(props){


      useEffect(() => {
        if(choice === "Yes" ){
            activeSymptoms("Yes");
        }
    },[])

    const [choices, setChoices] = useState(["Yes","No"]);
    const [secondaryChoices, setSecondaryChoices] = useState(["Yes", "No" ]);
    const [{choice, subChoice , val}, setTest] = useRecoilState(testData);
    const [modalState, setModalState] = useState(false);

    console.log("the test value is "+val);

    const handleAnswer = (value , isSub) => {

        if(isSub){
            console.log("choice updated ",value);
        if(value === "No"){
            setTest({choice:value , subChoice , val:0});
        }
        if(value === "Yes"){
            setTest({choice:value , subChoice , val:1});
        }
        }else{
            setTest({choice , subChoice:value});
            console.log("Subchoice updated");
        }
      };

    
//onChange={(event) => handleRadioChange(event , index)}
    return( 
    <>
     <ContentBox>
         <h2>Is the result of any following coagulation tests of the patient available?</h2>


         <div className="symptom_checkbox">
            <div className="wbctHelp">
                <h4> WBCT <sub>20</sub></h4>
                <img className="infoimg"  src={info} onClick={() => {setModalState(true)}} />
                <h4> Blood coagulation factor</h4>

               
            </div>
             <form>
             <div className="form__container">

             { choices.map((Pchoice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={Pchoice}
                        name="area"
                        defaultChecked={ choice === Pchoice ? true : false }
                        id={`${index + 1}-${index * 10}-ans`}
                        onChange={(event)=>{activeSymptoms(Pchoice); handleAnswer(event.target.value, true)}}
                    />
                    <label htmlFor={`${index + 1}-${index * 10}-ans`}>
                        {Pchoice}
                    </label>
                    </div>);
                 })}   

                 <div className="confirmedSymptom" id="sub_choice">
            <h5>Is there any sign of coagulation failure in the test, either WBCT<sub>20</sub> (uncoagulable
             blood or abnormal clot) or coagulation factors (abnormality in platelet, fibrinogen, or any other factor)?</h5>

            { secondaryChoices.map((Schoice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={Schoice}
                        name="sub-area"
                        id={`${index + 1}-${index * 20}-ans`}
                        defaultChecked={ subChoice === Schoice ? true : false }
                        onChange={(event) => {handleAnswer(event.target.value, false)}}
                    />
                    <label htmlFor={`${index + 2}-${index * 10}-ans`}>
                        {Schoice}
                    </label>
                    </div>);
                 })} 

                 </div>
             </div>
            </form>
         </div>

             <Modal
             size="lg"
             aria-labelledby="contained-modal-title-vcenter"
             centered
             show={modalState}
             onHide={() => setModalState(false)}
             backdrop={ 'static' }
             keyboard={ false }
 
             >
             <div className="home__modal">
               <div className="modal__header">
               <h4>What is the WBCT<sub>20</sub> test?</h4>
                 
                 <div
                   className="close__modal"
                   onClick={() => {setModalState(false)}}
                 >
                   <CloseModalIcon />
 
                   </div>
               </div>
               <hr/>
               <div style={{display:"flex",justifyContent:"center", margin:"30px"}}><img src={WBCT} style={{textAlign:"center" , width:"85%"}}/>
               </div>
               <hr/>
               <h7></h7>
               <h7>(Benjamin et al., 2018)</h7>
               <div className="modal__footer">
             
           </div>
         
             </div>
            </Modal>
             
         
         
             <img className="previous"  src={Backward} onClick={() => {props.changeStep(4)}}/>
             <img className="next"  src={Forward}  onClick={() => {props.nextStep(choice,subChoice,true,6)}}/>
         
    </ContentBox>
    </>
    )
    
}
export default TestStep;

