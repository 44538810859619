export default {
  data: [
    {
      id: 1,
      species: 'Platyceps_Echis_Psammophis',
      risk: 2,
      locationX: '36.48318130',
      locationY: '57.33355180',
    },
    {
        id: 2,
        species: 'Platyceps_Echis_Psammophis',
        risk: 2,
        locationX: '37.48318130',
        locationY: '56.33355180',
      }
     
      
      
  ],
};
