import {useState , useEffect} from 'react';
import Layout from '../../../common/layout';
import {Link} from 'react-router-dom';
import GooglemapRE from '../../riskEstimation/googlemapRE';
import snakes from '../../../../data/snakes';
import hospitals from '../../../../data/hospitals';
import snakebite from '../../../../data/snakebite';
import axios from "axios"
/*import ResultStep from '../../components/pages/riskEstimation/resultStep';
import FeedBackStep from '../../components/pages/riskEstimation/feedbackStep'*/

function RiskEstimation (props) {
  const [SnakeList, setSnakeList] = useState (snakes.data);
  const [SnakePoints, setSnakePoints] = useState([])
  const [bitten , setBitten]= useState([])
  const [HospitalList, setHospitalList] = useState (hospitals.data);
  const [SnakebiteList, setSnakebiteList] = useState (snakebite.data);
  const [SeasonState, setSeasonState] = useState (0);
  const [TimeState, setTimeState] = useState (0);
  const [PointsState, setPointsState] = useState (true);
  const [SnakebiteState, setSnakebiteState] = useState (true);
  const [HospitalsState, setHospitalsState] = useState (true);
  const [filterStyle, setfilterStyle] = useState ("");
  const [hasFilter, setHasFilter] = useState (false);
  const [currentStep, setCurrentStep] = useState(1);
  const [currentSnake, setCurrentSnake] = useState(1);
  const [checkfirst, setCheckFirst] = useState(false);
  const [checksecond, setCheckSecond] = useState(false);
  const [checkCold, setCheckCold] = useState(false);
  const [checkWarm, setCheckWarm] = useState(false);
  const [ selectedSnake , setSelectedSnake]= useState(1);
  const [kmlUrl , setKmlUrl] = useState("https://www.dropbox.com/s/fwiq2b9xl7a5vco/R_General.kml?dl=1");

  useEffect(() => {
    async function getSnakePoints() {
      let response = await axios.get(process.env.REACT_APP_API_URL+'snake-risk/points/');
      response = await response;
      console.log(response.data);
      setSnakePoints(response.data)
      setSnakeList(response.data)
    }

    async function getHospitals() {
      let response = await axios.get(process.env.REACT_APP_API_URL+'snake-risk/hospitals/');
      response = await response;
      console.log(response.data);
      setHospitalList(response.data); 
    }

    async function getSnakeBites() {
      let response = await axios.get(process.env.REACT_APP_API_URL+'snake-risk/bites/');
      response = await response;
      console.log(response.data);
      setSnakebiteList(response.data); 
      setBitten(response.data)
    }
    
      getSnakePoints();
      getHospitals();
      getSnakeBites();
  }, [])

  const changeStepHandler = (stepState) => {
    setCurrentStep(stepState);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };


  const filter = (season, time) => {
     //debugger
    let items = [];
    items = SnakeList;
    console.log("season "+season+" and time "+time)
    if (season === 0 && time === 0) {
      setKmlUrl("https://www.dropbox.com/s/fwiq2b9xl7a5vco/R_General.kml?dl=1")
      setSnakeList (SnakePoints);
      //you could use reducer to make the code cleaner for those 4 state , i had to rush in to make the thing work
      setCheckCold(false);setCheckWarm(false);setCheckFirst(false);setCheckSecond(false);
      setfilterStyle("")
      setTimeState(0)
      setSeasonState(0)
      document.querySelectorAll(".filter__radio").forEach(element => {
        element.checked = false;
      });

      setHasFilter(false)
    }

    else {
      setHasFilter(true)
      items = SnakePoints;
      if (season !== 0) {
        setSeasonState (season);
        if(time === 0 ) {
          setCheckFirst(true);
          filter(season, 1);
          console.log("first filter trigerred")
          setTimeState (1);
          items = items.filter (
          a => a.Snake.activity === 1 || a.Snake.activity === 3
        );
          //setTimeState (1);
        }
        console.log("check in just here");
        items = items.filter (
          a => a.Snake.season === season || a.Snake.season === 3
        );
      }

      if (time !== 0) {
        setTimeState (time);
        if(season === 0 ) {
          setCheckWarm(true);
          filter(1, time);
          console.log("first filter trigerred")
          setSeasonState(1);
          items = items.filter (
            a => a.Snake.season === 1 || a.Snake.season === 3
          );
          //setTimeState (1);
        }

        items = items.filter (
          a => a.Snake.activity === time || a.Snake.activity === 3
        );
      }
      if(season === 0 && time === 0){
        setKmlUrl("https://www.dropbox.com/s/fwiq2b9xl7a5vco/R_General.kml?dl=1");
      }

      if(season === 1 && time === 1){
        setfilterStyle("warm__night");
        setKmlUrl("https://www.dropbox.com/s/hny5qnkcfk0ubg4/R_W_N.kml?dl=1");
      }
      if(season === 1 && time === 2){
        setKmlUrl("https://www.dropbox.com/s/lw70wbo0s46x5of/R_W_D.kml?dl=1");
        setfilterStyle("warm__day")
      }
      if(season === 2 && time === 1){
        setKmlUrl("https://www.dropbox.com/s/uuyboa5o4iwsedq/R_C_N.kml?dl=1");
        setfilterStyle("cold__night")
      }
      if(season === 2 && time === 2){
        setfilterStyle("cold__day")
        setKmlUrl("https://www.dropbox.com/s/o5btobhkhzl8e69/R_C_D.kml?dl=1");
      }
      setSnakeList (items);
      console.log(items)
    }
  };

  console.log("hospitals data"+HospitalList)
  console.log("ta daronne la chauveded last");

  const changePointState = () => {
    if (PointsState) {
      setSnakeList ([]);
      setPointsState (false);
    } else {
      setSnakeList (SnakePoints);
      setPointsState (true);
    }
  };

  const changeSnakebiteState = () => {
    if (SnakebiteState) {
      setSnakebiteList ([]);
      setSnakebiteState (false);
    } else {
      setSnakebiteList (bitten);
      setSnakebiteState (true);
    }
  };

  const changeHospitalsState = () => {
    if (HospitalsState) {
      setHospitalList ([]);
      setHospitalsState (false);
    } else {
      setHospitalList (hospitals.data);
      setHospitalsState (true);
    }
  };
  const handelMobileFilter=()=>{
    document.querySelector('.map__filter').classList.toggle('map__filter__active');
  }
  return (
    <>
      {currentStep === 1 &&(
        <div className="map__container" >
        <GooglemapRE changeStep={changeStepHandler}
           getCurrentSnake={ val => {setSelectedSnake(val);}}
          snakes={SnakeList}
          hospitals={HospitalList}
          snakebite={SnakebiteList}
          season={SeasonState}
          time={TimeState}
          kml={kmlUrl}
        />
        <div className="map__filter">
      
          <div className={'filter__form ' + filterStyle}>
            <span className={hasFilter===true ? "reset__form reset__form__active" : "reset__form"} onClick={() => filter (0, 0)}>
              reset all
            </span>
            <h4>
              Filter
            </h4>
            <div className="filter__item">
              <h5>Season</h5>
              <div class="form-check">
                <input
                  className="form-check-input filter__radio"
                  type="radio"
                  name="season"
                  id="seasonWarm"
                  onClick={() => {filter (1, TimeState);setCheckWarm(true);setCheckCold(false)}}
                  checked={checkWarm}
                />
                <label className="form-check-label" htmlFor="seasonWarm">
                  Warm Season
                </label>
              </div>
              <div class="form-check">
                <input
                  className="form-check-input filter__radio"
                  type="radio"
                  name="season"
                  id="seasonCold"
                  onClick={() => {filter(2, TimeState);setCheckCold(true);setCheckWarm(false)}}
                  checked={checkCold}
                />
                <label className="form-check-label" htmlFor="seasonCold">
                  Cold Season
                </label>
              </div>
            </div>

            <div className="filter__item">
              <h5>Time</h5>
              <div class="form-check">
                <input
                  className="form-check-input filter__radio"
                  type="radio"
                  name="time"
                  id="timeNight"
                  onClick={() => {filter(SeasonState, 1);setCheckFirst(true);setCheckSecond(false)}}
                  checked = {checkfirst}
                />
                <label className="form-check-label" htmlFor="timeNight">
                  Night
                </label>
              </div>

              <div class="form-check">
                <input
                  className="form-check-input filter__radio"
                  type="radio"
                  name="time"
                  id="timeDay"
                  onClick={() => {filter(SeasonState, 2);setCheckFirst(false);setCheckSecond(true)}}
                  checked= {checksecond}
                />
                <label className="form-check-label" htmlFor="timeDay">
                  Day
                </label>
              </div>
            </div>

            <div className="filter__item">
              <h5>Extra</h5>

              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  id="chkPointState"
                  defaultChecked="checked"
                  onClick={() => changePointState ()}
                />
                <label class="form-check-label" htmlFor="chkPointState">
                  Presence Points
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  defaultChecked="checked"
                  id="chkSnakebite"
                  onClick={() => changeSnakebiteState ()}
                />
                <label class="form-check-label" htmlFor="chkSnakebite">
                  Snakebite
                </label>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  value=""
                  defaultChecked="checked"
                  id="chkHospitalState"
                  onClick={() => changeHospitalsState ()}
                />
                <label class="form-check-label" htmlFor="chkHospitalState">
                  Hospitals
                </label>
              </div>
            </div>
          <div className="filter__item">
          <button className="filter__mobile__close" onClick={()=> handelMobileFilter()}>
              Close Filter
            </button>
          </div>
          </div>

          <Link
            className="link__to__image__identification"
            to="/imageIdentification"
          >
            Report A Snake Observation
          </Link>

          <Link
             className="link__to__feedback"
             onClick={() => changeStepHandler(3)}
          >
            Give Us Feedback 
          </Link>


        </div>

        <svg
        
          width="93"
          height="93"
          viewBox="0 0 93 93"
          className="filter__mobile"
          onClick={()=>handelMobileFilter()}
        >
          <defs>
            <filter
              id="Rectangle_43"
              x="0"
              y="0"
              width="93"
              height="93"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dy="3" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="5.5" result="blur" />
              <feFlood flood-opacity="0.259" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
          <g id="filter-mobile" transform="translate(-1272.5 -684.5)">
            <g
              transform="matrix(1, 0, 0, 1, 1272.5, 684.5)"
              filter="url(#Rectangle_43)"
            >
              <rect
                id="Rectangle_43-2"
                data-name="Rectangle 43"
                width="60"
                height="60"
                rx="6"
                transform="translate(16.5 13.5)"
                fill="#046c63"
              />
            </g>
            <g
              id="filter_alt_black_24dp_4_"
              data-name="filter_alt_black_24dp (4)"
              transform="translate(1295 704)"
            >
              <path
                id="Path_174"
                data-name="Path 174"
                d="M0,0H47m0,47H0"
                fill="none"
              />
              <path
                id="Path_175"
                data-name="Path 175"
                d="M4.453,7.153c3.956,5.072,11.26,14.472,11.26,14.472v11.75a1.964,1.964,0,0,0,1.958,1.958h3.917a1.964,1.964,0,0,0,1.958-1.958V21.625s7.285-9.4,11.241-14.472A1.954,1.954,0,0,0,33.24,4H6A1.954,1.954,0,0,0,4.453,7.153Z"
                transform="translate(3.87 3.833)"
                fill="#fff"
              />
              <path
                id="Path_176"
                data-name="Path 176"
                d="M0,0H47V47H0Z"
                fill="none"
              />
            </g>
          </g>
        </svg>

      </div>
      )}

      {/*currentStep ===2 &&(
        <ResultStep changeStep={changeStepHandler} snake_id={selectedSnake}/>
      )*/}

      
      
    </>
  );
}

export default RiskEstimation;
