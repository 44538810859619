import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/home";
import ImageIdentification from "./pages/imageIdentification";
import RiskEstimation from "./pages/riskEstimation";
import GuideLine from "./pages/guideline";
import Treatment from "./pages/treatment";


function App() {
  if (process.env.REACT_APP_STAGE === 'PROD')
  console.log = function no_console() {};
  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact={true} component={Home} /> 
          <Route path="/imageIdentification" component={ImageIdentification} />
          <Route path="/riskEstimation" component={RiskEstimation} />
          <Route path="/guideline" component={GuideLine} />
          <Route path="/treatment" component={Treatment} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
