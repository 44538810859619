import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
function MainContainer(props) {
  const { children ,fullWith , fullHeight} = props;
  return (
    <>
      <main className="main__container" style={{height : fullHeight ? '800px' : 'auto'}}>
        <section className="main__content">
          <div className={fullWith === true ? '':'container'} >{children}</div>
        </section>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
        />
      </main>
    </>
  );
}

export default MainContainer;
