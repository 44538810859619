import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/style/App.scss";
import { RecoilRoot } from "recoil";
import RecoilNexus from 'recoil-nexus'; 

ReactDOM.render(
  <RecoilRoot>
  <RecoilNexus />
  <React.StrictMode>
    <App />
  </React.StrictMode>
  </RecoilRoot>
,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
