import Seo from "./../seo";
import Header from "./../header";
import Footer from "./../footer";
import MainContainer from "./../../container/main";
function Layout(props) {
  const { children, title, withGrassFooter, withFixedItem,fullWith , fullHeight } = props;
  return (
    <>
      <Seo title={title} />
  
      <Header />
      <MainContainer fullWith={fullWith} fullHeight={fullHeight}>{children}</MainContainer>
      <Footer withGrassFooter={withGrassFooter} withFixedItem={withFixedItem} />
    </>
  );
}

export default Layout;
