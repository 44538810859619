import ContentBox from "./../contentBox";
import Forward from "../../../../assets/images/icon/next.png";
import Backward from "../../../../assets/images/icon/previous.png";
import Navigation from "../navigation";
import info from "../../../../assets/images/icon/info.png"
import { useRecoilState } from "recoil";
import { necroData } from "../../../../state/TreatmentState";
import { useState, useEffect } from "react";


function NecroStep(props){

    const [choices, setChoices] = useState(["Yes","No"]);
    const [{data, val}, setData] = useRecoilState(necroData);
    const [necro, setNecro] = useState(data);

    const handleAnswer = value => {
        console.log(value);
        setData(value);
        if(value === "No"){
            setData({data:value , val:0});
        }
        if(value === "Yes"){
            setData({data:value ,  val:1});
        }
      };

      console.log("the edema value is "+val);
    
//onChange={(event) => handleRadioChange(event , index)}
    return( 
    <>
     <ContentBox>
         <h2>Does the patient have any necrosis?</h2>
         <div className="symptom_checkbox">
             <form>
             <div className="form__container">

             { choices.map((choice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={choice}
                        defaultChecked={ data === choice ? true : false }
                        name="area"
                        id={`${index + 1}-id`}
                        onChange={(event) => handleAnswer(event.target.value)}
                    />

                    <label htmlFor={`${index + 1}-${index * 10}-ans`}>
                        {choice}
                    </label>
                    </div>);
                 })}  

            </div>
            </form>

         </div>

             <img className="previous"  src={Backward} onClick={() => {props.changeStep(6)}}/>
             <img className="next" src={Forward}  onClick={() => {props.nextStep(data,"",false,8)}}/>
         
    </ContentBox>
    </>
    )
}
export default NecroStep;