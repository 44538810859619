import { useState } from "react";
import "./../../assets/style/components/view-height.scss";
import Layout from "../../components/common/layout";
import BoxIcon from "../../assets/images/picture/box.png";
import SnakeIcon from "../../assets/images/picture/snake.png";
import GlobeIcon from "../../assets/images/picture/globe.png";
import map from "../../assets/images/picture/Iran-map.jpg";
import { ReactComponent as CloseModalIcon } from "../../assets/images/icon/delete.svg";
import { Link, useHistory } from "react-router-dom";
import { useEffect } from "react";
import AccountPanel from "../../components/pages/account/accountPanel";
import SignIn from "../../components/pages/account/SignIn";
import SignUp from "../../components/pages/account/SignUp";
import Modal from "react-bootstrap/Modal";
import ViewHeightFull from "./../../components/common/viewHeightFull";
import axios from "axios";
import {
  useRecoilState,
  
} from 'recoil';
import { guideState } from "../../state/GuideLineState";
import { AuthState, Username } from "../../state/AuthState";

function Home() {
  const [newslettersModalShow, setNewslettersModalShow] = useState(false);
  const [checkGuide, setCheckGuide] = useRecoilState(guideState);
  const [feedbackButton, setFeedbackButton] = useState("Sumbit")
  const [userEmail, setUserEmail] = useState("");
  const [comment, setComment] = useState("");
  const [emailError , SetEmailError]= useState("");
  const history = useHistory();
  const [{loggedIn, name}, setAuthState]= useRecoilState(AuthState);
  const [userName, setUserName] = useRecoilState(Username);

  useEffect(()=>{setCheckGuide()},[])

  console.log("logged state ",loggedIn)
  console.log("user state ",userName)
  ValidityState = () =>
    {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if( !re.test(userEmail) /*!userEmail.includes(re)*/)
      {
        SetEmailError("Not A Valid Email !");
        console.log("hey hey not valid")
        return false;
      }

      else
      {
        console.log("vaaalid");
        SetEmailError("");
        return true;
      }
     
      if(userEmail)
      {
        console.log("hey hey not valid 222")
        return false;
      }
      console.log("vaaalid")
      return true;
    }

    const validationSubmitHandler = async (event) => {

      const validation = ValidityState()
      event.preventDefault();
      if(validation)
      {
        console.log("valid")
        submitFeedbackHandler();
      }
      else
      {
        console.log("not valid")
        
      }
  
    }

  function ContactUsEvent(){
    var element = document.getElementById("contact-us");
    var form = document.getElementById("form");
    console.log("comonly used");
    element.classList.toggle("active");
    form.classList.toggle("active");
  }

  function SignInEvent(){
    var element = document.getElementById("signIn__modal");
    console.log("comonly used");
    element.classList.toggle("active");
  }

  
function SignUpEvent(){
  var element = document.getElementById("signUp__modal");
  console.log("comonly used");
  element.classList.toggle("active");
}

  const handleCommOnChange = (event) =>
  {
    setComment(event.target.value);
    //console.log("comment >"+event.target.value)
  }

  const handleEmailOnChange = (event) =>
  {
    setUserEmail(event.target.value);
    //console.log("email >"+event.target.value)
  }

  const submitFeedbackHandler = async ()  => {

    let SubmitedData = new FormData();
    SubmitedData.append('uploadId',55)
    SubmitedData.append('email',userEmail)
    SubmitedData.append('comment',comment)
    SubmitedData.append('basicAnswers',["True","False"])
    SubmitedData.append('infoAnswers',["True,True"])
    SubmitedData.append('featureAnswers',["True","True"])
    SubmitedData.append('rating',5)
    SubmitedData.append('vocation',2)
    SubmitedData.append('heyhey',"heyhey")
  

    console.log((SubmitedData.heyhey));

    /*for (var key of SubmitedData.entries()) {
      console.log(key);
  }*/

  await axios({
    method: 'post',
    url:process.env.REACT_APP_API_URL+'snake-image/user-feedback/',
    data:(SubmitedData),
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  }).then((response) => {
    console.log(response.data); 
    setFeedbackButton("Sent");
    ContactUsEvent();
    //setUploadId(""); 
    //changes(response.data);    
    //props.changeStep(2);                 
    //history.pushState('/')
  })

  }


  return (
    <>
    <Layout withGrassFooter={true}>
        <ViewHeightFull>
          <section className="home">
            <h1>Welcome to AI.Snake</h1>
            <div className="items">

              <Link to="/treatment">
                <div className="item">
                  <img
                    src={BoxIcon}
                    className="img-box"
                  />
                  <h6>Snakebite Treatment Tips</h6>
                  <div className="coming__soon">SOON</div>
                </div>
              </Link>

              <Link to="/imageIdentification">
                <div className="item">
                  <img
                    src={SnakeIcon}
                    className="snake"
                  />
                  <h6>Smart Snake Identification</h6>
                  <div className="coming__soon">SOON</div>
                </div>
              </Link>

              <Link to="/riskEstimation">
                <div className="item">
                <img
                  src={GlobeIcon}
                  className="globe"
                />
                <h6>Snakebite Risk Estimation</h6>
                  <div className="coming__soon">SOON</div>
                </div>
              </Link>
 
            </div>
            <div className="button-container"><button
            onClick={(e) => {
              
              setCheckGuide(true);
              /*atomFunction.toggleSupport();*/
              history.push('/Guideline')
              }} className="starter">Get Started</button>
              </div>


          <div class="contact_container" id="contact-us">
            <div className="contact__form" id="form"> 
            <h2> Connect with us </h2>
            <div className="form__container">
              <form>

              <div className="form__element">
              <label htmlFor="" className="is__required">
                    Email
                  </label>
              <input type="text" placeholder="ex: snaky@gmail.com" onChange={(event) => handleEmailOnChange(event)} required/>
              </div>

              <div className="form__element">
                <label htmlFor="">If you have any comments/ feedback or question:</label>
                <textarea type="text" placeholder="Leave your message here" onChange={(event) => handleCommOnChange(event)}/>
              </div>
              <div className="email_validity"><h3>{emailError}</h3></div>
              <div className="button__container"><button type="submit"className="submit" onClick={(event) => {validationSubmitHandler(event);}}>{feedbackButton} </button></div>
              </form>
            </div>
            </div>
            
          </div>
          <button className="contact__us" onClick={ContactUsEvent}>
           
          </button>
           
          </section>

        </ViewHeightFull>
      </Layout>

      <AccountPanel />
      
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={newslettersModalShow}
        onHide={() => setNewslettersModalShow(false)}
      >
        <div className="home__modal">
          <div className="modal__header">
            <h6>Sorry, This feature in under development</h6>
            <div
              className="close__modal"
              onClick={() => setNewslettersModalShow(false)}
            >
              <CloseModalIcon />
            </div>
          </div>
          <div className="modal__content">
            <h6>Enter your email to notify you when this feature is actived</h6>
            <input type="text" placeholder="Emial Address" />
          </div>
          <div className="modal__footer">
            <button type="submit" className="submit">
              Submit
            </button>
            <button
              className="return"
              onClick={() => setNewslettersModalShow(false)}
            >
              close
            </button>
          </div>
        </div>
      </Modal>

      
      </>
  );
}

export default Home;
