import { atom } from "recoil";

export const currentSnake = atom({
    key:'currentSnake',
    default:'1',
   });

export const firstPrediction = atom({
    key: "firstPrediction",
    default: false,
  });
  