export default {
  data: [
    {
        "Snake": {
            "index": 1,
            "name": "Echis",
            "venome_level": "N",
            "Common_name": "",
            "season": 3,
            "activity": 1
        },
        "area": [],
        "locationX": "36.41800000",
        "locationY": "54.97600000"
    },

    {
        "Snake": {
            "index": 2,
            "name": "Echis1",
            "venome_level": "V",
            "Common_name": "",
            "season": 2,
            "activity": 3
        },
        "area": [],
        "locationX": "33.41800000",
        "locationY": "55.97600000"
    },

    {
        "Snake": {
            "index": 3,
            "name": "Echis2",
            "venome_level": "S",
            "Common_name": "",
            "season": 1,
            "activity": 2
        },
        "area": [],
        "locationX": "36.41800000",
        "locationY": "53.97600000"
    },
 
],
};
