import { atom } from "recoil";

export const guideState = atom({
    key: "guideState",
    default: false,
})

export const guideStep = atom({
    key: "guideStep",
    default: 1,
})

export const userLoc = atom({
    key: "userLocation",
    default: {lat:0 , lng:87}
})