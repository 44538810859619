import LogoIcon from "../../../assets/images/icon/logo.png";
import { ReactComponent as GlobeIcon } from "../../../assets/images/icon/global.svg";
import { ReactComponent as HeaderBgIcon } from "../../../assets/images/picture/header__BG.svg";
import { Link } from "react-router-dom";
import { AuthState } from "../../../state/AuthState";
import { useRecoilState } from "recoil";
import { logout , logoutSecondary, whoami} from "../../../actions/auth";

function Header() {

  const [{loggedIn, name}, setAuth] = useRecoilState(AuthState);

  return (
    <>
      <header>
        <HeaderBgIcon className="header__bg" />
        <div className="container">
          <div className="wrapper">
            <div className="content">
              <a href="/">
                <div className="logo">
                  <img src={LogoIcon} alt="" />
                </div>
                </a>
              <div className="userAction">
              <a href="http://ai-nature.com/" target="_blank">
                <GlobeIcon />
              </a>
              { loggedIn ? <><button onClick={()=>{logout()}}>Log out</button></> : <></>}  
              </div>
              
              
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
