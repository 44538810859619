import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import Marker from "./marker";
const CustomGoogleMap = (props) => {
  const { userLocation, setUserLocation } = props;
  const [mapLoading, setMapLoading] = useState(true);
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const { latitude, longitude } = position.coords;
        setUserLocation({
          lat: latitude,
          lng: longitude,
        });
        setMapLoading(false);
        console.log(position.coords)
      },
      
      () => {
        setMapLoading(false);
      }
    );
  },[mapLoading]);

  const getMapOptions = () => {
    return {
      disableDefaultUI: true,
      mapTypeControl: true,
      streetViewControl: true,
      styles: [
        {
          featureType: "poi",
          elementType: "labels",
          stylers: [{ visibility: "on" }],
        },
      ],
    };
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      {!mapLoading ? (
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyBSfRV-o63zy_ogfsrCfCibwTp4j3HB5pU" }}
          defaultCenter={userLocation}
          defaultZoom={8}
          options={getMapOptions}
          onClick={(e) => {
            setUserLocation({
              lat: e.lat,
              lng: e.lng,
            });
          }}
        >
          <Marker
            lat={userLocation.lat}
            lng={userLocation.lng}
            snakeFace={props.snakeFace}
          />
        </GoogleMapReact>
      ) : (
        <button onClick={() => {setMapLoading(false)}}>give acess to the map</button>
      )}
    </div>
  );
};

export default CustomGoogleMap;
