import ContentBox from "./../contentBox";
import Forward from "../../../../assets/images/icon/next.png";
import Backward from "../../../../assets/images/icon/previous.png"
import arr_next from "../../../../assets/images/icon/arrow_next.png"
import arr_previous from "../../../../assets/images/icon/arrow_previous.png"
import guideimg from "../../../../assets/images/picture/joints_ref.png"
import info from "../../../../assets/images/icon/info.png"
import { edemaData } from "../../../../state/TreatmentState";
import { locationData } from "../../../../state/TreatmentState";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { Modal } from "react-bootstrap";
import { ReactComponent as CloseModalIcon } from "./../../../../assets/images/icon/delete.svg";
import { width } from "dom-helpers";

//import the images of symptoms here
import edema_head_g1_1 from "../../../../assets/images/picture/edema/edema_head_g2_1.jpg"
import edema_head_g1_2 from "../../../../assets/images/picture/edema/edema_head_g2_2.jpg"
import edema_head_g2_1 from "../../../../assets/images/picture/edema/edema_head_g4_1.jpg"
import edema_head_g2_2 from "../../../../assets/images/picture/edema/edema_head_g4_2.jpg"
import edema_trunk_g1_1 from "../../../../assets/images/picture/edema/edema_trunk_g2_1.jpg"
import edema_trunk_g1_2 from "../../../../assets/images/picture/edema/edema_trunk_g2_2.jpg"
import edema_trunk_g2_1 from "../../../../assets/images/picture/edema/edema_trunk_g4_1.jpg"
import edema_trunk_g2_2 from "../../../../assets/images/picture/edema/edema_trunk_g4_2.jpg"
import edema_limb_g1_1 from "../../../../assets/images/picture/edema/edema_limb_g1_1.jpg"
import edema_limb_g1_2 from "../../../../assets/images/picture/edema/edema_limb_g1_2.jpg"
import edema_limb_g2_1 from "../../../../assets/images/picture/edema/edema_limb_g2_1.jpg"
import edema_limb_g2_2 from "../../../../assets/images/picture/edema/edema_limb_g2_2.jpg"
import edema_limb_g3_1 from "../../../../assets/images/picture/edema/edema_limb_g3_1.jpg"
import edema_limb_g3_2 from "../../../../assets/images/picture/edema/edema_limb_g3_2.jpg"
import edema_limb_g4_1 from "../../../../assets/images/picture/edema/edema_limb_g4_1.jpg"
import edema_limb_g4_2 from "../../../../assets/images/picture/edema/edema_limb_g4_2.jpg"



function activeSymptoms(val){

    if(val === "Yes"){
    const element = document.getElementById("sub_choice");
    element.classList.add("active");
    console.log(val);
    }

    if(val === "No"){
        const element = document.getElementById("sub_choice");
        element.classList.remove("active");
        console.log(val);
        }
}


function EdemaStep(props){


      useEffect(() => {
        if(choice === "Yes" ){
            activeSymptoms("Yes");
        }
    },[])

    const [choices, setChoices] = useState(["Yes","No"]);
    const [secondaryChoices, setSecondaryChoices] = useState([]); 
    const [painLocation , setPainLocation] = useRecoilState(locationData);
    const [{choice, subChoice , val}, setEdema] = useRecoilState(edemaData);
    const [modalState, setModalState] = useState(false);
    const [selectedGallery, setSelectedGallery] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedRefs, setSelectedRefs] = useState([]);
    const [selectedRef, setSelectedRef] = useState(null);
    const [selectedSeries, setSelectedSeries] = useState([]);
    const [selectedSeriesRefs, setSelectedSeriesRefs] = useState([]);




    var i = 0;
    var slider_img = document.getElementsByClassName("slider-img");


    function prev(){
        if(i <= 0) i = selectedGallery.length;	
        i--;
        return setImg();			 
    }
    
    function next(){
        if(i >= selectedGallery.length-1) i = -1;
        i++;
        return setImg();			 
    }
    
    
    function setImg(){

        console.log(slider_img)
        //slider_img.setAttribute('src', selectedGallery[i]);
        setSelectedImage(selectedGallery[i])
        setSelectedRef(selectedRefs[i])

    
    }


    console.log("the edema value is "+val);

    const handleAnswer = (value , isSub) => {

        if(isSub){
            setEdema({choice:value , subChoice});
            if(value === "No"){
                setEdema({choice:value , subChoice , val:0});
            }
            if(value === "Yes"){
                setEdema({choice:value , subChoice , val:1});
            }
            console.log("choice updated ",value);
        }else{
            setEdema({choice , subChoice:value});
            console.log("Subchoice updated");
        }
      };

    useEffect(() => {
        if(painLocation==="Head or Face"){
            setSelectedSeriesRefs([["", ""], ["", ""]]);
            setSelectedSeries([[edema_head_g1_1, edema_head_g1_2], [edema_head_g2_1, edema_head_g2_2]]);
            setSecondaryChoices(["Grade 2, local edema, just in neurocranium or face","Grade 4, edema that extends to the neck and causes compression of air channels"]);
        }

        if(painLocation==="Limb"){
            setSelectedSeriesRefs([["World Health Organization. (2010). Dahomey burrowing asp Atractaspis dahomeyensis bite, local blistering and swelling", "World Health Organization. (2010). Dahomey burrowing asp Atractaspis dahomeyensis bite, local blistering and swelling"],
             ["World Health Organization. (2010). Rhinoceros viper Bitis gabonica bite on wrist, swelling, bruising and early necrosis at site of fang punctures", "World Health Organization. (2010). Rhinoceros viper Bitis gabonica bite on wrist, swelling, bruising and early necrosis at site of fang punctures"], 
             ["World Health Organization. (2010). Black-necked spitting cobra Naja nigricollis bite on the upper forearm in a 9-yearold girl, Kaltungo, Nigeria, swelling of entire arm and adjacent trunk with blistering and early signs of necrosis on 4th day after the bite", "World Health Organization. (2010). Saw-scaled viper Echis ocellatus bite on wrist 4 days previously, treated with topical application of Crinum yuccaeflorum (Amaryllidaceae) (gadali in Hausa) root by a traditional doctor, Kaltungo, Nigeria: showing swelling of entire arm and trunk"], 
             ["World Health Organization. (2010). Puff adder Bitis arietans bite on hand in a 7-year-old boy, Zaria, Nigeria, massive swelling of arm and trunk 22 hours after bit", "World Health Organization. (2010). Puff adder Bitis arietans bite on hand in a 7-year-old boy, Zaria, Nigeria, massive swelling of arm and trunk 22 hours after bit"]]);
            setSelectedSeries([[edema_limb_g1_1, edema_limb_g1_2], [edema_limb_g2_1, edema_limb_g2_2], [edema_limb_g3_1, edema_limb_g3_2], [edema_limb_g4_1, edema_limb_g4_2]]);
            setSecondaryChoices(["Grade 1, local edema, not extending beyond one main joint ",
                                "Grade 2, edema that extends beyond one main joint and is limited between two main joints",
                                "Grade 3, edema that extends beyond two main joints and is limited to three main joints",
                                "Grade 4, edema that extends from limbs to trunk/half of the body" ]);
        }

        if(painLocation==="Trunk"){
            setSelectedSeriesRefs([["", ""], ["", ""]]);
            setSelectedSeries([[edema_trunk_g1_1, edema_trunk_g1_2], [edema_trunk_g2_1, edema_trunk_g2_2]]);
            setSecondaryChoices(["Grade 2, limited, local edema","Grade 4, extensive edema in the trunk"]);
        }
    }, [])
    
    
    
//onChange={(event) => handleRadioChange(event , index)}
    return( 
    <>
     <ContentBox>
         <h2>Does the patient have edema?</h2>
         <div className="symptom_checkbox">
             <form>
             <div className="form__container">

             { choices.map((Pchoice , index) => {
                    console.log(index)
                    return(<div className="element">
                    <input
                        type="radio"
                        value={Pchoice}
                        name="area"
                        defaultChecked={ choice === Pchoice ? true : false }
                        id={`${index + 1}-${index * 10}-ans`}
                        onChange={(event)=>{activeSymptoms(Pchoice); handleAnswer(event.target.value, true)}}
                    />
                    <label htmlFor={`${index + 1}-${index * 10}-ans`}>
                        {Pchoice}
                    </label>
                    </div>);
                 })}      

                 <div className= "choiceguide">
                 <div className="confirmedSymptom" id="sub_choice">
                     
            { secondaryChoices.map((Schoice , index) => {

                    return(<div className="element">
                    
                    <input
                        type="radio"
                        value={Schoice}
                        name="sub-area"
                        id={`${index + 1}-${index * 20}-ans`}
                        defaultChecked={ subChoice === Schoice ? true : false }
                        onChange={(event) => {handleAnswer(event.target.value, false)}}
                        
                    />
                    <img className="infoimg"  src={info} onClick={() => {setModalState(true); setSelectedGallery(selectedSeries[index]) ; setSelectedImage(selectedSeries[index][0]) ; setSelectedRefs(selectedSeriesRefs[index]) ; setSelectedRef(selectedSeriesRefs[index][0]) ; console.log(index)}} />
                    <label htmlFor={`${index + 2}-${index * 10}-ans`}>
                        {Schoice}
                    </label>
                    </div>);

                 })} 

                 {painLocation==="Limb" ? (<img className="guideimg"  src={guideimg} />): (<> </>)}
                 </div> 
                 </div>

            </div>
            </form>
         </div>

         <Modal dialogClassName="symptomGallery"
             aria-labelledby="contained-modal-title-vcenter"
             size="lg"
             show={modalState}
             onHide={() => setModalState(false)}
             backdrop={ 'static' }
             keyboard={ false }
 
             >
             <div className="home__modal">
               <div className="modal__header">
               <h4> Samples of the chosen grade:</h4>
                 
                 <div
                   className="close__modal"
                   onClick={() => {setModalState(false)}}
                 >
                   <CloseModalIcon />
 
                   </div>
               </div>


               <div class="slider">
                    <div class="img-box">
                    <img className="slider-img" src={selectedImage} />
                    </div>

                    <img className="imgButton" src={arr_previous} onClick={() => {prev()}} />
                    <img className="imgButton" src={arr_next}     onClick={() => {next()}} /> 
                
                </div>


               <h7></h7>
               <h7>{selectedRef}</h7>
               <div className="modal__footer">
             
           </div>
         
             </div>
            </Modal>

            

             <img className="previous"  src={Backward} onClick={() => {props.changeStep(2)}}/>
             <img className="next"  src={Forward}  onClick={() => {props.nextStep(choice,subChoice,true,4)}}/>





    </ContentBox>
    </>
    
    )
    
}

export default EdemaStep;