import {atom} from 'recoil';

export const morphological_feature = atom({
    key: 'edemaData',
    default: {
      actual_pattern: '',
      actual_eye: 0,
      actual_head: 0,
    },
  });

  export const pattern_feature = atom({
    key: 'pattern_feature',
    default:''
  });

export const core_feature = atom({
  key: 'core_feature',
  default:[["snake"],["venom"]]
})