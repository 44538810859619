import ContentBox from "./../contentBox";
import Forward from "../../../../assets/images/icon/next.png";
import Backward from "../../../../assets/images/icon/previous.png"
import info from "../../../../assets/images/icon/info.png"
import { useState, useEffect } from "react";
import { useRecoilState } from "recoil";
import { neuroData } from "../../../../state/TreatmentState";
import { Modal } from "react-bootstrap";
import { ReactComponent as CloseModalIcon } from "./../../../../assets/images/icon/delete.svg";
import arr_next from "../../../../assets/images/icon/arrow_next.png"
import arr_previous from "../../../../assets/images/icon/arrow_previous.png"

//import the images of symptoms here
import neuro_g1_1 from "../../../../assets/images/picture/neuro/neuro_g1_1.jpg"
import neuro_g1_2 from "../../../../assets/images/picture/neuro/neuro_g1_2.jpg"
import neuro_g2_1 from "../../../../assets/images/picture/neuro/neuro_g2_1.jpg"
import neuro_g2_2 from "../../../../assets/images/picture/neuro/neuro_g2_2.jpg"
import neuro_g3_1 from "../../../../assets/images/picture/neuro/neuro_g3_1.jpg"
import neuro_g3_2 from "../../../../assets/images/picture/neuro/neuro_g3_2.jpg"
import neuro_g4_1 from "../../../../assets/images/picture/neuro/neuro_g4_1.jpg"
import neuro_g4_2 from "../../../../assets/images/picture/neuro/neuro_g4_2.jpg"



function activeSymptoms(val){

    if(val === "Yes"){
    const element = document.getElementById("sub_choice");
    element.classList.add("active");
    console.log(val);
    }

    if(val === "No"){
        const element = document.getElementById("sub_choice");
        element.classList.remove("active");
        console.log(val);
        }
}



function NeuroStep(props){

      useEffect(() => {
        if(choice === "Yes" ){
            activeSymptoms("Yes");
        }
    },[])

    const [choices, setChoices] = useState(["Yes","No"]);
    const [secondaryChoices, setSecondaryChoices] = useState(["Grade 1, local anesthesia in bite site and/or fasciculations",
     "Grade 2, muscarinic syndrome: Hypersalivation, nausea and vomiting, sudation, diarrhea",
     "Grade 3, bilateral ptosis and/or visual, auditory or another sensory paralysis of the cranial nerves",
     "Grade 4, altered mental status and/or respiratory muscle weakness causing difficulty breathing" ]);
     const [{choice, subChoice, val}, setNeuro] = useRecoilState(neuroData);
     const [modalState, setModalState] = useState(false);
     const [selectedGallery, setSelectedGallery] = useState([]);
     const [selectedImage, setSelectedImage] = useState(null);
     const [selectedRefs, setSelectedRefs] = useState([]);
     const [selectedRef, setSelectedRef] = useState(null);
 
 
     var i = 0;
     var slider_img = document.getElementsByClassName("slider-img");
     var images = [[neuro_g1_1, neuro_g1_2], [neuro_g2_1, neuro_g2_2], [neuro_g3_1, neuro_g3_2], [neuro_g4_1, neuro_g4_2]];
     var refs = [["", ""], ["", ""],
     ["World Health Organization. (2010). Formal clinical testing for ptosis in a patient with early elapid neurotoxicty", 
     "World Health Organization. (2010). Black mamba bite Dendroaspis polylepis Ngwelazana, South Africa: showing ptosis, external ophthalmoplegia and facial paralysis"], 
     ["", ""]];


 
 
 
     function prev(){
         if(i <= 0) i = selectedGallery.length;	
         i--;
         return setImg();			 
     }
     
     function next(){
         if(i >= selectedGallery.length-1) i = -1;
         i++;
         return setImg();			 
     }
     
     
     function setImg(){
 
         console.log(slider_img)
         //slider_img.setAttribute('src', selectedGallery[i]);
         setSelectedImage(selectedGallery[i])
         setSelectedRef(selectedRefs[i])
 
     
     }
 

     console.log("the neuro value is "+val);

     const handleAnswer = (value , isSub) => {

        if(isSub){
            if(value === "No"){
                setNeuro({choice:value , subChoice , val:0});
            }
            if(value === "Yes"){
                setNeuro({choice:value , subChoice , val:1});
            }
            console.log("choice updated ",value);
        }else{
            setNeuro({choice , subChoice:value});
            console.log("Subchoice updated");
        }
      };
    
    
//onChange={(event) => handleRadioChange(event , index)}
    return( 
    <>
     <ContentBox>
         <h2>Does the patient have any neurotoxic symptom?</h2>
         <div className="symptom_checkbox">
             <form>
             <div className="form__container">

             { choices.map((Pchoice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={Pchoice}
                        name="area"
                        defaultChecked={ choice === Pchoice ? true : false }
                        id={`${index + 1}-${index * 10}-ans`}
                        onChange={(event)=>{activeSymptoms(Pchoice); handleAnswer(event.target.value, true)}}
                    />

                    <label htmlFor={`${index + 1}-${index * 10}-ans`}>
                        {Pchoice}
                    </label>
                    </div>);
                 })}      

                 <div className="confirmedSymptom" id="sub_choice">
            { secondaryChoices.map((Schoice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={Schoice}
                        name="sub-area"
                        id={`${index + 1}-${index * 20}-ans`}
                        defaultChecked={ subChoice === Schoice ? true : false }
                        onChange={(event) => {handleAnswer(event.target.value, false)}}
                    />
                    <img className="infoimg"  src={info} onClick={() => {setModalState(true); setSelectedGallery(images[index]) ; setSelectedImage(images[index][0]) ; setSelectedRefs(refs[index]) ; setSelectedRef(refs[index][0]) ; console.log(index)}} />

                    <label htmlFor={`${index + 2}-${index * 10}-ans`}>
                        {Schoice}
                    </label>
                    </div>);
                 })}       
            </div>

            </div>
            </form>
         </div>
            
         <Modal dialogClassName="symptomGallery"
             aria-labelledby="contained-modal-title-vcenter"
             size="lg"
             show={modalState}
             onHide={() => setModalState(false)}
             backdrop={ 'static' }
             keyboard={ false }
 
             >
             <div className="home__modal">
               <div className="modal__header">
               <h4> Samples of the chosen grade:</h4>
                 
                 <div
                   className="close__modal"
                   onClick={() => {setModalState(false)}}
                 >
                   <CloseModalIcon />
 
                   </div>
               </div>


               <div class="slider">
                    <div class="img-box">
                    <img className="slider-img" src={selectedImage} />
                    </div>

                    <img className="imgButton" src={arr_previous} onClick={() => {prev()}} />
                    <img className="imgButton" src={arr_next}     onClick={() => {next()}} /> 
                
                </div>


               <h7></h7>
               <h7>{selectedRef}</h7>
               <div className="modal__footer">
             
           </div>
         
             </div>
            </Modal>

             <img src={Backward} className="previous"  onClick={() => {props.changeStep(5)}}/>
             <img src={Forward} className="next"   onClick={() => {props.nextStep(choice,subChoice,true,7)}}/>

    </ContentBox>
    </>
    )
}
export default NeuroStep;