import ContentBox from "./../contentBox";
import Forward from "../../../../assets/images/icon/next.png";
import Backward from "../../../../assets/images/icon/previous.png"
import { useState } from "react";
import { RecoilState, useRecoilState } from "recoil";
import { locationData } from "../../../../state/TreatmentState";


function SymptomStep(props){

    const [choices, setChoices] = useState(["Head or Face","Limb","Trunk"]);
    const [data, setData] = useRecoilState(locationData);

    const handleAnswer = value => {
        console.log(value);
        setData(value);
      };

    console.log("this data ",data);
    
//onChange={(event) => handleRadioChange(event , index)}
    return( 
    <>
     <ContentBox>
         <h2>Where is the bite area ?</h2>
         <div className="bitearea_checkbox">
             <form>
             <div className="basic__form__container">

                 { choices.map((choice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={choice}
                        defaultChecked={ data === choice ? true : false }
                        name="area"
                        id={`${index + 1}-id`}
                        onChange={(event) => handleAnswer(event.target.value)}
                    />
                    <label htmlFor={`${index + 1}-${index * 10}-ans`}>
                        {choice}
                    </label>
                    </div>);
                 })}                
            </div>
            </form>
         </div>
         
             <img className="previous"  src={Backward} onClick={() => {props.changeStep(0)}} />
             <img className="next"  src={Forward} onClick={() => {props.nextStep(data,"",false,2)}} />

    </ContentBox>
    </>
    )
}
export default SymptomStep;