import { useState, useRef , useEffect } from "react";
import { ReactComponent as SnakePlusIcon } from "./../../../../assets/images/icon/snake-plus.svg";
import SnakeOne from "./../../../../assets/images/picture/snake_one.png";
import SnakeTwo from "./../../../../assets/images/picture/snake_two.png";
import SnakeThree from "./../../../../assets/images/picture/snake_three.png";
import Snake from "./../../../../assets/images/picture/snake_image.png";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as CloseModalIcon } from "./../../../../assets/images/icon/delete.svg";
import { ReactComponent as CloseStepIcon } from "./../../../../assets/images/icon/delete-red.svg";
import { ReactComponent as ArrowDown } from "./../../../../assets/images/icon/arrow-down.svg";
import ViewHeightFull from "./../../../common/viewHeightFull";
import { toast } from "react-toastify";
import Collapse from "react-bootstrap/Collapse";
import CustomGoogleMap from "./googleMap";
import { useHistory } from "react-router";
import {parse, stringify, toJSON, fromJSON} from 'flatted';
import Upload_id from "../../../../context/content";
import axios from "axios";
import * as guide from "../../../../state/GuideLineState";
import {useRecoilState , useRecoilValue} from 'recoil';
import { guideState , guideStep } from "../../../../state/GuideLineState";
import { firstPrediction } from "../../../../state/StateMangement";

function UploadImageStep(props) {
  const [image, setImage] = useState(null)
  const [uploadId, setUploadId] = useState(0)
  const [uploadSate, setUploadState] = useState("beforeUpload");
  const [hasInformation, setHasInformation] = useState(false);
  const [hasAcess, setHasAcess] = useState("");
  const [uploadDetailsModal, setUploadDetailsModal] = useState(false); 
  const [locationCollapse, setLocationCollapse] = useState(true);
  const [sizeCollapse, setSizeCollapse] = useState(true);
  const [snakeFace, setSnakeFace] = useState(null);
  const guidevalue = useRecoilValue(guideState);
  const [currentStep, setCurrentStep] = useRecoilState(guideStep);
  const [firstActivation, setFirstActivation] = useRecoilState(firstPrediction)


  useEffect( () => {console.log("checked state", guideState); setFirstActivation(false)}
  ,[])
  
  
  const [userLocation, setUserLocation] = useState({
    lat: -80,
    lng: 37,
  });
  const [detailModel, setDetailModel] = useState({
    location: {
      lat: "none",
      lng: "none",
    },
    size: null,
  });
  const [sizeDetail, setSizeDetail] = useState(null);
  const SnakeSelectedImage = useRef(null);
  const FileInput = useRef(null);

  const history = useHistory()

  const checkPermission = async () => {
    const permissionStatus = await navigator?.permissions?.query({name: 'geolocation'});
    const hasPermission = permissionStatus?.state;
    setHasAcess(hasPermission)

    console.log(hasPermission);
  }



  const uploadHandler = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      var selectedFile = fileInput.files[0];
      if (selectedFile.size < 20200000) {
        setImage(event.target.files[0]);
        setUploadState("afterUpload");
        setUploadDetailsModal(true);
        var reader = new FileReader();
        reader.onload = function (event) {
          SnakeSelectedImage.current.src = event.currentTarget.result;
          setSnakeFace(SnakeSelectedImage.current.src);
        };
        reader.readAsDataURL(selectedFile);
        
      } else {
        toast.warn("The selected photo is larger than the specified size (20 MB)");
        fileInput.value = null;
      }
    }
  };


  const resetHandler = () => {
    FileInput.current.value = null;
    setUploadState("beforeUpload");
    setHasInformation(false);
    setSizeDetail(null);
    setDetailModel({
      location: {
        lat: "none",
        lng: "none",
      },
      size: null,
    });
  };

  const submitDetailHandler = () => {

      //console.log("lat:"+position.coords.latitude+" lng:"+ position.coords.longitude);
      //setUserLocation({location: {lat: position.coords.latitude, lng: position.coords.longitude}});
      setDetailModel({
        location: {
          lat: userLocation.lat,
          lng: userLocation.lng,
        },
        size: sizeDetail,
      });
      console.log(detailModel);
      //changesv2(image);
    
    setHasInformation(true);
    setSizeDetail(detailModel.size && detailModel.size);
    setUploadDetailsModal(false);
  };

  const changes = (v) => {props.changeId(v)}
  const changesv2 = (v) => {props.getImage(v);console.log("image remonté "+v)}

  useEffect(() => {
    //submitDetailHandler();
    checkPermission();
  }, [])


////////////////////// async function to manage the upload of image and side data
  const dataUploadHandler = async () => {
    
    let formField = new FormData()
    console.log(userLocation)
    formField.append('image',image)
    formField.append('size',detailModel.size)
    formField.append('location',stringify(userLocation))
    changesv2(snakeFace);
    console.log(detailModel);
    /*for (var value of formField.values()) {
      console.log(value);
    }*/

    await axios({
      method: 'post',
      url:process.env.REACT_APP_API_URL+'snake-image/upload-image/',
      data:(formField),
  
    }).then((response) => {
      console.log("je suis la "+response.data); 
      setUploadId(""); 
      changes(response.data);  
      props.changeStep(2);            
      //history.pushState('/')
    })
    
    //useEffect(() => { setUploadId(id) }, [])
    
    
   
    
  };
  
  useEffect(() => { 
    if(hasAcess=="granted"){
      setDetailModel({
        location: {
          lat: userLocation.lat,
          lng: userLocation.lng,
        },
        
      });
    }

    else
    {
      setDetailModel({
        location: {
          lat: "none",
          lng: "none",
        },
        
      });
    }
    }, [userLocation , hasAcess])

  return (
    <>
    <Upload_id.Provider value={uploadId} >
      <div className="upload__image__step">
        {guidevalue ? <CloseStepIcon className="close__icon" onClick={() => {
         setCurrentStep(3);
         history.push("/guideline")
          }} /> : <CloseStepIcon className="close__icon" onClick={() => {
            setCurrentStep(3);
            history.push("/guideline")
             }} />} 
        <div className="image__identification__uploader">
          <div className="head__text">


            {guidevalue ? <h2> Do you have any image of the snake?</h2> : <h2>Do you have any image of the snake?</h2>}  

            <h2>{guidevalue}</h2>
            
            {uploadSate === "afterUpload" && (
              <h5>You show us the snake's image,  we tell you which snake it is!</h5>
            )}
          </div>
          <div
            className="upload__box"
            style={{
              border: uploadSate === "afterUpload" && "none",
            }}
          >
            <h4>Drag and Drop or Select your Image</h4>
            <input
              type="file"
              name=""
              id=""
              accept="imag e/png, image/jpg, image/jpeg"
              ref={FileInput}
              onChange={(event) => uploadHandler(event) } //, (e)=>setImage(e.target.files[0])
            />
            <SnakePlusIcon className="snake__plus__icon" />
            {
            uploadSate === "afterUpload" && (
              <ViewHeightFull>
                <div className="after__upload">
                  <img src="" alt="" ref={SnakeSelectedImage} />
                  {

                  
                  hasInformation && (
                    <>
                      <div className="image__overlay"></div>
                      <div className="information">
                        {
                        detailModel && detailModel.location &&(
                          <div className="location">
                            
                           
                            <span>Lng: {detailModel.location.lng}</span>
                            <span>Lat: {detailModel.location.lat}</span>
                          </div>
                        )

                        }
                        <div className="size">
                        {sizeDetail && <span>Size: {sizeDetail}</span>}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </ViewHeightFull>
            )}
          </div>
          {uploadSate === "afterUpload" && (
            <div className="upload__actions">
              <div className="two__button">
                {hasInformation && (
                  <button
                    className="orange__button"
                    onClick={() => setUploadDetailsModal(true)}
                  >
                    Change Detail
                  </button>
                )}
                {!hasInformation && (
                  <button
                    className="orange__button"
                    onClick={() => setUploadDetailsModal(true)}
                  >
                    Add Detail
                  </button>
                )}
                <button className="blue__button" onClick={() => resetHandler()}>
                  Reset
                </button>
              </div>
              <button
                className="green__button"
                onClick={  async() => {props.changeStep(2); await dataUploadHandler();}}
              >
                Confirm and Identify
              </button>
            </div>
          )}
          {uploadSate === "beforeUpload" && (
            <div className="upload__hints">
             
            </div>
          )}
        </div>
        {uploadSate === "beforeUpload" && (
          <div className="to__know">
            <span>Input photo must be from the back surface of the snake (at least 50% visible) not from the side of the body or snake's head</span>
            <div className="items">
              <div className="item">
                <div className="picture">
                  <img src={SnakeOne} alt="" />
                </div>
                
              </div>
              <div className="item">
                <div className="picture">
                  <img src={SnakeTwo} alt="" />
                </div>
                
              </div>
              <div className="item">
                <div className="picture">
                  <img src={SnakeThree} alt="" />
                </div>
                
              </div>
            </div>
          </div>
        )}
      </div>
      <Modal
        dialogClassName="my-modal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={uploadDetailsModal}
        onHide={() => setUploadDetailsModal(false)}
      >
        <div className="home__modal">
          <div className="modal__header">
            <h6>Add more information for a more detailed search</h6>
            <div
              className="close__modal"
              onClick={() => setUploadDetailsModal(false)}
            >
              <CloseModalIcon />
            </div>
          </div>
          <div className="modal__content pt-0">
            <div className="items">
              <div className="item mb-5" style={{ cursor: "pointer" }}>
                <div
                  className="title d-flex justify-content-between align-items-center"
                  onClick={() => setLocationCollapse((prevState) => !prevState)}
                >
                  <h6 className="mb-0">Location</h6>
                  <ArrowDown />
                </div>
                <hr />
                <Collapse in={locationCollapse}>
                  <div
                    className="location"
                    style={{
                      width: "100%",
                      height: "247px",
                    }}
                  >
                    <CustomGoogleMap
                      snakeFace={snakeFace}
                      userLocation={userLocation}
                      setUserLocation={setUserLocation}
                    />
                    {/* <iframe
                      src="https://maps.google.com/maps?width=507&amp;height=274&amp;hl=en&amp;q=University of Oxford&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                      width="600"
                      height="450"
                      frameBorder="0"
                      style={{ border: 0, width: "100%", height: "100%" }}
                      allowFullScreen=""
                      aria-hidden="false"
                      tabIndex="0"
                    ></iframe> */}
                  </div>
                </Collapse>
              </div>
              <div className="item" style={{ cursor: "pointer" }}>
                <div
                  className="title d-flex justify-content-between align-items-center"
                  onClick={() => setSizeCollapse((prevState) => !prevState)}
                >
                  <h6 className="mb-0">Size</h6>
                  <ArrowDown />
                </div>
                <hr />
                <Collapse in={sizeCollapse}>
                  <div className="content">
                    <div className="items d-flex">
                      <div
                        className="item d-flex align-items-center mr4"
                        style={{
                          marginRight: "39px",
                        }}
                        
                      >
                        <input
                          type="radio"
                          name="input-check"
                          id="below-one"
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "7px",
                          }}
                          defaultChecked={
                            sizeDetail && sizeDetail === "Below one meter"
                          }

                          onChange={() =>
                            setDetailModel({
                              ...detailModel,
                              size: "Bellow one meter",
                            })
                          }
                        />
                        <label htmlFor="below-one">Below one meter</label>
                      </div>
                      <div
                        className="item d-flex align-items-center"
                        onChange={() =>
                          setDetailModel({
                            ...detailModel,
                            size: "More than one meter",
                          })
                        }
                      >
                        <input
                          type="radio"
                          name="input-check"
                          id="more-than"
                          style={{
                            width: "18px",
                            height: "18px",
                            marginRight: "7px",
                          }}
                          defaultChecked={
                            sizeDetail && sizeDetail === "More than one meter"
                          }

                          onChange={() =>
                            setDetailModel({
                              ...detailModel,
                              size: "More than one meter",
                            })
                          }
                        />
                        <label htmlFor="more-than">More than one meter</label>
                      </div>
                    </div>
                  </div>
                </Collapse>
              </div>
            </div>
          </div>
          <div className="modal__footer">
            <button
              type="submit"
              className="submit"
              onClick={() => submitDetailHandler()}
            >
              Submit
            </button>
            <button
              className="return"
              onClick={() => setUploadDetailsModal(false)}
            >
              Skip
            </button>
          </div>
        </div>
      </Modal>
      </Upload_id.Provider>
    </>
  );
}

export default UploadImageStep;
