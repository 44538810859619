import { guideState , guid } from "../../../../state/GuideLineState"
import { RecoilState } from "recoil";
import { useState , useEffect } from "react";
import axios from "axios";
import Snake1Icon from "../../../../assets/images/icon/snake1.svg";
import Snake3Icon from "../../../../assets/images/icon/snake3.svg";
import Hospital from "../../../../assets/images/icon/hospital_new.png";
import SnakeBite from "../../../../assets/images/icon/snakeBite.png";
import clickOnMap from "../../../../assets/images/icon/click-on-map.png";
import FirstFeature from "../../../../assets/images/picture/box.png";
import SecondFeature from "../../../../assets/images/picture/snake.png";
import ThirdFeature from "../../../../assets/images/picture/globe.png";

function DataStep (props){

  const [randomNotation , setRandomNotation]= useState('');
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      setIsLoaded(true)
    }, 4500);
  }, []);


  useEffect (()=> {
    async function getProcessText(){
      let response = await axios.get(process.env.REACT_APP_API_URL+'snake-image/process-text/')
      response = await response;
      setRandomNotation(response.data.text)
      console.log("lezgoow"+response.data)

    }
    getProcessText();
  }, []);
 
    return(<>
     <div className="Smooth__screen">
    {props.redirection === "/" ? (<h2>We are glad that we could help you</h2>) : (<h2>We are sorry that we couldn't help you in this case</h2> )} 
    <div className="map__guide" >
      { props.redirection === "/RiskEstimation" &&(
        <>
        <h3> Now we can redirect you to the Risk Map where you can get more details about snakes and their distributions</h3>
        <ul>
            <li><img src={clickOnMap} height={48} width={55} /> Click on any location on the map to get the list of present species </li>
            <li><img src={SnakeBite} height={50} width={58}/> Observe the reported snakebite locations</li>
            <li><img src={Hospital} height={58} width={58}/> Check the information reported by the medical centers</li>
        </ul>
        </>
      )}
      {
        props.redirection === "/" &&(
          <>
          <h3>Now we can redirect you to the Home page where you can access features to:</h3>
          <ul className="features-resume">
            <li><img src={FirstFeature} height={80} width={90} /> Obtain evidence-based snakebite treatment tips </li>
            <li><img src={SecondFeature} height={80} width={90}/> Identify snake species by images or locations</li>
            <li><img src={ThirdFeature} height={80} width={90}/> Obtain details about snake species and their distributions</li>
          </ul>
          </>
        )
      }
      

    <div class="progress-button">
    <button class="bar">
    <span class="progress">{ isLoaded && (<h2 className="button_context" onClick={() => {window.location.href=props.redirection}}>Redirect Me</h2>)}</span>
    </button>
    </div>
    {/*<div className="load__section">
    <div className="loading">
    <div className="loading__parent">
      <div className="loading__child"></div>
    </div>
  </div>
  <div className="notice">
    <p style={{fontSize:20}}>Meanwhile your waiting did you know </p> 
    <p style={{color: "purple" , fontWeight:"bold" , fontSize:20}}>{randomNotation}</p> 
  </div>
  </div>*/}
    </div>

  </div>
    </>);
}

export default DataStep;