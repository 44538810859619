
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
import { useState , useEffect } from 'react';
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from 'swiper';
import 'swiper/swiper-bundle.css';
import BandPattern from "../../../../assets/images/picture/pattern4.jpg";
import LinePattern from "../../../../assets/images/picture/pattern3.jpg";
import ZigZagPattern from "../../../../assets/images/picture/pattern2.jpg";
import UniPattern from "../../../../assets/images/picture/pattern1.jpg";
import Qmark from "../../../../assets/images/icon/questionMark.png";
import { morphological_feature , pattern_feature } from "../../../../state/filterState";
import { useRecoilState } from "recoil";
import Oval from "../../../../assets/images/picture/head1.jpg";
import Triangle from "../../../../assets/images/picture/head2.jpg";
SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

const pattern_list = ["U", "Z", "C", "L"];

const MobileFilter = () => {

  const [pattern, setPattern] = useState(["Unicolor, reticulated", "Zigzag", "Cross bars", "Longitudinal line" ])
    const [patternImage, setPatternImage] = useState(Qmark);

  const [head, setHead] = useState(["Triangular" , "Roundish"]);
  const [headImage, setHeadImage] = useState(Qmark);

  const [{actual_pattern , actual_eye , actual_head} ,setFilters ] = useRecoilState(morphological_feature)
  const [ actualPattern ,setActualPattern ] = useRecoilState(pattern_feature)

  const [isChecked , setIsChecked] = useState (null);

  const [selected, setSelected] = useState(null);
  const [eyeSelected , setEyeSelected] = useState(null);
  const [headSelected, setHeadSelected] = useState(null);

  function ClearAll(){
    setFilters({actual_pattern:'', actual_eye:0, actual_head:0});
    setHeadSelected(null);
    setSelected(null);
    setEyeSelected(null);
    var filtered = document.getElementsByClassName("snake-element");

    var delayInMilliseconds = 350;

    setTimeout(function() {
    for(var i=0 ; i < filtered.length ; i++){
    filtered[i].classList.remove("filtered");
    }
    }, delayInMilliseconds);
    console.log("removed all filters");
  }

  
  function onPatternChange(event){
    setFilters({actual_pattern:event, actual_eye , actual_head})
    setActualPattern(event)
    console.log("value of pattern > ",actual_pattern)
    if(event == "U"){
        console.log("we are on the field")
        setPatternImage(UniPattern)
        //isChecked(null)
    }
    else if(event == "Z"){
        setPatternImage(ZigZagPattern)
    }
    else if(event == "L"){
        setPatternImage(LinePattern)
    }
    else if(event == "C"){
    setPatternImage(BandPattern)
}
}

  function onHeadChange(event){
    console.log("value of pattern > ",event)
    setFilters({actual_pattern, actual_eye , actual_head:event})
    if(event == 0){
        console.log("we are on the field")
        setHeadImage(Qmark)
    }
    else if(event == 1){
        setHeadImage(Oval)
    }
    else{setHeadImage(Triangle)}
  }

  function onEyeChange(event){
    console.log("value of pattern > ",event)
    setFilters({actual_pattern, actual_eye:event , actual_head})
  }

const [thumbsSwiper, setThumbsSwiper] = useState(null);
const [controlledSwiper, setControlledSwiper] = useState(null);
    
    const slides = [];

      slides.push(
        <SwiperSlide key={`slide-1`} tag="li">
          <div className="filter__header">
              <h3>Pattern </h3><button onClick={() => {ClearAll()}}>Clear All</button>
          </div>
          <div className="patternPicker">
              <div className="img-container"><img src={patternImage} width="60px" height="60px" /></div>
              { pattern.map((Pchoice , index) => {
                    return(<div className="radioGroup">
                    <input
                        type="radio"
                        value={pattern_list[index]}
                        name="mpattern"
                        id={`${index + 1}-mobileid`}
                        onChange={(event)=>{onPatternChange(event.target.value);setSelected(index)}}
                        checked = {selected === index}
                    />
                    <label htmlFor={`${index + 1}-mobileid`}>
                        {Pchoice}
                    </label>
                    </div>);
                 })} 
              </div>
        </SwiperSlide>
      );

      slides.push(
        <SwiperSlide key={`slide-${2}`} tag="li">
          <div className="filter__header">
              <h3>Eye Pupil </h3><button onClick={() => {ClearAll()}}>Clear All</button>
          </div>
          <div className="eyePicker">

                <input type="radio" id="Mrounded" name="meye"
                value={1}
                onChange={(event)=>{onEyeChange(event.target.value);setEyeSelected(0);}}
                checked = {eyeSelected === 0}
                />
                <label for="Mrounded">
                <div className="roundEye">
                        <div className="pupil">
                        </div>
                </div>
                Rounded
                </label>
                    
                    
                <input type="radio" id="Mvertical" name="meye"
                  value={2}
                  onChange={(event)=>{onEyeChange(event.target.value);setEyeSelected(1);}}
                  checked = {eyeSelected === 1}
                />
                <label for="Mvertical" >
                    <div className="verticalEye">
                        <div className="pupil">
                            
                        </div>
                    </div>
                    Vertical
                </label>
                  
              </div>
        </SwiperSlide>
      );

      slides.push(
        <SwiperSlide key={`slide-${3}`} tag="li">
          <div className="filter__header">
              <h3>Head Shape </h3><button onClick={() => {ClearAll()}}>Clear All</button>
          </div>
          <div className="headPicker">
                 <div className="img-container"><img src={headImage} width="60px" height="60px" /></div>
                 { head.map((element , index) => {
                    return(<div className="radioGroup">
                    <input
                        type="radio"
                        value={index + 1}
                        name="mhead"
                        id={`mhead-${index + 1}-id`}
                        onChange={(event)=>{onHeadChange(event.target.value);setHeadSelected(index);}}
                        checked={headSelected === index}  
                    />
                    <label htmlFor={`mhead-${index + 1}-id`}>
                        {element}
                    </label>
                    </div>);
                 })} 
            </div>
        </SwiperSlide>
      );
    

    const thumbs = [];
    for (let i = 0; i < 5; i += 1) {
      thumbs.push(
        <SwiperSlide key={`thumb-${i}`} tag="li" style={{ listStyle: 'none' }}>
          <img
            src={`https://picsum.photos/id/${i}/163/100`}
            alt={`Thumbnail ${i}`}
          ></img>
        </SwiperSlide>
      );
    }

    return (
        <>
        <div className="mobileFilter" id="MFilter">

        <Swiper
        id="main"
        thumbs={{ swiper: thumbsSwiper }}
        controller={{ control: controlledSwiper }}
        tag="section"
        wrapperTag="ul"
        navigation
        pagination
        spaceBetween={0}
        slidesPerView={1}
        onInit={(swiper) => console.log('Swiper initialized!', swiper)}
        onSlideChange={(swiper) => {
          console.log('Slide index changed to: ', swiper.activeIndex);
        }}
        onReachEnd={() => console.log('Swiper end reached')}
      >
        {slides}
      </Swiper>

        </div>
        </>
    );
  };
  
  export default MobileFilter;