import React, { useState } from "react";
import { ReactComponent as CloseModalIcon } from "../../../../assets/images/icon/delete.svg";
import {register} from "./../../../../actions/auth"
import { Redirect } from "react-router-dom";
import CSRFToken from "../CSRFToken";
import { csrfToken, AuthState } from "../../../../state/AuthState";
import { useRecoilState } from "recoil"
import { toast } from "react-toastify";
import DjangoCSRFToken from 'django-react-csrftoken'
import { useEffect } from "react";

function SignUpEvent(e){
  var element = document.getElementById("signIn__modal");
  var element2 = document.getElementById("signUp__modal");
  console.log("comonly used");
  element.classList.toggle("active");
  element2.classList.toggle("active");
}


function closeModal(){
  var element = document.getElementById("signIn__modal");
  var element2 = document.getElementById("signUp__modal");
  element.classList.remove("active")
  element2.classList.remove("active")
}


function SignUp(){

  const [userName, setUserName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [re_password, setRe_password] = useState("")
  const [profession, setVocation] = useState(1);
  const [passValidity, setPassValidity] = useState(true)
  const [{state, name , authMessage}, setAuthMessage] = useRecoilState(AuthState)

  const handleUserNameOnChange = (event) =>
  {
    setUserName(event.target.value);
    console.log("username > "+event.target.value)
  }

  const handleEmailOnChange = (event) =>{
    setEmail(event.target.value);
    console.log("username > "+event.target.value)
  }

  const handleVocationOnChange = (event) =>{
    setVocation(event.target.value);
    console.log("vocation > "+event.target.value)
  }

  const handlePasswordOnChange = (event) =>
  {
    setPassword(event.target.value);
    console.log("username > "+event.target.value)
  }

  const handleRePasswordOnChange = (event) =>
  {
    setRe_password(event.target.value);
    console.log("username > "+event.target.value)
  }

  const onSubmit = (e) => {
    e.preventDefault();
    if(password === re_password){

      const v = register(userName , email , profession , password , re_password);
      console.log("submitting ",v);
      SignUpEvent();

    }

    else{
      console.log("passwords are not matching");
    }

  }

  useEffect(() =>{
    if(authMessage!==""){
    toast.warn(authMessage);
    console.log("auth message",authMessage);}
  }, [authMessage])

    return(<>

         <section className="aside__login__modal" id="signUp__modal">
             <CloseModalIcon className="close__icon" onClick={closeModal}/>
              <div className="modal__content">
                  <h2>Sign Up</h2>
                <form onSubmit={e => onSubmit(e)}>
                <CSRFToken />
                <div className="login__form__element">
                    <label htmlFor="" className="is__required">
                    Username
                  </label>
                  <input type="text" placeholder="ex: Dr.Nadi" onChange={(event) => handleUserNameOnChange(event)}  required/>
              </div>

              <div className="login__form__element">
                    <label htmlFor="" className="is__required">
                    Email
                  </label>
                  <input type="text" onChange={(event) => handleEmailOnChange(event)} required/>
              </div>

              <div className="login__form__element">
                    <label className="is__required" htmlFor="">What is your vocation ?</label>
                    <select onChange={(event) => handleVocationOnChange(event)} >
                      <option value={1}>Doctor</option>
                      <option value={2}>Researcher</option>
                      <option value={3}>Student</option>
                      
                   </select>
              </div>

              <div className="login__form__element">
                    <label htmlFor="" className="is__required">
                    Password
                  </label>
                  <input type="password" onChange={(event) => handlePasswordOnChange(event)} required/>
              </div>

              <div className="login__form__element">
                    <label htmlFor="" className="is__required">
                    Confirm Password
                  </label>
                  <input type="password" onChange={(event) => handleRePasswordOnChange(event)} required/>
              </div>
              <div className="button__container"><button type="submit"className="submit"> Go </button></div>
              <div className="sign_in">Already have an account ? <span onClick={SignUpEvent}>Sign In</span></div>
              </form>
              </div>
             
    </section>
        
    </>);
}
export default SignUp;