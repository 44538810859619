import { useState, useEffect } from "react";
import ViewHeightFull from "./../../../common/viewHeightFull";
import { ReactComponent as NonVenemous } from "./../../../../assets/images/icon/non-venemous.svg";
import { ReactComponent as Venemous } from "./../../../../assets/images/icon/venemous.svg";
import { ReactComponent as SemiVenemous } from "./../../../../assets/images/icon/semi-venemous.svg";
import { ReactComponent as CloseModalIcon } from "./../../../../assets/images/icon/delete.svg";
import troll from "./../../../../assets/images/picture/troll.jpg";
import Snake from "./../../../../assets/images/picture/snake_image.png";
import Upload_id from "../../../../context/content";
import context from "react-bootstrap/esm/AccordionContext";
import { contextType } from "google-map-react";
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import { render } from "@testing-library/react";
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { guideState, guideStep } from "../../../../state/GuideLineState"; 
import { firstPrediction } from "../../../../state/StateMangement";
import { useHistory } from "react-router-dom";



const IdentificationState = atom({
  key: "id",
  default: null,
});

const popupState = atom({
  key: "modal",
  default: false,
});

function IdentificationStep(props) {

  //const forceUpdate = useForceUpdate();
 
  const [randomNotation , setRandomNotation]= useState('');
  const [predictions, setPrediction] = useState([]);
  const [loadingState, setLoadingState] = useState(true);
  const [snake, setSnake] = useState([])
  const [activeSnake , SetActiveSnake] = useState(0);
  const [getValue , SetValue] = useState(true)
  const [detectionModal, setDetectionModal]= useRecoilState(popupState);//useState(false)
  const [detectionState, setDetectionState]= useRecoilState(IdentificationState)
  const [currentStep, setCurrentStep] = useRecoilState(guideStep)
  const [firstActivation, setFirstActivation] = useRecoilState(firstPrediction)
  const guideValue = useRecoilValue(guideState);

  const history = useHistory();
  /*useEffect(() => {
    setTimeout(() => {
      setLoadingState(false);
    }, 3000);
  }, []);*/
  
  console.log("checked guideline"+guideValue);
 
  const changes = (v) => {props.getCurrentSnake(v)}

  const init = (v) => {props.getPredictedSnake(v)}

  const nextStep = (val) =>{
    SetActiveSnake(val);
    changes(activeSnake);
    props.changeStep(3);
  }


  console.log("version 1.0")
  console.log(props.id)

  useEffect(()=>{if(firstActivation){

    async function getPredictions() {
      await axios.get(process.env.REACT_APP_API_URL+'snake-image/prediction/'+props.id.image_id+'/').then( (response) =>{
      setPrediction(response.data);
        console.log("data response"+response.data)
        console.log("getting data from "+ process.env.REACT_APP_API_URL +"snake-image/prediction/"+props.id.image_id+'/')
        console.log("pulling predictions "+predictions)
      } );}
    
    console.log("first activation activated")
    if(predictions.length < 3 )
    {
    getPredictions();
    //getPredictions();
    console.log("predictions live"+predictions);
    }
    setDetectionState(true); 
    setLoadingState(false);
  }},[])


  useEffect(() => {
    async function getPredictions() {
      await axios.get(process.env.REACT_APP_API_URL+'snake-image/prediction/'+props.id.image_id+'/').then( (response) =>{
      setPrediction(response.data);
        console.log("data response"+response.data)
        console.log("getting data from "+ process.env.REACT_APP_API_URL +"snake-image/prediction/"+props.id.image_id+'/')
        console.log("pulling predictions "+predictions)
      } );}


    if(props.id != 0)
    {
        console.log("idiies are here",props.id)
        setDetectionState(props.id.detection_state)
      if(detectionState)
      {
        console.log("entering the zone")
        setLoadingState(false)
        console.log("okay")
      if(predictions.length < 3 )
      {
      getPredictions();
      //getPredictions();
      console.log("predictions live"+predictions);
      }

      else
      {
        //getPredictions();
        console.log("Prediction process ending "+props.id)
        console.log("predictions >>> "+predictions[0])
        init(predictions[0].class_name.index)
        setLoadingState(false); 
      }

     
    }

    else
    {
      setDetectionModal(true)
      if(!firstActivation){
        setFirstActivation("true");
      }

    }


     
    
    }

    
    


    
  }, [predictions , props.id , detectionState] )


  useEffect (()=> {
    async function getProcessText(){
      let response = await axios.get(process.env.REACT_APP_API_URL+'snake-image/process-text/')
      response = await response;
      setRandomNotation(response.data.text)
      console.log("lezgoow"+response.data)

    }
    getProcessText();
  }, [])

  
  


  return (
    <div className="identification__step">
      <h2>AI. Snake thinks as follows</h2>
      <div className="guide">
        <div className="items">
          <div className="item">
            <span>Non venemous</span>
          </div>
          <div className="item">
            <span>venemous</span>
          </div>
          <div className="item">
            <span>semi venemous</span>
          </div>
        </div>
      </div>

      {loadingState && (
        <>
          <ViewHeightFull>
            <div className="loading">
              <div className="loading__parent">
                <div className="loading__child"></div>
              </div>
            </div>
            <div className="notice">
              <p style={{fontSize:20}}>Meanwhile you are waiting did you know: </p> 
              <p style={{color: "purple" , fontWeight:"bold" , fontSize:20}}>{randomNotation}</p> 
          <Modal
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={detectionModal}
            onHide={() => setDetectionModal(false)}
            backdrop={ 'static' }
            keyboard={ false }

          >
            <div className="home__modal">
              <div className="modal__header">

              <h6>Is there any Snake in the uploaded image?</h6>
                
                <div
                  className="close__modal"
                  onClick={() => {props.changeStep(1)}}
                >
                  <CloseModalIcon />

                  </div>
              </div>
              <hr/>
              <div style={{display:"flex",justifyContent:"center", margin:"30px"}}><img src={troll} style={{textAlign:"center" , width:"60%"}}/>
              </div>
              <h6  style={{margin:"20px 30px 50px 30px"}}>AI Snake <span style={{color:"red"}}>couldn't detect any snake</span> in the uploaded image, do you still want to continue?  </h6>
              <hr/>
              <div className="modal__footer">
            <button
              type="submit"
              className="submit"
              onClick={()=>{setDetectionState(true);setDetectionModal(false)} }>
              Continue
            </button>
            <button
              className="return"
              onClick={() => {props.changeStep(1)} }
            >
              Reupload
            </button>
          </div>
        
            </div>
          </Modal>
            </div>
          </ViewHeightFull>
        </>
        
      )}
      {!loadingState && (
        
        
        
        <div className="suggestions">

        


          <div className="items">


{predictions.map((prediction , index) => {

  var galery = prediction.class_name.images;
  var venom = prediction.class_name.venome_level;

  if(prediction.class_name.venome_level=="V")
  {
    //console.log("its venomous");
    var venomBorder="item border-dark-orange"
    var venom = "NonVenemous";
  }

  else if(prediction.class_name.venome_level=="S"){
    var venomBorder="item border-orange"
  }

  else
  {
    var venomBorder="item border-green"
  }

return(



<div className={venomBorder}
              onClick={ () => { SetActiveSnake(prediction.class_name.index); changes(prediction.class_name.index) ; props.changeStep(3);}} > 
               
    <div
              className="item border-green"
              onClick={ () => { SetActiveSnake(prediction.class_name.index); changes(prediction.class_name.index) ; props.changeStep(3);}}
            >
    <div className="top">

                <div className="left">
                  <div className="type__and__name">
                    <div className="type__icon">
                    {(() => {
                        switch(prediction.class_name.venome_level) {
                        case "V":
                          return <Venemous />
                          break;
                        case "S":
                          return <SemiVenemous />
                          break;
                        default:
                          return <NonVenemous />
                        }
                      })()}
                      
                     
                    </div>
                    <div className="type__name">
                      <h4>{prediction.class_name.name}</h4>
                    </div>
                  </div>
                  <div className="tip">
                    <span>
                      {(prediction.class_name.Common_name)}
                    </span>
                  </div>
                </div>
                <div className="right">
                  <div className="percentage__similarity">
                    <div className="box">
                      <svg className="purple_circle">
                        <circle cx="15" cy="15" r="15"></circle>
                        <circle
                          style={{
                            strokeDashoffset: "calc(95 - (95 * "+(prediction.predict_percent*100).toFixed(2)+") / 100)",
                          }}
                          cx="15"
                          cy="15"
                          r="15"
                        ></circle>
                      </svg>
                      <div className="number">
                        <span>%</span>
                        {(prediction.predict_percent*100).toFixed(1)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="content">
                <div className="snake__images">
                <div className="items">
                {
                  
                  Object.keys(galery).map(function(key) {
                    return (
                        
                    <div className="item">
                      <img src={galery[key].image.toLowerCase()/*process.env.REACT_APP_API_IMG+galery[key].image*/} alt="" />
                    </div>
                    
                      );
                  })

                 
                  
                }
             </div>
 
            </div>
            
          </div>

        </div>
         
 
 )

})
         

}

    <button onClick={()=> {setCurrentStep(6);history.push("/Guideline")}} className="class__not__found"><p>I can't find the relevant snake in the list {guideValue}</p></button>
    </div>
  </div>
      )}
</div>
  );
}

export default IdentificationStep;
