import ContentBox from "./../contentBox";
import Forward from "../../../../assets/images/icon/next.png";
import Backward from "../../../../assets/images/icon/previous.png"
import { useState ,useEffect } from "react";
import { useRecoilState } from "recoil";
import { painData } from "../../../../state/TreatmentState";
import { pain } from "../../../../state/TreatmentState";
import {atom} from 'recoil';


function activeSymptoms(val){

    if(val === "Yes"){
    const element = document.getElementById("sub_choice");
    element.classList.add("active");
    console.log(val);
    }

    if(val === "No"){
        const element = document.getElementById("sub_choice");
        element.classList.remove("active");
        console.log(val);
        }
}



function PainStep(props){

    const [choices, setChoices] = useState(["Yes","No"]);
    const [secondaryChoices, setSecondaryChoices] = useState(["Usual/mild pain like when you puncture your thumb with a needle",
           "Severe pain like When you hit your thumb with a hammer" ]);
    const [{choice, subChoice , val}, setPainData] = useRecoilState(painData);
    const [isPain , setIsPain] = useRecoilState(pain);

    console.log("the pain value is "+val);

    //function that handle the assignation of globale states ( recoil atom )
    
    const handleAnswer = (value , isSub) => {
        console.log("hey we are here ",choice);
        if(choice === choices){
            console.log("comonly used");
        }
        if(isSub){
            setPainData({choice:value , subChoice , val});
            if(value === "No"){
                setPainData({choice:value , subChoice , val:0});
            }
            if(value === "Yes"){
                setPainData({choice:value , subChoice , val:secondaryChoices.indexOf(subChoice)+1});
                console.log("the pain value is v2 "+secondaryChoices.indexOf(subChoice));
            }
            console.log("choice updated ",value);
        }else{
            setPainData({choice , subChoice:value , val:secondaryChoices.indexOf(value)+1});

            console.log("Subchoice updated", secondaryChoices.indexOf(value));

        }
      };

      useEffect(() => {
          if(choice === "Yes" ){
              activeSymptoms("Yes");
          }
      },[])
    
    
//onChange={(event) => handleRadioChange(event , index)}
    return( 
    <>
     <ContentBox>
         <h2>Does the patient have pain ?</h2>
         <div className="symptom_checkbox">
             <form>
             <div className="form__container">

                 { choices.map((Pchoice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={Pchoice}
                        name="area"
                        defaultChecked={ choice === Pchoice ? true : false }
                        id={`${index + 1}-${index * 10}-ans`}
                        onChange={(event)=>{activeSymptoms(Pchoice); handleAnswer(event.target.value, true)}}
                    />
                    <label htmlFor={`${index + 1}-${index * 10}-ans`}>
                        {Pchoice}
                    </label>
                    </div>);
                 })}      

                 <div className="confirmedSymptom" id="sub_choice">
            { secondaryChoices.map((Schoice , index) => {
                    return(<div className="element">
                    <input
                        type="radio"
                        value={Schoice}
                        name="sub-area"
                        id={`${index + 1}-${index * 20}-ans`}
                        defaultChecked={ subChoice === Schoice ? true : false }
                        onChange={(event) => {handleAnswer(event.target.value, false)}}
                    />
                    <label htmlFor={`${index + 2}-${index * 10}-ans`}>
                        {Schoice}
                    </label>
                    </div>);
                 })}        
            </div>

            </div>
            </form>
         </div>

             <img className="previous" src={Backward} onClick={() => {props.changeStep(1)}}/>
             <img className="next" src={Forward}  onClick={() => {props.nextStep(choice,subChoice,true,3)}}/>

    </ContentBox>
    </>
    )
}
export default PainStep;