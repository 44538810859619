import FooterBG from "../../../assets/images/picture/footer__bg.png";
import { Link } from "react-router-dom";

function Footer(props) {
  const { withGrassFooter, withFixedItem } = props;
  return (
    <>
      <footer className={`${withFixedItem ? "footer__fixed" : ""}`}>
        {withGrassFooter && <img src={FooterBG} alt="" />}
        <Link to="">Copyright © 2021 . All Rights Reserved</Link>
      </footer>
    </>
  );
}

export default Footer;
