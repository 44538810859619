import { atom, useRecoilState } from "recoil";

export const AuthState = atom({
    key:"AuthState",
    default: {loggedIn: false, username:"",authMessage: ""}
})

export const csrfToken = atom({
    key:'csrfToken',
    default: '',
}) 

export const Username = atom({
    key:'Username',
    default:'',
})

export const useAuthState = () => useRecoilState(AuthState)

export const registration = atom({
    key:"registration",
    default: "",
});