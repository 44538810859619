import ContentBox from "./../contentBox";
import Forward from "../../../../assets/images/icon/next.png";
import Backward from "../../../../assets/images/icon/previous.png";
import Vial from "../../../../assets/images/icon/vial.png";
import Clinic from "../../../../assets/images/icon/clinic.png";
import Symptom from "../../../../assets/images/icon/symp.png";
import { useEffect, useState } from "react";
import { locationData, painData , edemaData , bleedingData , testData , neuroData , necroData , pain } from "../../../../state/TreatmentState";
import { AuthState } from "../../../../state/AuthState";
import { useRecoilState , useRecoilValue } from "recoil";
import { resultContent } from "../../../../state/contentState";
import axios from "axios";
import Parser from 'html-react-parser';
import AuthModal from "../../account/AuthModal"
import SignIn from "../../account/SignIn";
import SignUp from "../../account/SignUp";
import { useRef } from "react";

function LoginComponent(props){

    return(
        <>
        <div className="auth_required">
            <h4>{props.text}</h4>
            <button 
                style={{background:`linear-gradient(${props.color} 0%, #02363e 100%)`}}
                onClick={() => props.childFunc.current()}
            > Log In </button>
        </div>
        </>
    )
}


function ResultStep(props){

    const [Dpain, setDpain] = useRecoilState(locationData);
    const [finalResult, setFinalResult] = useState(1);
    const content = useRecoilValue(resultContent);
    const [contentIndex, setContentIndex] = useState("page1");
    const [{loggedIn, name}, setAuth] = useRecoilState(AuthState);
    const childFunc = useRef(null);


    const comonlyUsed = [1,2,3,4];
    const [treatmentResult , setTreatmentResult] = useState({
        location: useRecoilValue(locationData), 
        pain: useRecoilValue(painData),
        edema: useRecoilValue(edemaData),
        bleeding: useRecoilValue(bleedingData),
        test: useRecoilValue(testData),
        neuro: useRecoilValue(neuroData),
        necro: useRecoilValue(necroData),
    }); 
    console.log("result Data ",treatmentResult.pain.val); 
    console.table(content);
    console.log("first content "+Object.keys(content)[1]);
    console.log("the content ",contentIndex);

    function assignResult( result ){
        if(result.neuro.val == 0 & result.test.val == 0 & result.bleeding.val == 0 && result.edema.val == 0 ){
            // 1 st bloc with pain value
            if (result.pain.val == 2 || result.pain.val == 1){
                setFinalResult(2);
            }else{
                setFinalResult(1);
            }
        } 
        else if(result.neuro.val != 0){
            setFinalResult(3);
        }

        else if(result.bleeding.val != 0){
            if(result.bleeding.val == 1){
                setFinalResult(4);
            }
            else{
                setFinalResult(5);
            }
        }

        else if(result.test.val != 0){
            setFinalResult(4);
        }

        else if(result.edema.val){
            if(result.edema.val){
                setFinalResult(7);
            }
            else{
                setFinalResult(6);
            }
        }
        else if( result.necro != 0){
            setFinalResult(8);
        }
        
    }

    const submitTreatmentHandler = async ()  => {

        let SubmitedData = new FormData();
        SubmitedData.append('location',treatmentResult.location)
        SubmitedData.append('pain',[treatmentResult.pain.choice, treatmentResult.pain.subChoice])
        SubmitedData.append('edema',[treatmentResult.edema.choice , treatmentResult.edema.subChoice])
        SubmitedData.append('bleeding',[treatmentResult.bleeding.choice,  treatmentResult.bleeding.subChoice])
        SubmitedData.append('test',[treatmentResult.bleeding.choice,  treatmentResult.bleeding.subChoice])
        SubmitedData.append('neuro', [treatmentResult.neuro.choice, treatmentResult.neuro.subChoice])
        SubmitedData.append('necro',treatmentResult.necro.data)
        SubmitedData.append('result',contentIndex)
    
        console.log("submited data is done");
    
        for (var key of SubmitedData.entries()) {
          console.log(key);
      }
      await axios({
        method: 'post',
        url:process.env.REACT_APP_API_URL+'snake-image/treatment-feedback/',
        data:(SubmitedData),
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      }).then((response) => {
        console.log(response.data); 
        //setUploadId(""); 
        //changes(response.data);    
        //props.changeStep(2);                 
        //history.pushState('/')
      })
    
      }

    

    useEffect(() => {
        console.log("effect is used");
        assignResult(treatmentResult);
        setContentIndex("page"+finalResult);
        submitTreatmentHandler();
        }, [finalResult])

        console.log("those symptomes relay to the result page number ",finalResult);

    //onChange={(event) => handleRadioChange(event , index)}
    return( 
    <>

     <SignIn  childFunc={childFunc} /> 
     <SignUp />

     {<div className="resultBox">
         <h4> Based on your reported symptoms, you are dealing with:</h4>
         {Parser(content[contentIndex].Case)}
        
        <div className="specific__treatment">
            <div className="sub-section">
                <img src={Vial} />
                <h3>Specific Treatment</h3>
            </div>
            <div className="content">
                {Parser(content[contentIndex].Specific)}
            </div>
        </div>
        
        <div className="clinical__treatment">
            <div className="sub-section">
                <img src={Clinic} />
                <h3>Clinical Monitoring</h3>
            </div>
            <div className="content">
            { Parser(content[contentIndex].Clinical) }
            </div>
        </div>
        
        <div className="symptomatic__treatment">
            <div className="sub-section">
                <img src={Symptom} />
                <h3>Symptomatic Treatment</h3>
            </div>
            <div className="content">
            { loggedIn ? Parser(content[contentIndex].Symptomatic) : <LoginComponent childFunc={childFunc} color="#5b8751" text="Dear user, this section updates regulary, to receive the most updated version please login" />}
            </div>
            
        </div>
        <div className="reference">
            <h6><span>Reference:</span></h6>
            <h6>Chippaux, J. P. (2015). Prise en charge des morsures de serpent en Afrique subsaharienne. Médecine et Santé Tropicales, 25(3), 245-248.</h6>
            <h6>World Health Organization.(2010). Guidelines for the prevention and clinical management of snakebite in Africa (No. WHO/AFR/EDM/EDP/10.01). World Health Organization. Regional Office for Africa.</h6>
            <h6>Brandehoff et al. Ketamine for pain control of snake envenomation in Guinea: A case series. Toxicon. 2020;187:82-85.</h6>
            <h6>Watt et al. Positive response to edrophonium in patients with neurotoxic envenoming by cobras (Naja naja philippinensis). A placebo-controlled study. N Engl J Med. 1986;315(23):1444-8.</h6>
        </div>
     </div> }
    <img className="previous" src={Backward} onClick={() => {props.changeStep(7)}}/>
    

    </>
    )
}
export default ResultStep;