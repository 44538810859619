import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {ReactComponent as FalseMark} from "../../../../assets/images/icon/icons8-cancel.svg"
import {ReactComponent as TrueMark} from "../../../../assets/images/icon/true-mark.svg"
import { useRecoilState, useRecoilValue } from "recoil";
import { guideState , guideStep, userLoc } from "../../../../state/GuideLineState";
import RiskEstimation from "../riskEstimation";
import {ReactComponent as Wave} from "../../../../assets/images/picture/header__BG.svg";
import Parser from 'html-react-parser';
import CustomGoogleMap from "./googleMap";

function QuestionBox(props){

    const[questionContent, setQuestionContent]=useState(props.questionValue);
    const guideValue = useRecoilValue(guideState);
    const [currentStep, setCurrentStep] = useRecoilState(guideStep);
    const [userLocation, setUserLocation] = useState({
        lat: -80,
        lng: 37,
      });
      const [stateLocation , setStateLocation] = useRecoilState(userLoc)
    const history = useHistory();

    console.log("guideState >>> "+guideValue);
    console.log("position >>> "+userLocation.lat);

    function footerBot(){
        document.getElementsByClassName("main__container")[0].style.height = "1600px";
    }

    

    return(
        <>
        {/*<Wave className="header__bg"/>*/}
         <section className="question__box">
             <div className="question__quote" ><h2>{Parser(questionContent)}</h2></div>
             

            { currentStep === 1 &&(
                <div className="question__response">
                <TrueMark height="220px" width="220px"  onClick={(e) => {
                    e.preventDefault();
                    history.push('/imageIdentification');
                    }}/>
                       <FalseMark height="130px" width="130px" onClick={(e) => {
                    e.preventDefault();
                    history.push('/riskEstimation');
                    }}/>
                </div>
                
            )}

            { currentStep === 3 &&(
               <>
               <div className="map__wrapper">
                <div className="map__container">
                    <CustomGoogleMap 
                    userLocation={userLocation}
                    setUserLocation={setUserLocation}
                    />
                </div>
                <button
                type="submit"
                className="submit"
                onClick={() => {console.log("trigerred")
                setStateLocation(userLocation);
                setCurrentStep(4);
                }}
                >
                    Submit
                </button>
                </div>
               </>
                
            )
            }
                 
             
         </section>
         </> 
        );

}
export default QuestionBox;