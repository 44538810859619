import { useState , useEffect } from "react";
import Snake from "./../../../../assets/images/picture/snake_image.png";
import { ReactComponent as FaceSymbol } from "./../../../../assets/images/icon/face-symbole.svg";
import { ReactComponent as TrueMark } from "./../../../../assets/images/icon/true-mark.svg";
import ViewHeightFull from "./../../../common/viewHeightFull";
import { withRouter } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import axios from "axios";
function FeedbackStep(props) {
  const [emailError , SetEmailError]= useState("");
  const [hasFeedback, setHasFeedback] = useState(false);
  const [ratingState, setRatingState] = useState(1);
  const [snakeClass, setSnakeClass] = useState([]);
  const [userEmail, setUserEmail] = useState("");
  const [comment, setComment] = useState("");
  const [vocation, setVocation]= useState(3);
  const [questions, setQuestions] = useState([
    {
      question:
        "آیا این محصول در درمان مار گزیدگی کمک کننده است ؟",
        answer: ["خیر" , "بله" , "درصورت تکمیل، بله"],
        send: "",
    },
    {
      question:
        " (Snakebite first aid and treatment) آیا تکمیل بخش  را براساس منابع پزشکی توصیه می کنید؟",
        answer: ["خیر" , "بله"],
        send: "",
    },

    {
      question:
        "آیا دقت این محصول قایل قبول است و خرید چنین محصولی را (پس از تکمیل) به بیمارستان یا دانشگاه محل کار خود توصیه می کنید؟ ",
      answer: ["خیر" , "بله"],
      send: "",
    }, 

    
  ]);

  

  const useable_informations  = ["شناسایی عکس مارها" , "دریافت فهرست مارهای سمی در هر مکان  " , "گالری عکس مار ها " , "نقشه پراکنش", "صفحه توضیحات مارها  "  ]
  const [checkedState , setCheckedState] = useState(new Array(
    useable_informations.length).fill(false));


  const useable_features  = ["Snakebite First Aid" , "Snakebite Estimation Risk" , "Local Snake Gallery" , "Search Engines for Snakes"  ]
  const [checkedFeature , setCheckedFeature] = useState(new Array(
    useable_features.length).fill(false));

  const [answers , setAnswers] = useState(new Array(
    questions.length).fill("empty"))


    ValidityState = () =>
    {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!userEmail.includes('@'))
      {
        SetEmailError("Not A Valid Email !");
        console.log("hey hey not valid")
        return false;
      }

      else
      {
        console.log("vaaalid")
        return true;
      }
     
      if(userEmail)
      {
        console.log("hey hey not valid 222")
        return false;
      }
      console.log("vaaalid")
      return true;
    }

    /*Function to update the values of answers in different useStates*/ 

    // Checkbox Handlers
    const handleOnChange = (position) => {
      const updatedChecked = checkedState.map((item , index) =>
        index === position ? !item : item );
  
        setCheckedState(updatedChecked);
        console.log("trigger "+updatedChecked)

    }

    const handleOnChangev2 = (position) => {
      const updatedFeature = checkedFeature.map((item , index) =>
        index === position ? !item : item );
  
        setCheckedFeature(updatedFeature);
        console.log("trigger 2 "+updatedFeature)
    }

    //RadioButtons Handler
    const handleRadioChange = (event , position) =>
    {
      console.log(position)
      console.log(questions[position])
      console.log(event.target.value)
      
      console.log(answers)
      const updatedAnswer = answers.map((item , index) =>
        index === position ? event.target.value : item 
        );
  
        setAnswers(updatedAnswer);
        console.log("trigger 2 "+updatedAnswer);
        console.log("trigger 2 "+answers);
    }

    //Comment , vocation and email Handlers
    const handleCommOnChange = (event) =>
    {
      setComment(event.target.value);
      console.log("comment >"+event.target.value)
    }

    const handleEmailOnChange = (event) =>
    {
      setUserEmail(event.target.value);
      console.log("email >"+event.target.value)
    }

    const handleVocationOnChange = (event) => {
      setVocation(event.target.value);
      console.log("vocation >"+event.target.value)
    }

  /*Function to submit the feedback to the API*/ 
  const submitFeedbackHandler = async ()  => {

    let SubmitedData = new FormData();
    SubmitedData.append('uploadId',props.id.image_id)
    SubmitedData.append('rating',ratingState)
    SubmitedData.append('email',userEmail)
    SubmitedData.append('basicAnswers',answers)
    SubmitedData.append('infoAnswers',checkedState)
    SubmitedData.append('featureAnswers',checkedFeature)
    SubmitedData.append('vocation',vocation)
    SubmitedData.append('comment',comment)
    SubmitedData.append('heyhey',"heyhey")

    console.log((SubmitedData.heyhey));

    for (var key of SubmitedData.entries()) {
      console.log(key);
  }
  await axios({
    method: 'post',
    url:process.env.REACT_APP_API_URL+'snake-image/user-feedback/',
    data:(SubmitedData),
    headers: {
      'Content-Type': 'multipart/form-data'
    },
  }).then((response) => {
    console.log(response.data); 
    //setUploadId(""); 
    //changes(response.data);    
    //props.changeStep(2);                 
    //history.pushState('/')
  })

  }

  const validationSubmitHandler = async (event) => {

    const validation = ValidityState()
    event.preventDefault();
    if(validation)
    {
      console.log("valid")
      submitFeedbackHandler();
      submitFormHandler();
    }
    else
    {
      console.log("not valid")
      
    }

  }
  

  const submitFormHandler = (event) => {
    //event.preventDefault();
    setHasFeedback(true);
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  };

  const ratingChanged = (newRating) => {
    console.log(newRating);
    setRatingState(newRating);
  };

  /*Function to get data from the API*/ 

  useEffect(() => {
    async function getSnakeClass() {
      let response = await axios.get(process.env.REACT_APP_API_URL+'snake-image/class/'+(props.snake_id)+'/');
      response = await response;
      console.log(response.data);
      setSnakeClass(response.data);
    }
    if(snakeClass.length == 0){
      getSnakeClass();
    }
    else{
      
    }
    
  }, [snakeClass])
  console.log(props.image)

//"https://backend-snaky.oa.r.appspot.com"+snakeClass.properties.images[0].image

if(snakeClass.length != 0){
  return (
    <div className="feedback__step">
      {!hasFeedback && (
        <div className="get__feedback">
          <div className="snake__info">
            <div className="image">
              <img src={props.image} alt="" />
            </div>
            <p>
              Your selected result : <span style={{color:"green" , fontSize:"25px"}}>{snakeClass.properties.name}</span>
            </p>
          </div>
          {
            
        <div className="feedback__rating">
          <p>Did you get the result you wanted ?</p>
          <div className="feedback__item__box">  
          <ul class="feedback">
           <ReactStars
           count={5}
           onChange={ratingChanged}
           size={55}
           color="#20c997"
           activeColor="#ffd700"
           isHalf={true}

         /> 
         </ul>
         </div>
       </div>

         
          
      }
          <div className="form__wrapper">
            <form>
              <div className="items">
                {/* <div className="item">
                  <label htmlFor="">Your name</label>
                  <input type="text" placeholder="ex: john doe" />
                </div> */}
                <div className="item">
                  <label htmlFor="" className="is__required">
                    Email
                  </label>
                  <input type="text" placeholder="ex: snaky@gmail.com" onChange={(event) => handleEmailOnChange(event)} required/>
                </div>
                {/* <div className="item">
                  <label htmlFor="">Comment</label>
                  <input type="text" placeholder="ex : this is my comment" />
                </div> */}
              </div>
              {<div className="questions__box">
                <div className="items">
                  {questions &&
                    questions.map((item, index) => {
                      return (
                        <div className="item" key={index}>
                          <label htmlFor="">{item.question}</label>
                          <div className="checks__items">
                          {item.answer.map((answer, udex) => {return (
                            <div className="item">
                            <label htmlFor={`${index + 1}-${index * 10}-ans`}>
                              {answer}
                            </label>
                            <input
                              type="radio"
                              value={answer}
                              name={`${index}-ans-${index * 5}`}
                              id={`${index + 1}-${index * 10}-ans`}
                              onChange={(event) => handleRadioChange(event , index)}
                            />
                          </div>
                          )})}
                          </div>
                        </div>
                      );
                    })}



                </div>
              </div>
                  }

{
             <div className="checked-items">

              <div className="checked-item">
                <label htmlFor="">کدام بخش از وب اپلیکیشن برای شما مفید تر است ؟ </label>
                <ul className="check-list">
                {
                      useable_informations.map((info ,  index)=>{
                  return(<li key={index} className="item">
                  <label htmlFor={`custom-checkbox-${index}`}>{info}</label>
                  <input
                    type="checkbox"
                    id={`custom-checkbox-${index}`}
                    name={info}
                    value={info}
                    checked={checkedState[index]}
                    onChange={() => handleOnChange(index)}
                  />
                  </li>        
                  )
                })}
                </ul>
              </div>
                {/* <div className="checked-item">
                <label htmlFor="">Which Feature would be useful in future ?</label>
                <ul className="check-list">
                {
                      useable_features.map((info ,  index)=>{
                  return(<li key={index} className="item">
                    <input
                    type="checkbox"
                    id={`custom-checkbox2-${index*2}`}
                    name={info}
                    value={info}
                    checked={checkedFeature[index]}
                    onChange={() => handleOnChangev2(index)}
                  />
                  <label htmlFor={`custom-checkbox2-${index*2}`}>{info}</label>
                  </li>        
                  )
                })}
                </ul>
              </div>*/}
              
                
              </div>
      }
              {
              <div className="items">
                  { /*  <div className="item">
                    <label htmlFor="">What is your vocation ? (if other specify in comment)</label>
                    <select onChange={(event) => handleVocationOnChange(event)}>
                      <option value={1}>Student</option>
                      <option value={2}>Researcher</option>
                      <option selected value={3}>Visitor</option>
                      
                  </select>
                    </div>
             */}
                  
                <div className="item">
                  <label htmlFor="">لطفا نظرات خود جهت تکمیل این محصول را ارائه بفرمایید. همچنین در صورت تمایل شماره واتساپ خود را جهت نظر سنجی های بعدی و دریافت آپدیت ها برای ما بنویسید</label>
                  <textarea type="text" placeholder="ex : this is my comment" onChange={(event) => handleCommOnChange(event)}/>
                </div>
              </div>
              }
              <div><h2 style={{color:"red"}} >{emailError}</h2></div>
              <div className="button__wrapper">
                <button
                  type="submit"
                  onClick={(event) => {validationSubmitHandler(event);}}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {hasFeedback && (
        <ViewHeightFull>
          <div className="thank__you__feedback">
            <TrueMark />
            <div className="actions">
              <h4>Thank you for helping us get better</h4>
              <div className="two">
                <button
                  className="green__button"
                  onClick={() => props.history.push("/")}
                >
                  Home
                </button>
                <button
                  className="blue__button"
                  onClick={() => props.changeStep(1)}/*submitFeedbackHandler() props.changeStep(1)*/ 
                >
                  Upload another image
                </button>
              </div>
            </div>
          </div>
        </ViewHeightFull>
      )}
    </div>
  );}

    else
    {
      return(<h2>Still loading Data</h2>)
    }

}

export default withRouter(FeedbackStep);
