import { useState, useRef , useEffect } from "react";
import { ReactComponent as SnakePlusIcon } from "./../../../../assets/images/icon/snake-plus.svg";
import {Link} from "react-router-dom";
import SnakeOne from "./../../../../assets/images/picture/snake_one.png";
import SnakeTwo from "./../../../../assets/images/picture/snake_two.png";
import SnakeThree from "./../../../../assets/images/picture/snake_three.png";
import Snake from "./../../../../assets/images/picture/snake_image.png";
import Modal from "react-bootstrap/Modal";
import { ReactComponent as CloseModalIcon } from "./../../../../assets/images/icon/delete.svg";
import { ReactComponent as ArrowDown } from "./../../../../assets/images/icon/arrow-down.svg";
import ViewHeightFull from "../../../common/viewHeightFull";
import { toast } from "react-toastify";
import Collapse from "react-bootstrap/Collapse";
import { useHistory } from "react-router";
import {parse, stringify, toJSON, fromJSON} from 'flatted';
import Upload_id from "../../../../context/content";
import axios from "axios";
import { ReactComponent as CloseStepIcon } from "./../../../../assets/images/icon/delete-red.svg";
import { guideStep } from "../../../../state/GuideLineState";
import { useRecoilState } from "recoil";
import Snake1Icon from "../../../../assets/images/icon/snake1.svg";
import Snake3Icon from "../../../../assets/images/icon/snake3.svg";
import {ReactComponent as Hospital} from "../../../../assets/images/icon/hospital.svg";
import SnakeBiteIcon from "../../../../assets/images/icon/snakeBite.png";
import SnakePlus from "../../../../assets/images/icon/plus.png";


function SnakeBite(props){

    const [randomNotation , setRandomNotation]= useState('');
    const [uploadState , setUploadState]=useState("before__upload");
    const [currentStep, setCurrentStep]=useRecoilState(guideStep);
    const [submitState, setSubmitState]=useState(false)
    const history = useHistory()
    const graph = useRef(null);
    const [image, setImage] = useState(null)
    const [snakeFace, setSnakeFace] = useState(null);
    const SnakeSelectedImage = useRef(null);
    const FileInput = useRef(null);

    useEffect (()=> {
      async function getProcessText(){
        let response = await axios.get(process.env.REACT_APP_API_URL+'snake-image/process-text/')
        response = await response;
        setRandomNotation(response.data.text)
        console.log("lezgoow"+response.data)
      }
      getProcessText();
    }, []);


  useEffect(() => {
    
    if(!submitState){
      const ciclegraph = graph.current;
    const circleElements = ciclegraph.childNodes;

    let angle = 210;
    let dangle = 360 / circleElements.length;

    for (let i = 0; i < circleElements.length; i++) {
      let circle = circleElements[i];
      angle += dangle;
      circle.style.transform = `rotate(${angle}deg) translate(${
        ciclegraph.clientWidth / 1.8
      }px) rotate(-${angle}deg)`;
    }
    }
    else{
      setTimeout(() => {
        window.location.href='/riskEstimation';
      }, 6000);
    }
  }, [submitState]);

  const dataUploadHandler = async () => {
    
    let formField = new FormData()
    formField.append('image',image)
    /*for (var value of formField.values()) {
      console.log(value);
    }*/
    await axios({
      method: 'post',
      url:process.env.REACT_APP_API_URL+'snake-image/upload-snakebite/',
      data:(formField),
  
    }).then((response) => {
      console.log("je suis la "+response.data);             
      setCurrentStep(7);
    })
    //useEffect(() => { setUploadId(id) }, [])
  };

  const uploadHandler = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      var selectedFile = fileInput.files[0];
      if (selectedFile.size < 20200000) {
        setImage(event.target.files[0]);
        var reader = new FileReader();
        reader.onload = function (event) {
          SnakeSelectedImage.current.src = event.currentTarget.result;
          setSnakeFace(SnakeSelectedImage.current.src);
        };
        reader.readAsDataURL(selectedFile);
        console.log("lezgow what u gonna do"+event.target.files[0]);
        setUploadState("after__upload");
        //setUploadDetailsModal(true);
        
        
      } else {
        toast.warn("The selected photo is larger than the specified size (20 MB)");
        fileInput.value = null;
      }
    }
  }; 
    
return (
<>
{ !submitState && (
  <Upload_id.Provider>

    <div className="upload__biteimage__step">
    <div className="image__identification__uploader">
    <CloseStepIcon className="close__icon" onClick={() => {setCurrentStep(7);}} />
      <div className="head__text">
          <h2>Do you think if you can provide us with an image of the snakebite site ?</h2>
           <div className="App">
            
            <div className="ciclegraph" ref={graph}>
            {uploadState === "before__upload" &&(
              <><div className="circle c1" />
              <div className="circle c2" />
              <div className="circle c3" /></>
            )
                }
            </div>

            <input
              type="file"
              name=""
              id=""
              ref={SnakeSelectedImage}
              accept="image/png, image/jpg, image/jpeg"
              onChange={(event) => uploadHandler(event)} //, (e)=>setImage(e.target.files[0])
            />

        <div class={uploadState}
        onClick={() => {
        console.log("lezgooow"); 
        setCurrentStep(7);}}>
          {uploadState === "after__upload" && (<><img className="uploaded__image" src="" alt="" ref={SnakeSelectedImage} /></>)}
            <div class="dragNdrop" onClick={() => {console.log("lezgooow")}}>
            <p>Drag and Drop or Select an Image</p>
            <img src={SnakePlus} width="100px" height="100px" />
            </div>
        </div>

        </div>
      </div>
    </div>
    {uploadState === "after__upload" && (<div className="button__container" onClick={() => {console.log("click trigerred");dataUploadHandler();}}><button type="submit" className="submit"> Submit </button></div>)}
    </div>

</Upload_id.Provider>
  )}

{ submitState && (
  <div className="Smooth__screen">
    <h2>Thanks for sharing your data with us</h2>  
    <div className="map__guide">
      <h3>We are redirecting you now to the Risk Map where you can get more details about snakes and their location</h3>
      <ul>
      <li><img src={SnakeBiteIcon} height={65} width={75} /> you can see different snake locations and get details by clicking on it . </li>
      <li><img src={Snake3Icon} /> you can click on the map layer to see the snakes living in a specific locations</li>
      <li><Hospital /> you can click on hospitals to get more details</li>
    </ul>
    <div className="load__section">
    <div className="loading">
    <div className="loading__parent">
      <div className="loading__child"></div>
    </div>
  </div>
  <div className="notice">
    <p style={{fontSize:20}}>Meanwhile your waiting did you know </p> 
    <p style={{color: "purple" , fontWeight:"bold" , fontSize:20}}>{randomNotation}</p> 
  </div>
  </div>
    </div>

    
    <button onClick={() => {setCurrentStep(7)}}>Turn off</button>
  </div>
)}
</>

  ); 
}

export default SnakeBite;