
import { useEffect, useState } from "react";
import BandPattern from "../../../../assets/images/picture/pattern2.jpg";
import LinePattern from "../../../../assets/images/picture/pattern3.jpg";
import ZigZagPattern from "../../../../assets/images/picture/pattern4.jpg";
import UniPattern from "../../../../assets/images/picture/pattern1.jpg";
import Qmark from "../../../../assets/images/icon/questionMark.png";
import Oval from "../../../../assets/images/picture/head1.jpg";
import Triangle from "../../../../assets/images/picture/head2.jpg";
import { morphological_feature , pattern_feature } from "../../../../state/filterState";
import { useRecoilState } from "recoil";

const pattern_list = ["U", "Z", "C", "L"]


function Filter(props){

    const [colors, setColors] = useState(["Redish","Blakish"]);
    const [colorData , setColorData] = useState(["red","black"]);

    const [pattern, setPattern] = useState(["Unicolor, reticulated", "Zigzag", "Cross bars", "Longitudinal line" ])
    const [patternImage, setPatternImage] = useState(Qmark);

    const [head, setHead] = useState(["Triangular" , "Roundish"]);
    const [headImage, setHeadImage] = useState(Qmark);

    const [{actual_pattern , actual_eye , actual_head} ,setFilters ] = useRecoilState(morphological_feature)
    const [ actualPattern ,setActualPattern ] = useRecoilState(pattern_feature)

    const [isChecked , setIsChecked] = useState (null);

    const [selected, setSelected] = useState(null);
    const [eyeSelected , setEyeSelected] = useState(null);
    const [headSelected, setHeadSelected] = useState(null);


    function ClearAll(){
        setFilters({actual_pattern:'', actual_eye:0, actual_head:0});
        setHeadSelected(null);
        setSelected(null);
        setEyeSelected(null);
        setHeadImage(Qmark);
        setPatternImage(Qmark);
        var filtered = document.getElementsByClassName("snake-element");

        var delayInMilliseconds = 350;

        setTimeout(function() {
        for(var i=0 ; i < filtered.length ; i++){
        filtered[i].classList.remove("filtered");
        }
        }, delayInMilliseconds);
        console.log("removed all filters");
    }

    function ResetUnique(filter){
        if(filter === 1){
            setSelected(null);
            setFilters({actual_pattern:'', actual_eye, actual_head});
            var pattern = window.document.querySelectorAll("li[class*=\"width-48 height-48 profile_view_img\"]")[0]
        }
        if(filter === 2){
            setEyeSelected(null);
            setFilters({actual_pattern, actual_eye:0, actual_head});
        }
        if(filter === 3){
            setHeadSelected(null);
            setFilters({actual_pattern, actual_eye, actual_head:0});
        }
    }
    
    function onPatternChange(event){
        setFilters({actual_pattern:event, actual_eye , actual_head})
        setActualPattern(event)
        console.log("value of pattern > ",actual_pattern)
        if(event == "U"){
            console.log("we are on the field")
            setPatternImage(UniPattern)
            //isChecked(null)
        }
        else if(event == "Z"){
            setPatternImage(ZigZagPattern)
        }
        else if(event == "L"){
            setPatternImage(LinePattern)
        }
        else if(event == "C"){
        setPatternImage(BandPattern)
    }
    }

    function onHeadChange(event){
        console.log("value of pattern > ",event)
        setFilters({actual_pattern, actual_eye , actual_head:event})
        if(event == 0){
            console.log("we are on the field")
            setHeadImage(Qmark)
        }
        else if(event == 1){
            setHeadImage(Oval)
        }
        else{setHeadImage(Triangle)}
    }

    function onEyeChange(event){
        console.log("value of pattern > ",event)
        setFilters({actual_pattern, actual_eye:event , actual_head})
    }

    return(
        <div className="filters__container" id="filter">
            {/*<div className="filter_box">
              <h3>Color</h3>
              <div className="colorPicker">

              {
                  colors.map((color, index) =>{
                      return(
                        <><input 
                            type="radio" 
                            name="color"
                            id={colorData[index]} 
                            value={colorData[index]} 
                          />
                        <label for={colorData[index]}><span class={colorData[index]}></span>{color}</label></>
                      )
                  })
              }

              <input type="radio" name="color" id="other" />
              <label for="other"><span class="other"></span>Other</label>

              </div>

            </div>*/}


            <div className="filter_box">
                <div className="filter__header">
                    <h3>Pattern </h3> {/*<button onClick={() => {ResetUnique(1)}}>Clear</button>*/}
                </div>
              <div className="patternPicker">
              <div className="img-container"><img src={patternImage} width="110px" height="110px" /></div>
              { pattern.map((Pchoice , index) => {
                    return(<div className="radioGroup">
                    <input
                        type="radio"
                        value={pattern_list[index]}
                        name="pattern"
                        id={`${index + 1}-id`}
                        onChange={(event)=>{onPatternChange(event.target.value);setSelected(index)}}
                        checked = {selected === index}
                    />
                    <label htmlFor={`${index + 1}-id`}>
                        {Pchoice}
                    </label>
                    </div>);
                 })} 
              </div>
            </div>

            <div className="filter_box">
            <div className="filter__header">
                    <h3>Eye Pupil </h3> {/*<button onClick={() => {ResetUnique(2)}}>Clear</button>*/}
                </div>
              <div className="eyePicker">

                <input type="radio" id="rounded" 
                value={1} name="eye" 
                onChange={(event)=>{onEyeChange(event.target.value);setEyeSelected(0);}}
                checked = {eyeSelected === 0}
                />
                <label for="rounded">
                <div className="roundEye">
                        <div className="pupil">
                        </div>
                </div>
                Rounded
                </label>
                    
                    
                <input type="radio" id="vertical"
                 value={2} name="eye"
                  onChange={(event)=>{onEyeChange(event.target.value);setEyeSelected(1);}}
                  checked = {eyeSelected === 1}
                  />
                <label for="vertical" >
                    <div className="verticalEye">
                        <div className="pupil">
                            
                        </div>
                    </div>
                    Vertical
                </label>
                  
              </div>
            </div>

            <div className="filter_box">
                <div className="filter__header">
                    <h3>Head Shape </h3> {/*<button onClick={() => {ResetUnique(3)}}>Clear</button>*/}
                </div>
                 <div className="headPicker">
                 <div className="img-container"><img src={headImage} height="110px" width="110px" /></div>
                 { head.map((element , index) => {
                    return(<div className="radioGroup">
                    <input
                        type="radio"
                        value={index + 1}
                        name="head"
                        id={`head-${index + 1}-id`}
                        onChange={(event)=>{onHeadChange(event.target.value);setHeadSelected(index);}}
                        checked={headSelected === index}                     
                    />
                    <label htmlFor={`head-${index + 1}-id`}>
                        {element}
                    </label>
                    </div>);
                 })} 
                 </div>
            </div>
         <div className="resetFilters">
         <button onClick={()=>{ClearAll();}}>Clear All</button>
        </div>   
        </div>
    )
}
export default Filter;