import { useEffect, useRef } from "react";
import { ReactComponent as Notepad } from "./../../../../assets/images/icon/notepad.svg";
import { ReactComponent as Result } from "./../../../../assets/images/icon/result.svg";
import { ReactComponent as Feedback } from "./../../../../assets/images/icon/feedback.svg";
import { ReactComponent as Image } from "./../../../../assets/images/icon/image.svg";
function Steps(props) {
  const { activeStep } = props;
  const StepsElementParent = useRef(null);
  useEffect(() => {
    switch (activeStep) {
      case 1:
        StepsElementParent.current.scrollTo(0, 0);
        break;
      case 2:
        StepsElementParent.current.scrollTo(250, 0);
        break;
      case 3:
        StepsElementParent.current.scrollTo(500, 0);
        break;
      case 4:
        StepsElementParent.current.scrollTo(750, 0);
        break;
      default:
        break;
    }
  }, [activeStep]);
  return (
    <div className="steps">
      <div className="items" ref={StepsElementParent}>
        <div className={`item ${activeStep > 0 ? "active" : ""}`}>
          <div className="icon">
            <Image />
          </div>
          <div className="text">
            <span>Upload image</span>
          </div>
        </div>
        <div className={`item ${activeStep > 1 ? "active" : ""}`}>
          <div className="icon">
            <Notepad />
          </div>
          <div className="text">
            <span>Identification</span>
          </div>
        </div>
        <div className={`item ${activeStep > 2 ? "active" : ""}`}>
          <div className="icon">
            <Result />
          </div>
          <div className="text">
            <span>Result</span>
          </div>
        </div>
        <div className={`item ${activeStep > 3 ? "active" : ""}`}>
          <div className="icon">
            <Feedback />
          </div>
          <div className="text">
            <span>Report</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Steps;
