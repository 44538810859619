import { useState , useEffect } from "react";
import Layout from "./../../components/common/layout"
import SnakeBiteImage from "./../../components/pages/guideline/snakebite"
import QuestionSlide from "./../../components/pages/guideline/questionBox"
import LocationStep from "../../components/pages/guideline/locationStep";
import ResultStep from "../../components/pages/guideline/resultStep";
import DataStep from "../../components/pages/guideline/dataStep";
import ViewHeightFull from "../../components/common/viewHeightFull";
import { guideState } from "../../state/GuideLineState";
import { guideStep } from "../../state/GuideLineState";
import {
    useRecoilValue,
    useRecoilState
  } from 'recoil';

function GuideLine (props){

    const [currentStep, setCurrentStep] = useRecoilState(guideStep);
    const [guidevalue , setGuideValue] = useRecoilState(guideState);
    console.log(guidevalue)
    const changeStepHandler = (stepState) => {
        setCurrentStep(stepState);
        document.body.scrollTop = 0; 
        document.documentElement.scrollTop = 0; 
      };

      useEffect(() => {setGuideValue(true)},[])
    
    return(
    <>
    <Layout fullWith={true}>
    
    <section className="guide__line">
            <div className="wrapper">
                <div className="content">
                { currentStep==1 && (
                    <QuestionSlide questionValue={"Do you need help identifying the <span>Snake</span> ?"}/>
                )}
                { currentStep==2 && (
                    <SnakeBiteImage />
                )}
                { currentStep==3 && (
                    <QuestionSlide questionValue={"Where did the snakebite occure ?"}  />
                )}
                { currentStep==4 &&(
                    <LocationStep />
                )}
                { currentStep==5 &&(
                    <ResultStep />
                )}
                { currentStep==6 &&(
                    <DataStep redirection="/RiskEstimation" />
                )}
                { currentStep==7 &&(
                    <DataStep redirection="/" />
                )}
                </div>
            </div>
    </section>
    
    </Layout>    
    </>
    );
};

export default GuideLine;