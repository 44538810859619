export default {
    data:[
        {
            "id": 1,
            "name": "Imam Reza Hospital",
            "city": "Bojnord",
            "province": "north khorasan ",
            "adress": "Dowlat Blvd, Bojnurd, Iran",
            "locationX": "37.48318130",
            "locationY": "57.33355180",
            "website": "https://nkums.ac.ir/Category/30",
            "area": 1
        },
        {
            "id": 2,
            "name": "Taleghani Hospital",
            "city": "Mashhad",
            "province": "razavi khorasan ",
            "adress": "Asiaee Highway, Ferdowsi Intersection, Mashhad, Iran",
            "locationX": "36.42951810",
            "locationY": "59.45511100",
            "website": "https://taleghani.mums.ac.ir/",
            "area": 1
        },
        {
            "id": 3,
            "name": "Valiasr Hospital",
            "city": "Birjand",
            "province": "south khorasan ",
            "adress": "Ghafari Str, Birjand, South Khorasan, Iran",
            "locationX": "32.85869030",
            "locationY": "59.23316730",
            "website": "https://valiasr.bums.ac.ir/",
            "area": 1
        },
        {
            "id": 4,
            "name": "5th Azar Hospital",
            "city": "Gorgan",
            "province": "golestan ",
            "adress": "5th Azar Blvd, Gorgan, Iran",
            "locationX": "36.83569430",
            "locationY": "54.43234470",
            "website": "https://www.goums.ac.ir//",
            "area": 1
        },
        {
            "id": 5,
            "name": "Imam Khomeini Hospital",
            "city": "Sari",
            "province": "mazanderan ",
            "adress": " Razi Str, Sari, Iran",
            "locationX": "36.56594570",
            "locationY": "53.05094000",
            "website": "https://imamhospital.mazums.ac.ir/",
            "area": 1
        },
        {
            "id": 6,
            "name": "Imam Khomeini Hospital",
            "city": "Tehran",
            "province": "tehra",
            "adress": " Tohid square, Tehran, Iran",
            "locationX": "35.70833540",
            "locationY": "51.38069630",
            "website": "https://ikhc.tums.ac.ir/",
            "area": 1
        },
        {
            "id": 7,
            "name": "Shahid Rejaei Hospital",
            "city": "Karaj",
            "province": "alborz ",
            "adress": "Hesarak Str,Shahid Rajaei street, Karaj, Iran ",
            "locationX": "35.84124460",
            "locationY": "50.93399050",
            "website": "https://rajaei.abzums.ac.ir/fa-IR/rajaei.abzums.ac/6132/page/%D8%B5%D9%81%D8%AD%D9%87-%D8%A7%D8%B5%D9%84%DB%8C",
            "area": 1
        },
        {
            "id": 8,
            "name": "Amin Hospital ",
            "city": "Isfahan",
            "province": "isfahan ",
            "adress": "Ebne Sina Str, Shohada Sq, Isfahan, Iran",
            "locationX": "32.66992010",
            "locationY": "51.67585260",
            "website": "https://amin.mui.ac.ir/",
            "area": 1
        },
        {
            "id": 9,
            "name": "Shahid Sadooghi Hospital",
            "city": "Yazd",
            "province": "yazd ",
            "adress": "Safaieh, Yazd, Iran",
            "locationX": "31.82529330",
            "locationY": "54.38033580",
            "website": "https://web.ssu.ac.ir/index.aspx?lang=1&sub=16",
            "area": 1
        },
        {
            "id": 10,
            "name": "Kosar Hospital",
            "city": "Semnan",
            "province": "semnan ",
            "adress": "Basij Blvd, semnan, Iran",
            "locationX": "35.59884990",
            "locationY": "53.37204410",
            "website": "https://enkosarhos.semums.ac.ir/",
            "area": 1
        }
    ],
  };
  