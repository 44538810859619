import { useEffect, useRef } from "react";
import { ReactComponent as Notepad } from "./../../../../assets/images/icon/notepad.svg";
import { ReactComponent as Result } from "./../../../../assets/images/icon/result.svg";
import { ReactComponent as Feedback } from "./../../../../assets/images/icon/feedback.svg";
import { ReactComponent as Image } from "./../../../../assets/images/icon/image.svg";
function Steps(props) {
  const { activeStep } = props;
  const StepsElementParent = useRef(null);
  useEffect(() => {
    switch (activeStep) {
      case 0:
        StepsElementParent.current.scrollTo(0, 0);
        break;
      case 1:
        StepsElementParent.current.scrollTo(250, 0);
        break;
      case 2:
        StepsElementParent.current.scrollTo(500, 0);
        break;
      case 3:
        StepsElementParent.current.scrollTo(750, 0);
        break;
      case 4:
        StepsElementParent.current.scrollTo(1000, 0);
        break;
      case 5:
        StepsElementParent.current.scrollTo(1250, 0);
        break;
      case 6:
        StepsElementParent.current.scrollTo(1500, 0);
        break;
      case 7:
        StepsElementParent.current.scrollTo(1750, 0);
        break;
      default:
        break;
    }
  }, [activeStep]);
  return (
    <div className="Tsteps">
      <div className="items" ref={StepsElementParent}>

        <div className={`item ${activeStep > -1 ? "active" : ""}`}>
          <div className="number">
            <h2>0</h2>
          </div>
          <div className="text-treatment">
            <span>Start</span>
          </div>
        </div>

        <div className={`item ${activeStep > 0 ? "active" : ""}`}>
          <div className="number">
            <h2>1</h2>
          </div>
          <div className="text-treatment">
            <span>Bite site</span>
          </div>
        </div>

        <div className={`item ${activeStep > 1 ? "active" : ""}`}>
          <div className="number">
            <h2>2</h2>
          </div>
          <div className="text-treatment">
            <span>Pain</span>
          </div>
        </div>

        <div className={`item ${activeStep > 2 ? "active" : ""}`}>
          <div className="number">
            <h2>3</h2>
          </div>
          <div className="text-treatment">
            <span>Edema</span>
          </div>
        </div>
        
        <div className={`item ${activeStep > 3 ? "active" : ""}`}>
          <div className="number">
            <h2>4</h2>
          </div>
          <div className="text-treatment">
            <span>Bleeding</span>
          </div>
        </div>

        <div className={`item ${activeStep > 4 ? "active" : ""}`}>
          <div className="number">
            <h2>5</h2>
          </div>
          <div className="text-treatment">
            <span>Tests</span>
          </div>
        </div>

        <div className={`item ${activeStep > 5 ? "active" : ""}`}>
          <div className="number">
            <h2>6</h2>
          </div>
          <div className="text-treatment">
            <span>Neuro</span>
          </div>
        </div>

        <div className={`item ${activeStep > 6 ? "active" : ""}`}>
          <div className="number">
            <h2>7</h2>
          </div>
          <div className="text-treatment">
            <span>Necro</span>
          </div>
        </div>

        <div className={`item ${activeStep > 7 ? "active" : ""}`}>
          <div className="number">
            <h2>8</h2>
          </div>
          <div className="text-treatment">
            <span>Result</span>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Steps;
